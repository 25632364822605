import React, { useEffect, useState } from "react";
import "./App.css";
import {
  Box,
  VStack,
  HStack,
  Text,
  Select,
  Input,
  Button,
  useBreakpointValue,
  extendTheme,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  useToast,
  Link,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Icon,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Container,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  AddIcon,
  DeleteIcon,
  EditIcon,
  SearchIcon,
  ViewIcon,
} from "@chakra-ui/icons";
import { DatePicker, Pagination } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import "dayjs/locale/en";
import { Link as ReachLink, useNavigate } from "react-router-dom";
import { FaExclamationTriangle } from "react-icons/fa";
import World from "./world";
import India from "./india";
import { State } from "./provider";
import Rajasthan from "./rajasthan";
import { ModalHeader } from "react-bootstrap";
import moment from "moment";
dayjs.extend(customParseFormat);

const { RangePicker } = DatePicker;

const AdminPanel = () => {

  const navigate = useNavigate();

  const responsiveTableProps = useBreakpointValue({
    base: { width: "full" },
    md: { width: "auto" },
  });
  useEffect(() => {
    document.title = "Admin · Marwadi International Federation";
  }, []);
  const value = State();
  const {
    admin,
    setAdmin,
    viewHome,
    setViewHome,
    viewLoginActivity,
    setViewLoginActivity,
    viewSummary,
    setViewSummary,
    viewMap,
    setViewMap,
    viewConsolidatedSummary,
    setViewConsolidatedSummary,
  } = value || {};
  const [country, setCountry] = useState();
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [city, setCity] = useState([]);
  const [name, setName] = useState("");
  const [rangeName, setRangeName] = useState("");
  const [selectedSummaryLocation, setSelectedSummaryLocation] = useState();
  const [selectedSummaryOption, setSelectedSummaryOption] = useState();
  const [selectedMap, setSelectedMap] = useState();
  const [text, setText] = useState();
  const [viewMapActivity, setViewMapActivity] = useState(false);
  const [person, setPerson] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [data, setData] = useState([]);
  const [table, setTable] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [deleteMobile, setDeleteMobile] = useState("");
  const [loading, setLoading] = useState(false);
  const [summaryLoading, setSummaryLoading] = useState(false);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [consolidatedSearch, setConsolidatedSearch] = useState();
  const toast = useToast();
  const buttonWidth = useBreakpointValue({ base: "70%", md: "auto" });
  const [submissionTimes, setSubmissionTimes] = useState([]);
  const [showSubmissionTimes, setShowSubmissionTimes] = useState(false);
  const [countries, setCountries] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [csvData, setCsvData] = useState(null);
  const dateFormat = "DD/MM/YYYY";
  const defaultDate = dayjs().startOf("day");
  const [date, setDate] = useState(moment(defaultDate).format("YYYY-MM-DD"));
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const handleRangeChange = (dates) => {
    setStartDate(dates[0]);
    setEndDate(dates[1]);
  };

  const boxStyle = {
    fill: "pink",
    stroke: "black",
    cursor: "pointer",
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    fetchCountries();
  }, []);

  useEffect(() => {
    fetchDataByName();
  }, [consolidatedSearch]);

  useEffect(() => {
    if (selectedSummaryLocation === "India") {
      fetchRangeSummaryDataIndia();
    } else if (selectedSummaryLocation === "Abroad") {
      fetchRangeSummaryDataAbroad();
    }
  }, [rangeName]);

  const fetchCountries = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/countries`
      );
      setCountries(response.data);
    } catch (error) {
      console.error("Error occurred while fetching countries:", error);
    }
  };

  const fetchDataIndia = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${admin?.token}`,
        },
      };
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/user?city=${selectedCity}&state=${selectedState}&name=${name}&ctype=India`,
        config
      );
      setData(response.data);
      setLoading(true);
      setSummaryLoading(false);
    } catch (error) {
      console.error(error);
      toast({
        title: "Error",
        description: "Session Expired",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const fetchDataAbroad = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${admin?.token}`,
        },
      };
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/user?name=${name}&country=${selectedCountry}&ctype=Abroad`,
        config
      );
      setData(response.data);
      setLoading(true);
      setSummaryLoading(false);
    } catch (error) {
      console.error(error);
      toast({
        title: "Error",
        description: "Session Expired",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const fetchSummaryDataIndia = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${admin?.token}`,
        },
      };
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/datesummary?state=${selectedState}&name=${name}&city=${selectedCity}&date=${date}&ctype=India`,
        config
      );
      setData(response.data);
      setLoading(false);
      setSummaryLoading(true);
    } catch (error) {
      console.error(error);
      toast({
        title: "Error",
        description: "Session Expired",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const fetchSummaryDataAbroad = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${admin?.token}`,
        },
      };
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/datesummary?name=${name}&country=${selectedCountry}&date=${date}&ctype=Abroad`,
        config
      );
      setData(response.data);
      setLoading(false);
      setSummaryLoading(true);
      if (response.data === []) {
        toast({
          title: "No Data Found",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error(error);
      toast({
        title: "Error",
        description: "Session Expired",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const fetchRangeSummaryDataIndia = async () => {
    if (!startDate || !endDate) {
      toast({
        description: "Please select a date range",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    var dateStart = startDate.format("YYYY-MM-DD");
    var dateEnd = endDate.format("YYYY-MM-DD");
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${admin?.token}`,
        },
      };
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/rangesummary?state=${selectedState}&name=${rangeName}&city=${selectedCity}&startDate=${dateStart}&endDate=${dateEnd}&ctype=India`,
        config
      );
      setData(response.data);
      setLoading(false);
      setSummaryLoading(true);
      setSelectedSummaryLocation("India");
    } catch (error) {
      console.error(error);
      toast({
        title: "Error",
        description: "Session Expired",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const fetchRangeSummaryDataAbroad = async () => {
    if (!startDate || !endDate) {
      toast({
        description: "Please select a date range",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    var dateStart = startDate.format("YYYY-MM-DD") || "";
    var dateEnd = endDate.format("YYYY-MM-DD") || "";
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${admin?.token}`,
        },
      };
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/rangesummary?country=${selectedCountry}&startDate=${dateStart}&endDate=${dateEnd}&name=${rangeName}&ctype=Abroad`,
        config
      );
      setData(response.data);
      setLoading(false);
      setSummaryLoading(true);
      setSelectedSummaryLocation("Abroad");
      if (response.data === []) {
        toast({
          title: "No Data Found",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error(error);
      toast({
        title: "Error",
        description: "Session Expired",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const fetchConsolidatedSummary = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${admin?.token}`,
        },
      };
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/summary`,
        config
      );
      setData(response.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      toast({
        title: "Error",
        description: "Session Expired",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const fetchLoginData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/logindata`
      );

      const istData = response.data.map((item) => {
        const istTimestamp = new Date(item.latestLoginTime).toLocaleString(
          "en-IN",
          {
            timeZone: "Asia/Kolkata",
          }
        );
        return {
          ...item,
          latestLoginTime: istTimestamp,
        };
      });

      setData(istData);
    } catch (error) {
      console.error("Error fetching login data", error);
      toast({
        title: "Error",
        description: "Session Expired",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const fetchDataByName = async () => {
    try {
      if (consolidatedSearch) {
        const config = {
          headers: {
            Authorization: `Bearer ${admin?.token}`,
          },
        };
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/user?name=${consolidatedSearch}`,
          config
        );
        setData(response.data);
      } else {
        const config = {
          headers: {
            Authorization: `Bearer ${admin?.token}`,
          },
        };
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/user/summary`,
          config
        );
        setData(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchStates();
  }, []);

  const fetchStates = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/states`
      );
      setStates(response.data);
    } catch (error) {
      console.error("Error occurred while fetching states:", error);
    }
  };

  useEffect(() => {
    fetchCities();
  }, [selectedState]);

  const fetchCities = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/cities?state=${selectedState}`
      );
      setCities(response.data);
    } catch (error) {
      console.error("Error occurred while fetching cities:", error);
    }
  };

  useEffect(() => {
    updatePagination();
  }, [data, pageSize]);

  const updatePagination = () => {
    const totalPageCount = Math.ceil(data.length / pageSize);
    setTotalItems(data.length);
    setTotalPages(totalPageCount);
    setCurrentPage(1);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handlePageSizeChange = (event) => {
    const newPageSize = Number(event.target.value);
    const newTotalPages = Math.ceil(totalItems / newPageSize);
    setCurrentPage(1);
    setPageSize(newPageSize);
    setTotalPages(newTotalPages);
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, totalItems);
  const paginatedData = data.slice(startIndex, endIndex);

  const pageSizeOptions = [5, 10, 25, 50, 100];

  const customTheme = extendTheme({
    breakpoints: {
      sm: "480px",
      md: "1080px",
      lg: "1200px",
      xl: "1440px",
    },
  });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isDeleting, setIsDeleting] = useState(false);

  const handleAction = async (name, phone) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/submission-times?name=${name}&phone=${phone}`
      );

      // console.log(response.data);

      const istSubmissionTimes = response.data.map((submissionTime) => {
        // console.log('Original login time:', submissionTime.submission_time);

        const parsedTimestamp = new Date(submissionTime.submission_time);
        // console.log('Parsed login time:', parsedTimestamp);

        const istTimestamp = parsedTimestamp.toLocaleString("en-IN", {
          timeZone: "Asia/Kolkata",
        });
        // console.log('IST login time:', istTimestamp);

        return {
          ...submissionTime,
          submission_time: istTimestamp,
        };
      });

      setSubmissionTimes(istSubmissionTimes);
      setShowSubmissionTimes(true);
    } catch (error) {
      console.error("Error fetching submission times", error);
    }
  };

  const deleteUser = async (mobile_number) => {
    try {
      setIsDeleting(true);
      // console.log(email);
      const config = {
        headers: {
          Authorization: `Bearer ${admin?.token}`,
        },
      };
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/delete/${mobile_number}`,
        config
      );
      setData((prevUsers) => prevUsers.filter((user) => user.mobile_number !== mobile_number));

      // If there are no users left on the current page, go to the previous page
      if (data.length === 1 && currentPage > 1) {
        setCurrentPage((prevPage) => prevPage - 1);
      }
      if (response.status === 200) {
        toast({
          title: "User Deleted",
          description: "The user was deleted successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setCurrentPage(1);
        fetchCountries();
        fetchStates();
        fetchCities();
      } else {
        toast({
          title: "Failed to Delete User",
          description: "An error occurred while deleting the user.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
      setIsDeleting(false);
      onClose();
      // setTimeout(() => {
      //   window.location.reload();
      // }, 900);
    } catch (error) {
      console.error("Error occurred while deleting user:", error);
      toast({
        title: "Error",
        description: "An error occurred while deleting the user.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setIsDeleting(false);
      onClose();
    }
  };

  const handleViewSheet = () => {
    window.location.href = process.env.REACT_APP_GOOGLE_SHEET;
  };

  const generateCSVFile = async () => {
    await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/export-users`)
      .then((response) => {
        const csvData = response.data;
        const blob = new Blob([csvData], { type: "text/csv" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "data.csv";
        link.click();
        URL.revokeObjectURL(url);
        toast({
          title: "Google Sheets Updated Successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((error) => {
        console.error("Error calling API:", error);
      });
  };

  const buttonHomeLayout = useBreakpointValue(
    {
      base: (
        
        <VStack w="100%" align="stretch" flexDir="column" alignItems="center">
           <HStack
            display="flex"
            alignItems="center"
            justifyContent="right"
            flexWrap="wrap"
            >
                  <Button
                  type="submit"
                  colorScheme="red"
                  size="md"
                  fontFamily="Montserrat"
                  alignContent="right"
                  alignSelf="center"
                  onClick={()=> {
                    localStorage.removeItem("adminInfo");
                    navigate("/admin", { replace: true });
                    window.location.reload(false);
                  } }
                >
                  Logout
                </Button>

            </HStack>
          <Box
            as="button"
            borderRadius="md"
            bg="#0d6efd"
            color="white"
            px={6}
            h={10}
            w={buttonWidth}
          >
            <Link
              fontSize={12}
              textDecoration="none"
              as={ReachLink}
              to="/form"
            >
              <AddIcon pb={1} /> Add New Entry
            </Link>
          </Box>
          <Box
            as="button"
            borderRadius="md"
            bg="#0d6efd"
            color="white"
            px={6}
            h={10}
            // mb={6}
            w={buttonWidth}
            onClick={() => {
              setViewHome(false);
              setViewLoginActivity(true);
              fetchLoginData();
            }}
          >
            <Text fontSize={12}>View Login Activity</Text>
          </Box>
          <Box
            as="button"
            borderRadius="md"
            bg="#0d6efd"
            color="white"
            px={6}
            h={10}
            // mb={4}
            w={buttonWidth}
            onClick={() => {
              setViewHome(false);
              setLoading(false);
              setViewMap(true);
            }}
          >
            <Text fontSize={12}>View Map Activity</Text>
          </Box>
          <Box
            as="button"
            borderRadius="md"
            bg="#0d6efd"
            color="white"
            px={6}
            h={10}
            // mb={4}
            w={buttonWidth}
            onClick={() => {
              setViewMap(false);
              setViewHome(false);
              setViewSummary(true);
            }}
          >
            <Text fontSize={12}>View Summary</Text>
          </Box>
          <Box
            as="button"
            borderRadius="md"
            bg="#0d6efd"
            color="white"
            px={6}
            h={14}
            // mb={4}
            w={buttonWidth}
            onClick={() => {
              setViewMap(false);
              setViewHome(false);
              setViewConsolidatedSummary(true);
              fetchConsolidatedSummary();
            }}
          >
            <Text fontSize={12}>View Consolidated Summary</Text>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
            w="100%"
            p={4}
            gap="20px"
            borderWidth="1.2px"
            borderRadius="12px"
            borderColor="gray.500"
          >
            <Text pr={5} textAlign="center">
              <b>Search in Abroad via Current Location</b>
            </Text>
            <Select
              variant="flushed"
              placeholder="Select a country"
              value={selectedCountry}
              onChange={(e) => setSelectedCountry(e.target.value)}
            >
              {countries.map((country, index) => (
                <option key={index} value={country}>
                  {country}
                </option>
              ))}
            </Select>
            <HStack w="100%" spacing="40px">
              <Input
                variant="flushed"
                placeholder="Search by Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <Button onClick={fetchDataAbroad} colorScheme="blue">
                <SearchIcon />
              </Button>
            </HStack>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
            marginTop="24px"
            w="100%"
            p={4}
            gap="40px"
            borderWidth="1.2px"
            borderRadius="12px"
            borderColor="gray.500"
          >
            <Text pr={5} textAlign="center">
              <b>Search in India via Current Location</b>
            </Text>
            <Select
              variant="flushed"
              value={selectedState}
              placeholder="Select State"
              onChange={(e) => setSelectedState(e.target.value)}
              borderColor="gray.400"
            >
              {states.map((state) => (
                <option key={state} value={state}>
                  {state}
                </option>
              ))}
            </Select>
            <Select
              variant="flushed"
              value={selectedCity}
              placeholder="Select City"
              onChange={(e) => setSelectedCity(e.target.value)}
              borderColor="gray.400"
            >
              {cities.map((city) => (
                <option key={city} value={city}>
                  {city}
                </option>
              ))}
            </Select>
            <HStack w="100%" spacing="40px">
              <Input
                variant="flushed"
                placeholder="Search by Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <Button onClick={fetchDataIndia} colorScheme="blue">
                <SearchIcon />
              </Button>
            </HStack>
          </Box>

          {loading && (
            <>
              <Box
                borderWidth={1}
                borderColor="gray.500"
                maxH="600px"
                overflowX="auto"
                maxW="100%"
                sx={{
                  "&::-webkit-scrollbar": {
                    width: "12px",
                    backgroundColor: `rgba(0, 0, 0, 0.05)`,
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: `rgba(0, 0, 0, 0.05)`,
                  },
                }}
              >
                <Table variant="simple" height="100%">
                  <Thead position="sticky" top={-1}>
                    <Tr>
                      <Th
                        pr={1}
                        bgColor="gray.100"
                        fontFamily="Poppins"
                        border="1px solid gray"
                      >
                        #
                      </Th>
                      <Th
                        textAlign="center"
                        pr={1}
                        pl={1}
                        bgColor="gray.100"
                        fontFamily="Poppins"
                        border="1px solid gray"
                      >
                        Name
                      </Th>
                      <Th
                        textAlign="center"
                        pr={1}
                        pl={1}
                        bgColor="gray.100"
                        fontFamily="Poppins"
                        border="1px solid gray"
                      >
                        S/o ,D/o ,W/o NAME
                      </Th>
                      <Th
                        textAlign="center"
                        pr={1}
                        pl={1}
                        bgColor="gray.100"
                        fontFamily="Poppins"
                        border="1px solid gray"
                      >
                        Email
                      </Th>
                      <Th
                        textAlign="center"
                        pr={1}
                        pl={1}
                        bgColor="gray.100"
                        fontFamily="Poppins"
                        border="1px solid gray"
                      >
                        Mobile
                      </Th>
                      <Th
                        textAlign="center"
                        pr={1}
                        pl={1}
                        bgColor="gray.100"
                        fontFamily="Poppins"
                        border="1px solid gray"
                      >
                        Occupation
                      </Th>
                      <Th
                        textAlign="center"
                        pr={1}
                        pl={1}
                        bgColor="gray.100"
                        fontFamily="Poppins"
                        border="1px solid gray"
                      >
                        Country Type
                      </Th>
                      <Th
                        textAlign="center"
                        pr={1}
                        pl={1}
                        bgColor="gray.100"
                        fontFamily="Poppins"
                        border="1px solid gray"
                      >
                        Current Address
                      </Th>
                      <Th
                        textAlign="center"
                        pr={1}
                        pl={1}
                        bgColor="gray.100"
                        fontFamily="Poppins"
                        border="1px solid gray"
                      >
                        Native Place
                      </Th>
                      <Th
                        textAlign="center"
                        pr={1}
                        pl={1}
                        bgColor="gray.100"
                        fontFamily="Poppins"
                        border="1px solid gray"
                      >
                        Action
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {paginatedData.map((item, index) => (
                      <Tr key={item.id}>
                        <Td
                          textAlign="center"
                          pr={1}
                          pl={1}
                          border="1px solid gray"
                        >
                          {startIndex + index + 1}
                        </Td>
                        <Td
                          textAlign="center"
                          pr={1}
                          pl={1}
                          border="1px solid gray"
                        >
                          {item.full_name}
                        </Td>
                        <Td
                          textAlign="center"
                          pr={1}
                          pl={1}
                          border="1px solid gray"
                        >
                          {item.so_wo_do}
                        </Td>
                        <Td
                          textAlign="center"
                          pr={1}
                          pl={1}
                          border="1px solid gray"
                        >
                          {item.email}
                        </Td>
                        <Td
                          textAlign="center"
                          pr={1}
                          pl={1}
                          border="1px solid gray"
                        >
                          {item.mobile_number}
                        </Td>
                        <Td
                          textAlign="center"
                          pr={1}
                          pl={1}
                          border="1px solid gray"
                        >
                          {item.occupation}
                        </Td>
                        <Td
                          textAlign="center"
                          pr={1}
                          pl={1}
                          border="1px solid gray"
                        >
                          {item.country_type}
                        </Td>
                        <Td
                          textAlign="center"
                          pr={1}
                          pl={1}
                          border="1px solid gray"
                        >
                          {item.address}, {item.city}, {item.state},{" "}
                          {item.country}
                        </Td>
                        <Td
                          textAlign="center"
                          pr={1}
                          pl={1}
                          border="1px solid gray"
                        >
                          {item.native_district}, {item.native_tehsil},
                          {item.native_village}
                        </Td>
                        <Td
                          textAlign="center"
                          pr={1}
                          pl={1}
                          border="1px solid gray"
                        >
                          <DeleteIcon
                            colorScheme="red"
                            _hover={{ color: "red" }}
                            _focus={{ color: "red" }}
                            cursor="pointer"
                            mr="12px"
                            onClick={() => {
                              setDeleteMobile(item.mobile_number);
                              onOpen();
                            }}
                          />
                          <AlertDialog
                            isOpen={isOpen && deleteMobile === item.mobile_number}
                            leastDestructiveRef={undefined}
                            onClose={onClose}
                            isCentered
                          >
                            <AlertDialogOverlay />

                            <AlertDialogContent>
                              <AlertDialogHeader>
                                <Icon
                                  as={FaExclamationTriangle}
                                  mt={4}
                                  mr={2}
                                />
                                Delete User
                              </AlertDialogHeader>
                              <AlertDialogBody>
                                Are you sure you want to delete the user?
                              </AlertDialogBody>
                              <AlertDialogFooter>
                                <Button onClick={onClose}>Cancel</Button>
                                <Button
                                  colorScheme="red"
                                  ml={3}
                                  onClick={() => {
                                    deleteUser(deleteMobile);
                                  }}
                                  isLoading={isDeleting}
                                >
                                  Delete
                                </Button>
                              </AlertDialogFooter>
                            </AlertDialogContent>
                          </AlertDialog>

                          <Link as={ReachLink} to={`update/${item.mobile_number}`}>
                            <EditIcon
                              cursor="pointer"
                              _hover={{ color: "green" }}
                              _focus={{ color: "green" }}
                            />
                          </Link>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>

              <VStack justify="flex-end" spacing={4}>
                <Pagination
                  size="large"
                  current={currentPage}
                  total={totalItems}
                  pageSize={pageSize}
                  showSizeChanger={false}
                  onChange={handlePageChange}
                  showTotal={(total) => `Total ${total} items`}
                  showQuickJumper
                />
                {/* <Flex alignItems="center"> */}
                <Select
                  value={pageSize}
                  onChange={handlePageSizeChange}
                  variant="outline"
                >
                  {pageSizeOptions.map((option) => (
                    <option key={option} value={option}>
                      {option} / page
                    </option>
                  ))}
                </Select>
                {/* </Flex> */}
              </VStack>
            </>
          )}
        </VStack>
      ),

      md: (
        <VStack w="100%" align="stretch" spacing={4} p={4}>

            <HStack
            display="flex"
            alignItems="center"
            justifyContent="right"
            flexWrap="wrap"
            >
                  <Button
                  type="submit"
                  colorScheme="red"
                  size="md"
                  fontFamily="Montserrat"
                  alignContent="right"
                  alignSelf="center"
                  onClick={()=> {
                    localStorage.removeItem("adminInfo");
                    navigate("/admin", { replace: true });
                    window.location.reload(false);
                  } }
                >
                  Logout
                </Button>

            </HStack>
            

          <HStack
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexWrap="wrap"
          >
            <Link as={ReachLink} to="/form">
              <Box
                as="button"
                borderRadius="md"
                bg="#0d6efd"
                color="white"
                px={6}
                h={10}
                mb={0.9999}
                w={buttonWidth}
              >
                <AddIcon pb={1} /> Add New Entry
              </Box>
            </Link>
            <Box
              as="button"
              borderRadius="md"
              bg="#0d6efd"
              color="white"
              px={6}
              h={10}
              // mb={6}
              w={buttonWidth}
              onClick={() => {
                setViewHome(false);
                setLoading(false);
                setViewLoginActivity(true);
                fetchLoginData();
              }}
            >
              View Login Activity
            </Box>
            <Box
              as="button"
              borderRadius="md"
              bg="#0d6efd"
              color="white"
              px={6}
              h={10}
              // mb={6}
              w={buttonWidth}
              onClick={() => {
                setViewHome(false);
                setViewMap(true);
              }}
            >
              View Map Activity
            </Box>
            <Box
              as="button"
              borderRadius="md"
              bg="#0d6efd"
              color="white"
              px={6}
              h={10}
              // mb={6}
              w={buttonWidth}
              onClick={() => {
                setViewMap(false);
                setViewHome(false);
                setViewSummary(true);
                setLoading(false);
              }}
            >
              View Summary
            </Box>
            <Box
              as="button"
              borderRadius="md"
              bg="#0d6efd"
              color="white"
              px={6}
              h={10}
              // mb={6}
              w={buttonWidth}
              onClick={() => {
                setViewMap(false);
                setViewHome(false);
                setViewConsolidatedSummary(true);
                fetchConsolidatedSummary();
              }}
            >
              View Consolidated Summary
            </Box>
          </HStack>
          <HStack>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              w="100%"
              p={4}
              pb={8}
              gap="80px"
              borderWidth="1.2px"
              borderRadius="12px"
              borderColor="gray.500"
            >
              <VStack align="stretch" w="100%">
                <Text
                  pr={5}
                  borderColor="gray.400"
                  // borderRightWidth="1.2px"
                  textAlign="left"
                  fontFamily="Montserrat"
                >
                  <b>Search in Abroad via Current Location</b>
                </Text>

                <HStack
                  // p={2}
                  spacing={0}
                  gap={0}
                  borderWidth="1.2px"
                  borderRadius="12px"
                  borderColor="gray.500"
                >
                  <Select
                    ml={1}
                    textAlign="center"
                    variant="unstyled"
                    placeholder="Select a country"
                    focusBorderColor="none"
                    // borderRightWidth="1px"
                    // borderRightColor="gray.500"
                    _focus={{ boxShadow: "none" }}
                    _active={{ boxShadow: "none" }}
                    value={selectedCountry}
                    fontFamily="Montserrat"
                    onChange={(e) => setSelectedCountry(e.target.value)}
                  >
                    {countries.map((country, index) => (
                      <option key={index} value={country}>
                        {country}
                      </option>
                    ))}
                  </Select>

                  <Input
                    ml={1}
                    pr={2}
                    variant="flushed"
                    placeholder="Search by Name"
                    pl={2}
                    value={name}
                    borderLeftWidth="1.2px"
                    // borderWidth="1.2px"
                    // borderRadius="12px"
                    // borderColor="gray.500"
                    borderBottom="none"
                    focusBorderColor="none"
                    _focus={{ boxShadow: "none" }}
                    _active={{ boxShadow: "none" }}
                    onChange={(e) => setName(e.target.value)}
                    fontFamily="Montserrat"
                  />
                  <Button
                    colorScheme="blue"
                    borderRadius={11}
                    onClick={fetchDataAbroad}
                  >
                    <SearchIcon />
                  </Button>
                </HStack>
              </VStack>
            </Box>

            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              // marginTop="24px"
              w="100%"
              p={4}
              pb={8}
              gap="40px"
              borderWidth="1.2px"
              borderRadius="12px"
              borderColor="gray.500"
            >
              <VStack align="stretch" w="100%">
                <Text
                  pr={5}
                  borderColor="gray.400"
                  // borderRightWidth="1.2px"
                  textAlign="left"
                  fontFamily="Montserrat"
                >
                  <b>Search in India via Current Location</b>
                </Text>
                <HStack
                  spacing={0}
                  gap={0}
                  borderWidth="1.2px"
                  borderRadius="12px"
                  borderColor="gray.500"
                >
                  <Select
                    ml={1}
                    textAlign="center"
                    variant="unstyled"
                    focusBorderColor="none"
                    // borderRightWidth="1px"
                    // borderRightColor="gray.500"
                    _focus={{ boxShadow: "none" }}
                    _active={{ boxShadow: "none" }}
                    value={selectedState}
                    placeholder="Select State"
                    onChange={(e) => setSelectedState(e.target.value)}
                    // borderColor="gray.400"
                    fontFamily="Montserrat"
                  >
                    {states.map((state) => (
                      <option key={state} value={state}>
                        {state}
                      </option>
                    ))}
                  </Select>
                  <Select
                    ml={1}
                    textAlign="center"
                    variant="flushed"
                    borderBottom="none"
                    focusBorderColor="none"
                    borderLeftWidth="1px"
                    borderRightColor="gray.500"
                    _focus={{ boxShadow: "none" }}
                    _active={{ boxShadow: "none" }}
                    value={selectedCity}
                    placeholder="Select City"
                    onChange={(e) => setSelectedCity(e.target.value)}
                    borderColor="gray.400"
                    fontFamily="Montserrat"
                  >
                    {cities.map((city) => (
                      <option key={city} value={city}>
                        {city}
                      </option>
                    ))}
                  </Select>

                  <Input
                    ml={1}
                    pr={2}
                    variant="flushed"
                    placeholder="Search by Name"
                    pl={2}
                    value={name}
                    borderLeftWidth="1.2px"
                    // borderWidth="1.2px"
                    // borderRadius="12px"
                    // borderColor="gray.500"
                    borderBottom="none"
                    focusBorderColor="none"
                    _focus={{ boxShadow: "none" }}
                    _active={{ boxShadow: "none" }}
                    onChange={(e) => setName(e.target.value)}
                    fontFamily="Montserrat"
                  />
                  <Button
                    colorScheme="blue"
                    borderRadius={11}
                    onClick={fetchDataIndia}
                  >
                    <SearchIcon />
                  </Button>
                </HStack>
              </VStack>
            </Box>
          </HStack>
          {loading && (
            <>
              <Box
                borderWidth={1}
                borderColor="gray.500"
                //  borderRadius="12px"
                overflowX="auto"
                maxH="600px"
                overflowY="auto"
                maxW="100%"
                sx={{
                  "&::-webkit-scrollbar": {
                    width: "12px",
                    backgroundColor: `rgba(0, 0, 0, 0.05)`,
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: `rgba(0, 0, 0, 0.05)`,
                  },
                }}
              >
                <Table variant="simple" height="100%" overflowY="auto">
                  <Thead position="sticky" top={-1}>
                    <Tr>
                      <Th
                        pr={1}
                        bgColor="gray.100"
                        fontFamily="Poppins"
                        border="1px solid gray"
                      >
                        #
                      </Th>
                      <Th
                        textAlign="center"
                        pr={1}
                        pl={1}
                        bgColor="gray.100"
                        fontFamily="Poppins"
                        border="1px solid gray"
                      >
                        Name
                      </Th>
                      <Th
                        textAlign="center"
                        pr={1}
                        pl={1}
                        bgColor="gray.100"
                        fontFamily="Poppins"
                        border="1px solid gray"
                      >
                        S/o ,D/o ,W/o NAME
                      </Th>
                      <Th
                        textAlign="center"
                        pr={1}
                        pl={1}
                        bgColor="gray.100"
                        fontFamily="Poppins"
                        border="1px solid gray"
                      >
                        Email
                      </Th>
                      <Th
                        textAlign="center"
                        pr={1}
                        pl={1}
                        bgColor="gray.100"
                        fontFamily="Poppins"
                        border="1px solid gray"
                      >
                        Mobile
                      </Th>
                      <Th
                        textAlign="center"
                        pr={1}
                        pl={1}
                        bgColor="gray.100"
                        fontFamily="Poppins"
                        border="1px solid gray"
                      >
                        Occupation
                      </Th>
                      <Th
                        textAlign="center"
                        pr={1}
                        pl={1}
                        bgColor="gray.100"
                        fontFamily="Poppins"
                        border="1px solid gray"
                      >
                        Country Type
                      </Th>
                      <Th
                        textAlign="center"
                        pr={1}
                        pl={1}
                        bgColor="gray.100"
                        fontFamily="Poppins"
                        border="1px solid gray"
                      >
                        Current Address
                      </Th>
                      <Th
                        textAlign="center"
                        pr={1}
                        pl={1}
                        bgColor="gray.100"
                        fontFamily="Poppins"
                        border="1px solid gray"
                      >
                        Native Place
                      </Th>
                      <Th
                        textAlign="center"
                        pr={1}
                        pl={1}
                        bgColor="gray.100"
                        fontFamily="Poppins"
                        border="1px solid gray"
                      >
                        Action
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {paginatedData.map((item, index) => (
                      <Tr key={item.id}>
                        <Td
                          textAlign="center"
                          pr={1}
                          pl={1}
                          border="1px solid gray"
                        >
                          {startIndex + index + 1}
                        </Td>
                        <Td
                          textAlign="center"
                          pr={1}
                          pl={1}
                          border="1px solid gray"
                        >
                          {item.full_name}
                        </Td>
                        <Td
                          textAlign="center"
                          pr={1}
                          pl={1}
                          border="1px solid gray"
                        >
                          {item.so_wo_do}
                        </Td>
                        <Td
                          textAlign="center"
                          pr={1}
                          pl={1}
                          border="1px solid gray"
                        >
                          {item.email}
                        </Td>
                        <Td
                          textAlign="center"
                          pr={1}
                          pl={1}
                          border="1px solid gray"
                        >
                          {item.mobile_number}
                        </Td>
                        <Td
                          textAlign="center"
                          pr={1}
                          pl={1}
                          border="1px solid gray"
                        >
                          {item.occupation}
                        </Td>
                        <Td
                          textAlign="center"
                          pr={1}
                          pl={1}
                          border="1px solid gray"
                        >
                          {item.country_type}
                        </Td>
                        <Td
                          textAlign="center"
                          pr={1}
                          pl={1}
                          border="1px solid gray"
                        >
                          {item.address}, {item.city}, {item.state},{" "}
                          {item.country}
                        </Td>
                        <Td
                          textAlign="center"
                          pr={1}
                          pl={1}
                          border="1px solid gray"
                        >
                          {item.native_district}, {item.native_tehsil},
                          {item.native_village}
                        </Td>
                        <Td
                          textAlign="center"
                          pr={1}
                          pl={1}
                          border="1px solid gray"
                        >
                          <DeleteIcon
                            colorScheme="red"
                            _hover={{ color: "red" }}
                            _focus={{ color: "red" }}
                            cursor="pointer"
                            mr="12px"
                            onClick={() => {
                              setDeleteMobile(item.mobile_number);
                              onOpen();
                            }}
                          />
                          <AlertDialog
                            isOpen={isOpen && deleteMobile === item.mobile_number}
                            leastDestructiveRef={undefined}
                            onClose={onClose}
                            isCentered
                          >
                            <AlertDialogOverlay />

                            <AlertDialogContent>
                              <AlertDialogHeader>
                                <Icon
                                  as={FaExclamationTriangle}
                                  mt={4}
                                  mr={2}
                                />
                                Delete User
                              </AlertDialogHeader>
                              <AlertDialogBody>
                                Are you sure you want to delete the user?
                              </AlertDialogBody>
                              <AlertDialogFooter>
                                <Button onClick={onClose}>Cancel</Button>
                                <Button
                                  colorScheme="red"
                                  ml={3}
                                  onClick={() => {
                                    deleteUser(deleteMobile);
                                  }}
                                  isLoading={isDeleting}
                                >
                                  Delete
                                </Button>
                              </AlertDialogFooter>
                            </AlertDialogContent>
                          </AlertDialog>

                          <Link as={ReachLink} to={`update/${item.mobile_number}`}>
                            <EditIcon
                              cursor="pointer"
                              _hover={{ color: "green" }}
                              _focus={{ color: "green" }}
                            />
                          </Link>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>

              <HStack justify="flex-end" spacing={4}>
                <Pagination
                  size="large"
                  current={currentPage}
                  total={totalItems}
                  pageSize={pageSize}
                  showSizeChanger={false}
                  onChange={handlePageChange}
                  showTotal={(total) => `Total ${total} items`}
                  showQuickJumper
                />
                <Flex alignItems="center">
                  <Select
                    value={pageSize}
                    onChange={handlePageSizeChange}
                    variant="outline"
                  >
                    {pageSizeOptions.map((option) => (
                      <option key={option} value={option}>
                        {option} / page
                      </option>
                    ))}
                  </Select>
                </Flex>
              </HStack>
            </>
          )}
        </VStack>
      ),
    },
    customTheme.breakpoints
  );

  const buttonSummaryLayout = useBreakpointValue(
    {
      base: (
        <VStack w="100%" align="stretch" flexDir="column" alignItems="center">
          <HStack
            display="flex"
            alignItems="center"
            justifyContent="right"
            flexWrap="wrap"
            >
                  <Button
                  type="submit"
                  colorScheme="red"
                  size="md"
                  fontFamily="Montserrat"
                  alignContent="right"
                  alignSelf="center"
                  onClick={()=> {
                    localStorage.removeItem("adminInfo");
                    navigate("/admin", { replace: true });
                    window.location.reload(false);
                  } }
                >
                  Logout
                </Button>

            </HStack>

          <Box
            as="button"
            borderRadius="md"
            bg="#0d6efd"
            color="white"
            px={6}
            h={10}
            w={buttonWidth}
          >
            <Link
              fontSize={12}
              textDecoration="none"
              as={ReachLink}
              to="/form"
            >
              <AddIcon pb={1} /> Add New Entry
            </Link>
          </Box>
          <Box
            as="button"
            borderRadius="md"
            bg="#0d6efd"
            color="white"
            px={6}
            h={10}
            // mb={6}
            w={buttonWidth}
            onClick={() => {
              setViewSummary(false);
              setViewLoginActivity(true);
              fetchLoginData();
            }}
          >
            <Text fontSize={12}>View Login Activity</Text>
          </Box>
          <Box
            as="button"
            borderRadius="md"
            bg="#0d6efd"
            color="white"
            px={6}
            h={10}
            // mb={4}
            w={buttonWidth}
            onClick={() => {
              setViewSummary(false);
              setSummaryLoading(false);
              setViewMap(true);
            }}
          >
            <Text fontSize={12}>View Map Activity</Text>
          </Box>
          <Box
            as="button"
            borderRadius="md"
            bg="#0d6efd"
            color="white"
            px={6}
            h={10}
            // mb={4}
            w={buttonWidth}
            onClick={() => {
              setViewHome(true);
              setViewSummary(false);
            }}
          >
            <Text fontSize={12}>View Home</Text>
          </Box>
          <Box
            as="button"
            borderRadius="md"
            bg="#0d6efd"
            color="white"
            px={6}
            h={14}
            // mb={4}
            w={buttonWidth}
            onClick={() => {
              setViewSummary(false);
              setViewConsolidatedSummary(true);
              fetchConsolidatedSummary();
            }}
          >
            <Text fontSize={12}>View Consolidated Summary</Text>
          </Box>

          <FormControl id="map" w="100%">
            <HStack
              spacing="24px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexWrap="wrap"
              mt={4}
            >
              <FormLabel fontWeight="extrabold" fontFamily="Montserrat" mt={2}>
                Select a option :
              </FormLabel>
              <RadioGroup
                value={selectedSummaryOption}
                onChange={(value) => {
                  setSelectedSummaryOption(value);
                  setSummaryLoading(false);
                }}
              >
                <VStack justify="flex-start">
                  <Radio value="Date" size="lg">
                    Single Date
                  </Radio>
                  <Radio value="Date-range" size="lg">
                    Date-Range
                  </Radio>
                </VStack>
              </RadioGroup>
            </HStack>
          </FormControl>

          {selectedSummaryOption === "Date" && (
            <>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                alignItems="center"
                w="100%"
                p={4}
                gap="30px"
                borderWidth="1.2px"
                borderRadius="12px"
                borderColor="gray.500"
              >
                <Text pr={5} textAlign="center">
                  <b>Search in Abroad via Current Location</b>
                </Text>

                <DatePicker
                  defaultValue={defaultDate}
                  format={dateFormat}
                  selected={moment(date, "YYYY-MM-DD")}
                  className="full-width"
                  onChange={(selected) =>
                    setDate(selected?.format("YYYY-MM-DD"))
                  }
                />

                <Select
                  variant="flushed"
                  placeholder="Select a country"
                  value={selectedCountry}
                  onChange={(e) => setSelectedCountry(e.target.value)}
                >
                  {countries.map((country, index) => (
                    <option key={index} value={country}>
                      {country}
                    </option>
                  ))}
                </Select>
                <HStack w="100%" spacing="40px">
                  <Input
                    variant="flushed"
                    placeholder="Search by Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <Button colorScheme="blue" onClick={fetchSummaryDataAbroad}>
                    <SearchIcon />
                  </Button>
                </HStack>
              </Box>

              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                alignItems="center"
                marginTop="24px"
                w="100%"
                p={4}
                gap="30px"
                borderWidth="1.2px"
                borderRadius="12px"
                borderColor="gray.500"
              >
                <Text pr={5} textAlign="center">
                  <b>Search in India via Current Location</b>
                </Text>

                <DatePicker
                  defaultValue={defaultDate}
                  format={dateFormat}
                  selected={moment(date, "YYYY-MM-DD")}
                  className="full-width"
                  onChange={(selected) =>
                    setDate(selected?.format("YYYY-MM-DD"))
                  }
                />
                <Select
                  variant="flushed"
                  placeholder="Select a state"
                  value={selectedState}
                  onChange={(e) => setSelectedState(e.target.value)}
                  borderColor="gray.400"
                >
                  {states.map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </Select>
                <Select
                  variant="flushed"
                  value={selectedCity}
                  onChange={(e) => setSelectedCity(e.target.value)}
                  borderColor="gray.400"
                >
                  <option value="">Select City</option>
                  {cities.map((city) => (
                    <option key={city} value={city}>
                      {city}
                    </option>
                  ))}
                </Select>
                <HStack w="100%" spacing="40px">
                  <Input
                    variant="flushed"
                    placeholder="Search by Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <Button colorScheme="blue" onClick={fetchSummaryDataIndia}>
                    <SearchIcon />
                  </Button>
                </HStack>
              </Box>
            </>
          )}

          {selectedSummaryOption === "Date-range" && (
            <>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                alignItems="center"
                w="100%"
                p={4}
                gap="30px"
                borderWidth="1.2px"
                borderRadius="12px"
                borderColor="gray.500"
              >
                <Text pr={5} textAlign="center">
                  <b>Search in Abroad via Current Location</b>
                </Text>

                <RangePicker
                format={dateFormat}
                  value={[startDate, endDate]}
                  onChange={handleRangeChange}
                />

                <HStack w="100%" spacing="40px">
                  <Select
                    variant="flushed"
                    placeholder="Select a country"
                    value={selectedCountry}
                    onChange={(e) => setSelectedCountry(e.target.value)}
                  >
                    {countries.map((country, index) => (
                      <option key={index} value={country}>
                        {country}
                      </option>
                    ))}
                  </Select>
                  <Button colorScheme="blue" 
                  onClick={() => {
                        fetchRangeSummaryDataAbroad();
                      }}>
                    <SearchIcon />
                  </Button>
                </HStack>
              </Box>

              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                alignItems="center"
                marginTop="24px"
                w="100%"
                p={4}
                gap="30px"
                borderWidth="1.2px"
                borderRadius="12px"
                borderColor="gray.500"
              >
                <Text pr={5} textAlign="center">
                  <b>Search in India via Current Location</b>
                </Text>

                <RangePicker
                format={dateFormat}
                  value={[startDate, endDate]}
                  onChange={handleRangeChange}
                />

                <Select
                  variant="flushed"
                  placeholder="Select a state"
                  value={selectedState}
                  onChange={(e) => setSelectedState(e.target.value)}
                  borderColor="gray.400"
                >
                  {states.map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </Select>
                <HStack w="100%" spacing="40px">
                  <Select
                    variant="flushed"
                    value={selectedCity}
                    onChange={(e) => setSelectedCity(e.target.value)}
                    borderColor="gray.400"
                  >
                    <option value="">Select City</option>
                    {cities.map((city) => (
                      <option key={city} value={city}>
                        {city}
                      </option>
                    ))}
                  </Select>

                  <Button colorScheme="blue" 
                  onClick={() => {
                        fetchRangeSummaryDataIndia();
                      }}>
                    <SearchIcon />
                  </Button>
                </HStack>
              </Box>
            </>
          )}

          {summaryLoading && (
            <>
              {selectedSummaryOption === "Date-range" && (
                <HStack justify="flex-end" w="90%">
                  <Input
                    placeholder="Search By Name"
                    value={rangeName}
                    onChange={(e) => setRangeName(e.target.value)}
                  />
                </HStack>
              )}
              <Box
                borderWidth={1}
                borderColor="gray.500"
                //  borderRadius="12px"
                overflowX="auto"
                maxW="100%"
                maxH="600px"
                overflowY="auto"
                sx={{
                  "&::-webkit-scrollbar": {
                    width: "12px",
                    backgroundColor: `rgba(0, 0, 0, 0.05)`,
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: `rgba(0, 0, 0, 0.05)`,
                  },
                }}
              >
                <Table variant="simple" height="100%">
                  <Thead position="sticky" top={-1}>
                    <Tr>
                      <Th
                        pr={1}
                        bgColor="gray.100"
                        fontFamily="Poppins"
                        border="1px solid gray"
                      >
                        #
                      </Th>
                      <Th
                        textAlign="center"
                        pr={1}
                        pl={1}
                        bgColor="gray.100"
                        fontFamily="Poppins"
                        border="1px solid gray"
                      >
                        Name
                      </Th>
                      <Th
                        textAlign="center"
                        pr={1}
                        pl={1}
                        bgColor="gray.100"
                        fontFamily="Poppins"
                        border="1px solid gray"
                      >
                        S/o ,D/o ,W/o NAME
                      </Th>
                      <Th
                        textAlign="center"
                        pr={1}
                        pl={1}
                        bgColor="gray.100"
                        fontFamily="Poppins"
                        border="1px solid gray"
                      >
                        Email
                      </Th>
                      <Th
                        textAlign="center"
                        pr={1}
                        pl={1}
                        bgColor="gray.100"
                        fontFamily="Poppins"
                        border="1px solid gray"
                      >
                        Mobile
                      </Th>
                      <Th
                        textAlign="center"
                        pr={1}
                        pl={1}
                        bgColor="gray.100"
                        fontFamily="Poppins"
                        border="1px solid gray"
                      >
                        Occupation
                      </Th>
                      <Th
                        textAlign="center"
                        pr={1}
                        pl={1}
                        bgColor="gray.100"
                        fontFamily="Poppins"
                        border="1px solid gray"
                      >
                        Country Type
                      </Th>
                      <Th
                        textAlign="center"
                        pr={1}
                        pl={1}
                        bgColor="gray.100"
                        fontFamily="Poppins"
                        border="1px solid gray"
                      >
                        Current Address
                      </Th>
                      <Th
                        textAlign="center"
                        pr={1}
                        pl={1}
                        bgColor="gray.100"
                        fontFamily="Poppins"
                        border="1px solid gray"
                      >
                        Native Place
                      </Th>
                      <Th
                        textAlign="center"
                        pr={1}
                        pl={1}
                        bgColor="gray.100"
                        fontFamily="Poppins"
                        border="1px solid gray"
                      >
                        Action
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {paginatedData.map((item, index) => (
                      <Tr key={item.id}>
                        <Td
                          textAlign="center"
                          pr={1}
                          pl={1}
                          border="1px solid gray"
                        >
                          {startIndex + index + 1}
                        </Td>
                        <Td
                          textAlign="center"
                          pr={1}
                          pl={1}
                          border="1px solid gray"
                        >
                          {item.full_name}
                        </Td>
                        <Td
                          textAlign="center"
                          pr={1}
                          pl={1}
                          border="1px solid gray"
                        >
                          {item.so_wo_do}
                        </Td>
                        <Td
                          textAlign="center"
                          pr={1}
                          pl={1}
                          border="1px solid gray"
                        >
                          {item.email}
                        </Td>
                        <Td
                          textAlign="center"
                          pr={1}
                          pl={1}
                          border="1px solid gray"
                        >
                          {item.mobile_number}
                        </Td>
                        <Td
                          textAlign="center"
                          pr={1}
                          pl={1}
                          border="1px solid gray"
                        >
                          {item.occupation}
                        </Td>
                        <Td
                          textAlign="center"
                          pr={1}
                          pl={1}
                          border="1px solid gray"
                        >
                          {item.country_type}
                        </Td>
                        <Td
                          textAlign="center"
                          pr={1}
                          pl={1}
                          border="1px solid gray"
                        >
                          {item.address}, {item.city}, {item.state},{" "}
                          {item.country}
                        </Td>
                        <Td
                          textAlign="center"
                          pr={1}
                          pl={1}
                          border="1px solid gray"
                        >
                          {item.native_district}, {item.native_tehsil},
                          {item.native_village}
                        </Td>
                        <Td
                          textAlign="center"
                          pr={1}
                          pl={1}
                          border="1px solid gray"
                        >
                          <DeleteIcon
                            colorScheme="red"
                            _hover={{ color: "red" }}
                            _focus={{ color: "red" }}
                            cursor="pointer"
                            mr="12px"
                            onClick={() => {
                              setDeleteMobile(item.mobile_number);
                              onOpen();
                            }}
                          />
                          <AlertDialog
                            isOpen={isOpen && deleteMobile === item.mobile_number}
                            leastDestructiveRef={undefined}
                            onClose={onClose}
                            isCentered
                          >
                            <AlertDialogOverlay />

                            <AlertDialogContent>
                              <AlertDialogHeader>
                                <Icon
                                  as={FaExclamationTriangle}
                                  mt={4}
                                  mr={2}
                                />
                                Delete User
                              </AlertDialogHeader>
                              <AlertDialogBody>
                                Are you sure you want to delete the user?
                              </AlertDialogBody>
                              <AlertDialogFooter>
                                <Button onClick={onClose}>Cancel</Button>
                                <Button
                                  colorScheme="red"
                                  ml={3}
                                  onClick={() => {
                                    deleteUser(deleteMobile);
                                  }}
                                  isLoading={isDeleting}
                                >
                                  Delete
                                </Button>
                              </AlertDialogFooter>
                            </AlertDialogContent>
                          </AlertDialog>

                          <Link as={ReachLink} to={`update/${item.mobile_number}`}>
                            <EditIcon
                              cursor="pointer"
                              _hover={{ color: "green" }}
                              _focus={{ color: "green" }}
                            />
                          </Link>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>

              <VStack justify="flex-end" spacing={4}>
                <Pagination
                  size="large"
                  current={currentPage}
                  total={totalItems}
                  pageSize={pageSize}
                  showSizeChanger={false}
                  onChange={handlePageChange}
                  showTotal={(total) => `Total ${total} items`}
                  showQuickJumper
                />
                {/* <Flex alignItems="center"> */}
                <Select
                  value={pageSize}
                  onChange={handlePageSizeChange}
                  variant="outline"
                >
                  {pageSizeOptions.map((option) => (
                    <option key={option} value={option}>
                      {option} / page
                    </option>
                  ))}
                </Select>
                {/* </Flex> */}
              </VStack>
            </>
          )}
        </VStack>
      ),
      md: (
        <VStack w="100%" align="stretch" spacing={4}>

            <HStack
            display="flex"
            alignItems="center"
            justifyContent="right"
            flexWrap="wrap"
            >
                  <Button
                  type="submit"
                  colorScheme="red"
                  size="md"
                  fontFamily="Montserrat"
                  alignContent="right"
                  alignSelf="center"
                  onClick={()=> {
                    localStorage.removeItem("adminInfo");
                    navigate("/admin", { replace: true });
                    window.location.reload(false);
                  } }
                >
                  Logout
                </Button>

            </HStack>
            

          <HStack
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexWrap="wrap"
          >

           

            <Link as={ReachLink} to="/form">
              <Box
                as="button"
                borderRadius="md"
                bg="#0d6efd"
                color="white"
                px={6}
                h={10}
                mb={0.9999}
                w={buttonWidth}
              >
                <AddIcon pb={1} /> Add New Entry
              </Box>
            </Link>
            <Box
              as="button"
              borderRadius="md"
              bg="#0d6efd"
              color="white"
              px={6}
              h={10}
              // mb={6}
              w={buttonWidth}
              onClick={() => {
                setViewSummary(false);
                setSummaryLoading(false);
                setViewLoginActivity(true);
                fetchLoginData();
              }}
            >
              View Login Activity
            </Box>
            <Box
              as="button"
              borderRadius="md"
              bg="#0d6efd"
              color="white"
              px={6}
              h={10}
              // mb={6}
              w={buttonWidth}
              onClick={() => {
                setViewMap(true);
                setViewSummary(false);
              }}
            >
              View Map Activity
            </Box>
            <Box
              as="button"
              borderRadius="md"
              bg="#0d6efd"
              color="white"
              px={6}
              h={10}
              // mb={6}
              w={buttonWidth}
              onClick={() => {
                setViewMap(false);
                setViewHome(true);
                setViewSummary(false);
                setSummaryLoading(false);
              }}
            >
              View Home
            </Box>
            <Box
              as="button"
              borderRadius="md"
              bg="#0d6efd"
              color="white"
              px={6}
              h={10}
              // mb={6}
              w={buttonWidth}
              onClick={() => {
                setViewMap(false);
                setViewSummary(false);
                setViewConsolidatedSummary(true);
                fetchConsolidatedSummary();
                setSummaryLoading(false);
              }}
            >
              View Consolidated Summary
            </Box>
          </HStack>

          <FormControl id="map" w="100%">
            <HStack
              spacing="24px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexWrap="wrap"
              mt={4}
            >
              <FormLabel fontWeight="extrabold" fontFamily="Montserrat" mt={2}>
                Select a option :
              </FormLabel>
              <RadioGroup
                value={selectedSummaryOption}
                onChange={(value) => {
                  setSelectedSummaryOption(value);
                  setSummaryLoading(false);
                }}
              >
                <HStack spacing="24px">
                  <Radio value="Date" size="lg">
                    Single Date
                  </Radio>
                  <Radio value="Date-range" size="lg">
                    Date-Range
                  </Radio>
                </HStack>
              </RadioGroup>
            </HStack>
          </FormControl>

          {selectedSummaryOption === "Date" && (
            <HStack>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                w="100%"
                p={4}
                gap="30px"
                borderWidth="1.2px"
                borderRadius="12px"
                borderColor="gray.500"
              >
                <VStack w="100%" align="stretch">
                  <Text
                    pr={5}
                    // borderColor="gray.400"
                    // borderRightWidth="1.2px"
                    textAlign="left"
                    fontFamily="Montserrat"
                  >
                    <b>Search in Abroad via Current Location</b>
                  </Text>
                  <HStack
                    spacing={0}
                    gap={0}
                    borderWidth="1.2px"
                    borderRadius="12px"
                    borderColor="gray.500"
                  >
                    <DatePicker
                      bordered={false}
                      defaultValue={defaultDate}
                      format={dateFormat}
                      selected={moment(date, "YYYY-MM-DD")}
                      className="full-width"
                      onChange={(selected) =>
                        setDate(selected?.format("YYYY-MM-DD"))
                      }
                    />

                    <Select
                      ml={1}
                      // pl={12}
                      textAlign="center"
                      variant="flushed"
                      placeholder="Select a country"
                      value={selectedCountry}
                      onChange={(e) => setSelectedCountry(e.target.value)}
                      borderLeftWidth="1.2px"
                      // pl={1}
                      // pr={2}
                      borderLeftColor="gray.500"
                      borderBottom="none"
                      focusBorderColor="none"
                      fontFamily="Montserrat"
                      fontSize="14px"
                    >
                      {countries.map((country, index) => (
                        <option key={index} value={country}>
                          {country}
                        </option>
                      ))}
                    </Select>

                    <Input
                      ml={1}
                      pr={2}
                      // variant="flushed"
                      // placeholder="Search by Name"
                      pl={2}
                      borderLeftWidth="1.2px"
                      variant="flushed"
                      focusBorderColor="none"
                      borderBottomWidth={0}
                      placeholder="Search by Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      fontFamily="Montserrat"
                      fontSize="14px"
                    />
                    <Button
                      colorScheme="blue"
                      borderRadius={11}
                      onClick={fetchSummaryDataAbroad}
                    >
                      <SearchIcon />
                    </Button>
                  </HStack>
                </VStack>
              </Box>

              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                // marginTop="24px"
                w="100%"
                p={4}
                gap="30px"
                borderWidth="1.2px"
                borderRadius="12px"
                borderColor="gray.500"
              >
                <VStack w="100%" align="stretch">
                  <Text
                    pr={5}
                    // borderColor="gray.400"
                    // borderRightWidth="1.2px"
                    textAlign="left"
                    fontFamily="Montserrat"
                  >
                    <b>Search in India via Current Location</b>
                  </Text>
                  <HStack
                    spacing={0}
                    gap={0}
                    borderWidth="1.2px"
                    borderRadius="12px"
                    borderColor="gray.500"
                  >
                    <DatePicker
                      bordered={false}
                      defaultValue={defaultDate}
                      format={dateFormat}
                      selected={moment(date, "YYYY-MM-DD")}
                      className="full-width"
                      onChange={(selected) =>
                        setDate(selected?.format("YYYY-MM-DD"))
                      }
                    />

                    <Select
                      textAlign="center"
                      variant="flushed"
                      placeholder="Select a state"
                      borderLeftWidth="1.2px"
                      borderRightWidth="1.2px"
                      borderLeftColor="gray.500"
                      borderRightColor="gray.500"
                      focusBorderColor="none"
                      borderBottomWidth={0}
                      value={selectedState}
                      onChange={(e) => setSelectedState(e.target.value)}
                      borderColor="gray.400"
                      fontFamily="Montserrat"
                      fontSize="14px"
                    >
                      {states.map((state) => (
                        <option key={state} value={state}>
                          {state}
                        </option>
                      ))}
                    </Select>
                    <Select
                      variant="flushed"
                      placeholder="Select a city"
                      value={selectedCity}
                      onChange={(e) => setSelectedCity(e.target.value)}
                      borderColor="gray.400"
                      focusBorderColor="none"
                      borderBottomWidth={0}
                      textAlign="center"
                      fontFamily="Montserrat"
                      fontSize="14px"
                    >
                      {cities.map((city) => (
                        <option key={city} value={city}>
                          {city}
                        </option>
                      ))}
                    </Select>

                    <Input
                      ml={1}
                      pr={2}
                      pl={2}
                      borderLeftWidth="1.2px"
                      variant="flushed"
                      focusBorderColor="none"
                      fontSize="14px"
                      borderBottomWidth={0}
                      placeholder="Search by Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      fontFamily="Montserrat"
                    />
                    <Button
                      colorScheme="blue"
                      borderRadius={11}
                      onClick={fetchSummaryDataIndia}
                    >
                      <SearchIcon />
                    </Button>
                  </HStack>
                </VStack>
              </Box>
            </HStack>
          )}

          {selectedSummaryOption === "Date-range" && (
            <HStack>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                w="100%"
                p={4}
                gap="30px"
                borderWidth="1.2px"
                borderRadius="12px"
                borderColor="gray.500"
              >
                <VStack w="100%" align="stretch">
                  <Text
                    pr={5}
                    // borderColor="gray.400"
                    // borderRightWidth="1.2px"
                    textAlign="left"
                    fontFamily="Montserrat"
                  >
                    <b>Search in Abroad via Current Location</b>
                  </Text>
                  <HStack
                    spacing={0}
                    gap={0}
                    borderWidth="1.2px"
                    borderRadius="12px"
                    borderColor="gray.500"
                  >
                    <RangePicker
                    format={dateFormat}
                      bordered={false}
                      value={[startDate, endDate]}
                      onChange={handleRangeChange}
                    />

                    <Select
                      ml={1}
                      // pl={12}
                      textAlign="center"
                      variant="flushed"
                      placeholder="Select a country"
                      value={selectedCountry}
                      onChange={(e) => setSelectedCountry(e.target.value)}
                      borderLeftWidth="1.2px"
                      // pl={1}
                      // pr={2}
                      borderLeftColor="gray.500"
                      borderBottom="none"
                      focusBorderColor="none"
                      fontFamily="Montserrat"
                      fontSize="14px"
                      w="80%"
                    >
                      {countries.map((country, index) => (
                        <option key={index} value={country}>
                          {country}
                        </option>
                      ))}
                    </Select>

                    <Button
                      colorScheme="blue"
                      borderRadius={11}
                      onClick={() => {
                        fetchRangeSummaryDataAbroad();
                      }}
                    >
                      <SearchIcon />
                    </Button>
                  </HStack>
                </VStack>
              </Box>

              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                // marginTop="24px"
                w="100%"
                p={4}
                gap="30px"
                borderWidth="1.2px"
                borderRadius="12px"
                borderColor="gray.500"
              >
                <VStack w="100%" align="stretch">
                  <Text
                    pr={5}
                    // borderColor="gray.400"
                    // borderRightWidth="1.2px"
                    textAlign="left"
                    fontFamily="Montserrat"
                  >
                    <b>Search in India via Current Location</b>
                  </Text>
                  <HStack
                    spacing={0}
                    gap={0}
                    borderWidth="1.2px"
                    borderRadius="12px"
                    borderColor="gray.500"
                  >
                    <RangePicker
                    format={dateFormat}
                      bordered={false}
                      value={[startDate, endDate]}
                      onChange={handleRangeChange}
                    />

                    <Select
                      textAlign="center"
                      variant="flushed"
                      placeholder="Select a state"
                      borderLeftWidth="1.2px"
                      borderRightWidth="1.2px"
                      borderLeftColor="gray.500"
                      borderRightColor="gray.500"
                      focusBorderColor="none"
                      borderBottomWidth={0}
                      value={selectedState}
                      onChange={(e) => setSelectedState(e.target.value)}
                      borderColor="gray.400"
                      fontFamily="Montserrat"
                      fontSize="14px"
                      w="50%"
                    >
                      {states.map((state) => (
                        <option key={state} value={state}>
                          {state}
                        </option>
                      ))}
                    </Select>
                    <Select
                      variant="flushed"
                      placeholder="Select a city"
                      value={selectedCity}
                      onChange={(e) => setSelectedCity(e.target.value)}
                      borderColor="gray.400"
                      focusBorderColor="none"
                      borderBottomWidth={0}
                      textAlign="center"
                      fontFamily="Montserrat"
                      fontSize="14px"
                      w="50%"
                    >
                      {cities.map((city) => (
                        <option key={city} value={city}>
                          {city}
                        </option>
                      ))}
                    </Select>

                    {/* <Input
                      ml={1}
                      pr={2}
                      pl={2}
                      borderLeftWidth="1.2px"
                      variant="flushed"
                      focusBorderColor="none"
                      fontSize="14px"
                      borderBottomWidth={0}
                      placeholder="Search by Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      fontFamily="Montserrat"
                    /> */}
                    <Button
                      colorScheme="blue"
                      borderRadius={11}
                      onClick={() => {
                        fetchRangeSummaryDataIndia();
                      }}
                    >
                      <SearchIcon />
                    </Button>
                  </HStack>
                </VStack>
              </Box>
            </HStack>
          )}

          {summaryLoading && (
            <>
              {selectedSummaryOption === "Date-range" && (
                <HStack justify="flex-end">
                  <Input
                    placeholder="Search By Name"
                    value={rangeName}
                    onChange={(e) => setRangeName(e.target.value)}
                    w="40%"
                  />
                </HStack>
              )}

              <Box
                borderWidth={1}
                borderColor="gray.500"
                //  borderRadius="12px"
                overflowX="auto"
                maxW="100%"
                maxH="600px"
                overflowY="auto"
                sx={{
                  "&::-webkit-scrollbar": {
                    width: "12px",
                    backgroundColor: `rgba(0, 0, 0, 0.05)`,
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: `rgba(0, 0, 0, 0.05)`,
                  },
                }}
              >
                <Table variant="simple" height="100%" overflowY="auto">
                  <Thead position="sticky" top={-1}>
                    <Tr>
                      <Th
                        pr={1}
                        bgColor="gray.100"
                        fontFamily="Poppins"
                        border="1px solid gray"
                      >
                        #
                      </Th>
                      <Th
                        textAlign="center"
                        pr={1}
                        pl={1}
                        bgColor="gray.100"
                        fontFamily="Poppins"
                        border="1px solid gray"
                      >
                        Name
                      </Th>
                      <Th
                        textAlign="center"
                        pr={1}
                        pl={1}
                        bgColor="gray.100"
                        fontFamily="Poppins"
                        border="1px solid gray"
                      >
                        S/o ,D/o ,W/o NAME
                      </Th>
                      <Th
                        textAlign="center"
                        pr={1}
                        pl={1}
                        bgColor="gray.100"
                        fontFamily="Poppins"
                        border="1px solid gray"
                      >
                        Email
                      </Th>
                      <Th
                        textAlign="center"
                        pr={1}
                        pl={1}
                        bgColor="gray.100"
                        fontFamily="Poppins"
                        border="1px solid gray"
                      >
                        Mobile
                      </Th>
                      <Th
                        textAlign="center"
                        pr={1}
                        pl={1}
                        bgColor="gray.100"
                        fontFamily="Poppins"
                        border="1px solid gray"
                      >
                        Occupation
                      </Th>
                      <Th
                        textAlign="center"
                        pr={1}
                        pl={1}
                        bgColor="gray.100"
                        fontFamily="Poppins"
                        border="1px solid gray"
                      >
                        Country Type
                      </Th>
                      <Th
                        textAlign="center"
                        pr={1}
                        pl={1}
                        bgColor="gray.100"
                        fontFamily="Poppins"
                        border="1px solid gray"
                      >
                        Current Address
                      </Th>
                      <Th
                        textAlign="center"
                        pr={1}
                        pl={1}
                        bgColor="gray.100"
                        fontFamily="Poppins"
                        border="1px solid gray"
                      >
                        Native Place
                      </Th>
                      <Th
                        textAlign="center"
                        pr={1}
                        pl={1}
                        bgColor="gray.100"
                        fontFamily="Poppins"
                        border="1px solid gray"
                      >
                        Action
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {paginatedData.map((item, index) => (
                      <Tr key={item.id}>
                        <Td
                          textAlign="center"
                          pr={1}
                          pl={1}
                          border="1px solid gray"
                        >
                          {startIndex + index + 1}
                        </Td>
                        <Td
                          textAlign="center"
                          pr={1}
                          pl={1}
                          border="1px solid gray"
                        >
                          {item.full_name}
                        </Td>
                        <Td
                          textAlign="center"
                          pr={1}
                          pl={1}
                          border="1px solid gray"
                        >
                          {item.so_wo_do}
                        </Td>
                        <Td
                          textAlign="center"
                          pr={1}
                          pl={1}
                          border="1px solid gray"
                        >
                          {item.email}
                        </Td>
                        <Td
                          textAlign="center"
                          pr={1}
                          pl={1}
                          border="1px solid gray"
                        >
                          {item.mobile_number}
                        </Td>
                        <Td
                          textAlign="center"
                          pr={1}
                          pl={1}
                          border="1px solid gray"
                        >
                          {item.occupation}
                        </Td>
                        <Td
                          textAlign="center"
                          pr={1}
                          pl={1}
                          border="1px solid gray"
                        >
                          {item.country_type}
                        </Td>
                        <Td
                          textAlign="center"
                          pr={1}
                          pl={1}
                          border="1px solid gray"
                        >
                          {item.address}, {item.city}, {item.state},{" "}
                          {item.country}
                        </Td>
                        <Td
                          textAlign="center"
                          pr={1}
                          pl={1}
                          border="1px solid gray"
                        >
                          {item.native_district}, {item.native_tehsil},
                          {item.native_village}
                        </Td>
                        <Td
                          textAlign="center"
                          pr={1}
                          pl={1}
                          border="1px solid gray"
                        >
                          <DeleteIcon
                            colorScheme="red"
                            _hover={{ color: "red" }}
                            _focus={{ color: "red" }}
                            cursor="pointer"
                            mr="12px"
                            onClick={() => {
                              setDeleteMobile(item.mobile_number);
                              onOpen();
                            }}
                          />
                          <AlertDialog
                            isOpen={isOpen && deleteMobile === item.mobile_number}
                            leastDestructiveRef={undefined}
                            onClose={onClose}
                            isCentered
                          >
                            <AlertDialogOverlay />

                            <AlertDialogContent>
                              <AlertDialogHeader>
                                <Icon
                                  as={FaExclamationTriangle}
                                  mt={4}
                                  mr={2}
                                />
                                Delete User
                              </AlertDialogHeader>
                              <AlertDialogBody>
                                Are you sure you want to delete the user?
                              </AlertDialogBody>
                              <AlertDialogFooter>
                                <Button onClick={onClose}>Cancel</Button>
                                <Button
                                  colorScheme="red"
                                  ml={3}
                                  onClick={() => {
                                    deleteUser(deleteMobile);
                                  }}
                                  isLoading={isDeleting}
                                >
                                  Delete
                                </Button>
                              </AlertDialogFooter>
                            </AlertDialogContent>
                          </AlertDialog>

                          <Link as={ReachLink} to={`update/${item.mobile_number}`}>
                            <EditIcon
                              cursor="pointer"
                              _hover={{ color: "green" }}
                              _focus={{ color: "green" }}
                            />
                          </Link>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>

              <HStack justify="flex-end" spacing={4}>
                <Pagination
                  size="large"
                  current={currentPage}
                  total={totalItems}
                  pageSize={pageSize}
                  showSizeChanger={false}
                  onChange={handlePageChange}
                  showTotal={(total) => `Total ${total} items`}
                  showQuickJumper
                />
                <Flex alignItems="center">
                  <Select
                    value={pageSize}
                    onChange={handlePageSizeChange}
                    variant="outline"
                  >
                    {pageSizeOptions.map((option) => (
                      <option key={option} value={option}>
                        {option} / page
                      </option>
                    ))}
                  </Select>
                </Flex>
              </HStack>
            </>
          )}
        </VStack>
      ),
    },
    customTheme.breakpoints
  );

  const buttonLoginActivityLayout = useBreakpointValue(
    {
      base: (
        <VStack w="100%" align="stretch" flexDir="column" alignItems="center">
        <HStack
            display="flex"
            alignItems="center"
            justifyContent="right"
            flexWrap="wrap"
            >
                  <Button
                  type="submit"
                  colorScheme="red"
                  size="md"
                  fontFamily="Montserrat"
                  alignContent="right"
                  alignSelf="center"
                  onClick={()=> {
                    localStorage.removeItem("adminInfo");
                    navigate("/admin", { replace: true });
                    window.location.reload(false);
                  } }
                >
                  Logout
                </Button>

            </HStack>
          <Box
            as="button"
            borderRadius="md"
            bg="#0d6efd"
            color="white"
            px={6}
            h={10}
            w={buttonWidth}
          >
            <Link
              fontSize={12}
              textDecoration="none"
              as={ReachLink}
              to="/form"
            >
              <AddIcon pb={1} /> Add New Entry
            </Link>
          </Box>
          <Box
            as="button"
            borderRadius="md"
            bg="#0d6efd"
            color="white"
            px={6}
            h={10}
            // mb={4}
            w={buttonWidth}
            onClick={() => {
              setViewMap(true);
              setViewLoginActivity(false);
            }}
          >
            <Text fontSize={12}>View Map Activity</Text>
          </Box>
          <Box
            as="button"
            borderRadius="md"
            bg="#0d6efd"
            color="white"
            px={6}
            h={10}
            // mb={4}
            w={buttonWidth}
            onClick={() => {
              setViewHome(true);
              setViewLoginActivity(false);
            }}
          >
            <Text fontSize={12}>View Home</Text>
          </Box>
          <Box
            as="button"
            borderRadius="md"
            bg="#0d6efd"
            color="white"
            px={6}
            h={10}
            // mb={6}
            w={buttonWidth}
            onClick={() => {
              setViewMap(false);
              setViewLoginActivity(false);
              setViewSummary(true);
              setLoading(false);
            }}
          >
            <Text fontSize={12}>View Summary</Text>
          </Box>
          <Box
            as="button"
            borderRadius="md"
            bg="#0d6efd"
            color="white"
            px={6}
            h={14}
            // mb={4}
            w={buttonWidth}
            onClick={() => {
              setViewLoginActivity(false);
              setViewConsolidatedSummary(true);
              fetchConsolidatedSummary();
            }}
          >
            <Text fontSize={12}>View Consolidated Summary</Text>
          </Box>
          <Box
            overflowX="auto"
            maxW="100%"
            borderWidth={1}
            borderRadius="12px"
            maxH="600px"
            overflowY="auto"
            sx={{
              "&::-webkit-scrollbar": {
                width: "12px",
                backgroundColor: `rgba(0, 0, 0, 0.05)`,
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: `rgba(0, 0, 0, 0.05)`,
              },
            }}
          >
            <Table maxWidth="100%" whiteSpace="nowrap" variant="simple">
              <Thead position="sticky" top={-1}>
                <Tr>
                  <Th
                    pr={1}
                    bgColor="gray.100"
                    fontFamily="Poppins"
                    border="1px solid gray"
                  >
                    #
                  </Th>
                  <Th
                    pr={1}
                    bgColor="gray.100"
                    fontFamily="Poppins"
                    border="1px solid gray"
                  >
                    Name
                  </Th>
                  <Th
                    pr={1}
                    bgColor="gray.100"
                    fontFamily="Poppins"
                    border="1px solid gray"
                  >
                    Phone
                  </Th>
                  <Th
                    pr={1}
                    bgColor="gray.100"
                    fontFamily="Poppins"
                    border="1px solid gray"
                  >
                    Latest Visit Time
                  </Th>
                  <Th
                    pr={1}
                    bgColor="gray.100"
                    fontFamily="Poppins"
                    border="1px solid gray"
                  >
                    Total Visits
                  </Th>
                  <Th
                    pr={1}
                    bgColor="gray.100"
                    fontFamily="Poppins"
                    border="1px solid gray"
                  >
                    Action
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {paginatedData.map((item, index) => (
                  <Tr key={item.id}>
                    <Td
                      textAlign="center"
                      pr={1}
                      pl={1}
                      border="1px solid gray"
                    >
                      {index + 1}
                    </Td>
                    <Td
                      textAlign="center"
                      pr={1}
                      pl={1}
                      border="1px solid gray"
                    >
                      {item.name}
                    </Td>
                    <Td
                      textAlign="center"
                      pr={1}
                      pl={1}
                      border="1px solid gray"
                    >
                      {item.phone}
                    </Td>
                    <Td
                      textAlign="center"
                      pr={1}
                      pl={1}
                      border="1px solid gray"
                    >
                      {item.latestLoginTime}
                    </Td>
                    <Td
                      textAlign="center"
                      pr={1}
                      pl={1}
                      border="1px solid gray"
                    >
                      {item.totalVisits}
                    </Td>
                    <Td
                      textAlign="center"
                      pr={1}
                      pl={1}
                      border="1px solid gray"
                    >
                      <ViewIcon
                        cursor="pointer"
                        onClick={() => handleAction(item.name, item.phone)}
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>

          <HStack justify="flex-end" spacing={4}>
            <VStack w="100%" align="stretch">
              <Pagination
                size="large"
                current={currentPage}
                total={totalItems}
                pageSize={pageSize}
                showSizeChanger={false}
                onChange={handlePageChange}
                showTotal={(total) => `Total ${total} items`}
                showQuickJumper
              />
              <Flex alignItems="center">
                <Select
                  value={pageSize}
                  onChange={handlePageSizeChange}
                  variant="outline"
                >
                  {pageSizeOptions.map((option) => (
                    <option key={option} value={option}>
                      {option} / page
                    </option>
                  ))}
                </Select>
              </Flex>
            </VStack>
          </HStack>
          {showSubmissionTimes && (
            <Table>
              <Thead position="sticky" top={-1}>
                <Tr>
                  <Th
                    pr={1}
                    bgColor="gray.100"
                    fontFamily="Poppins"
                    border="1px solid gray"
                  >
                    #
                  </Th>
                  <Th
                    pr={1}
                    bgColor="gray.100"
                    fontFamily="Poppins"
                    border="1px solid gray"
                  >
                    Login Time
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {submissionTimes.map((time, index) => (
                  <Tr key={index}>
                    <Td
                      textAlign="center"
                      pr={1}
                      pl={1}
                      border="1px solid gray"
                    >
                      {index + 1}
                    </Td>
                    <Td
                      textAlign="center"
                      pr={1}
                      pl={1}
                      border="1px solid gray"
                    >
                      {time.submission_time}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          )}
        </VStack>
      ),
      md: (
        <VStack w="100%" align="stretch" spacing={4}>
           <HStack
            display="flex"
            alignItems="center"
            justifyContent="right"
            flexWrap="wrap"
            >
                  <Button
                  type="submit"
                  colorScheme="red"
                  size="md"
                  fontFamily="Montserrat"
                  alignContent="right"
                  alignSelf="center"
                  onClick={()=> {
                    localStorage.removeItem("adminInfo");
                    navigate("/admin", { replace: true });
                    window.location.reload(false);
                  } }
                >
                  Logout
                </Button>

            </HStack>
          <HStack
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexWrap="wrap"
          >
            <Link as={ReachLink} to="/form">
              <Box
                as="button"
                borderRadius="md"
                bg="#0d6efd"
                color="white"
                px={6}
                h={10}
                mb={0.9999}
                w={buttonWidth}
              >
                <AddIcon pb={1} /> Add New Entry
              </Box>
            </Link>
            <Box
              as="button"
              borderRadius="md"
              bg="#0d6efd"
              color="white"
              px={6}
              h={10}
              // mb={6}
              w={buttonWidth}
              onClick={() => {
                setViewMap(true);
                setViewLoginActivity(false);
              }}
            >
              View Map Activity
            </Box>
            <Box
              as="button"
              borderRadius="md"
              bg="#0d6efd"
              color="white"
              px={6}
              h={10}
              // mb={6}
              w={buttonWidth}
              onClick={() => {
                setViewMap(false);
                setViewHome(true);
                setViewLoginActivity(false);
              }}
            >
              View Home
            </Box>
            <Box
              as="button"
              borderRadius="md"
              bg="#0d6efd"
              color="white"
              px={6}
              h={10}
              // mb={6}
              w={buttonWidth}
              onClick={() => {
                setViewMap(false);
                setViewLoginActivity(false);
                setViewSummary(true);
                setLoading(false);
              }}
            >
              View Summary
            </Box>
            <Box
              as="button"
              borderRadius="md"
              bg="#0d6efd"
              color="white"
              px={6}
              h={10}
              // mb={6}
              w={buttonWidth}
              onClick={() => {
                setViewMap(false);
                setViewLoginActivity(false);
                setViewConsolidatedSummary(true);
                fetchConsolidatedSummary();
                setSummaryLoading(false);
              }}
            >
              View Consolidated Summary
            </Box>
          </HStack>
          <Box
            overflowX="auto"
            maxW="100%"
            maxH="600px"
            overflowY="auto"
            sx={{
              "&::-webkit-scrollbar": {
                width: "12px",
                backgroundColor: `rgba(0, 0, 0, 0.05)`,
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: `rgba(0, 0, 0, 0.05)`,
              },
            }}
          >
            <Table
              maxWidth="100%"
              whiteSpace="nowrap"
              variant="simple"
              overflowY="auto"
            >
              <Thead position="sticky" top={-1}>
                <Tr>
                  <Th
                    pr={1}
                    bgColor="gray.100"
                    fontFamily="Poppins"
                    border="1px solid gray"
                  >
                    #
                  </Th>
                  <Th
                    textAlign="center"
                    pr={1}
                    pl={1}
                    bgColor="gray.100"
                    fontFamily="Poppins"
                    border="1px solid gray"
                  >
                    Name
                  </Th>
                  <Th
                    textAlign="center"
                    pr={1}
                    pl={1}
                    bgColor="gray.100"
                    fontFamily="Poppins"
                    border="1px solid gray"
                  >
                    Phone
                  </Th>
                  <Th
                    textAlign="center"
                    pr={1}
                    pl={1}
                    bgColor="gray.100"
                    fontFamily="Poppins"
                    border="1px solid gray"
                  >
                    Latest Visit Time
                  </Th>
                  <Th
                    textAlign="center"
                    pr={1}
                    pl={1}
                    bgColor="gray.100"
                    fontFamily="Poppins"
                    border="1px solid gray"
                  >
                    Total Visits
                  </Th>
                  <Th
                    textAlign="center"
                    pr={1}
                    pl={1}
                    bgColor="gray.100"
                    fontFamily="Poppins"
                    border="1px solid gray"
                  >
                    Action
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {paginatedData.map((item, index) => (
                  <Tr key={item.id}>
                    <Td
                      textAlign="center"
                      pr={1}
                      pl={1}
                      border="1px solid gray"
                    >
                      {startIndex + index + 1}
                    </Td>
                    <Td
                      textAlign="center"
                      pr={1}
                      pl={1}
                      border="1px solid gray"
                    >
                      {item.name}
                    </Td>
                    <Td
                      textAlign="center"
                      pr={1}
                      pl={1}
                      border="1px solid gray"
                    >
                      {item.phone}
                    </Td>
                    <Td
                      textAlign="center"
                      pr={1}
                      pl={1}
                      border="1px solid gray"
                    >
                      {item.latestLoginTime}
                    </Td>
                    <Td
                      pr={1}
                      pl={1}
                      border="1px solid gray"
                      textAlign="center"
                    >
                      {item.totalVisits}
                    </Td>
                    <Td
                      pr={1}
                      pl={1}
                      border="1px solid gray"
                      textAlign="center"
                    >
                      <ViewIcon
                        cursor="pointer"
                        onClick={() => handleAction(item.name, item.phone)}
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>

          <HStack justify="flex-end" spacing={4}>
            <Pagination
              size="large"
              current={currentPage}
              total={totalItems}
              pageSize={pageSize}
              showSizeChanger={false}
              onChange={handlePageChange}
              showTotal={(total) => `Total ${total} items`}
              showQuickJumper
            />
            <Flex alignItems="center">
              <Select
                value={pageSize}
                onChange={handlePageSizeChange}
                variant="outline"
              >
                {pageSizeOptions.map((option) => (
                  <option key={option} value={option}>
                    {option} / page
                  </option>
                ))}
              </Select>
            </Flex>
          </HStack>
          {showSubmissionTimes && (
            <Table w="500px" borderColor="gray.500">
              <Thead position="sticky" top={-1}>
                <Tr>
                  <Th
                    pr={1}
                    bgColor="gray.100"
                    fontFamily="Poppins"
                    border="1px solid gray"
                  >
                    #
                  </Th>
                  <Th
                    pr={1}
                    bgColor="gray.100"
                    fontFamily="Poppins"
                    border="1px solid gray"
                  >
                    Login Time
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {submissionTimes.map((time, index) => (
                  <Tr key={index}>
                    <Td
                      // textAlign="center"
                      pr={1}
                      pl={1}
                      border="1px solid gray"
                    >
                      {index + 1}
                    </Td>
                    <Td
                      pr={1}
                      pl={1}
                      border="1px solid gray"
                      // textAlign="center"
                    >
                      {time.submission_time}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          )}
        </VStack>
      ),
    },
    customTheme.breakpoints
  );

  const buttonConsolidatedSummaryLayout = useBreakpointValue(
    {
      base: (
        <VStack w="100%" align="stretch" flexDir="column" alignItems="center">
          <HStack
            display="flex"
            alignItems="center"
            justifyContent="right"
            flexWrap="wrap"
            >
                  <Button
                  type="submit"
                  colorScheme="red"
                  size="md"
                  fontFamily="Montserrat"
                  alignContent="right"
                  alignSelf="center"
                  onClick={()=> {
                    localStorage.removeItem("adminInfo");
                    navigate("/admin", { replace: true });
                    window.location.reload(false);
                  } }
                >
                  Logout
                </Button>

            </HStack>
          <Box
            as="button"
            borderRadius="md"
            bg="#0d6efd"
            color="white"
            px={6}
            h={10}
            w={buttonWidth}
          >
            <Link
              fontSize={12}
              textDecoration="none"
              as={ReachLink}
              to="/form"
            >
              <AddIcon pb={1} /> Add New Entry
            </Link>
          </Box>
          <Box
            as="button"
            borderRadius="md"
            bg="#0d6efd"
            color="white"
            px={6}
            h={10}
            // mb={6}
            w={buttonWidth}
            onClick={() => {
              setViewConsolidatedSummary(false);
              setViewLoginActivity(true);
              fetchLoginData();
            }}
          >
            <Text fontSize={12}>View Login Activity</Text>
          </Box>
          <Box
            as="button"
            borderRadius="md"
            bg="#0d6efd"
            color="white"
            px={6}
            h={10}
            // mb={4}
            w={buttonWidth}
            onClick={() => {
              setViewMap(true);
              setViewConsolidatedSummary(false);
            }}
          >
            <Text fontSize={12}>View Map Activity</Text>
          </Box>
          <Box
            as="button"
            borderRadius="md"
            bg="#0d6efd"
            color="white"
            px={6}
            h={10}
            // mb={4}
            w={buttonWidth}
            onClick={() => {
              setViewHome(true);
              setViewMap(false);
              setViewConsolidatedSummary(false);
            }}
          >
            <Text fontSize={12}>View Home</Text>
          </Box>
          <Box
            as="button"
            borderRadius="md"
            bg="#0d6efd"
            color="white"
            px={6}
            h={10}
            // mb={4}
            w={buttonWidth}
            onClick={() => {
              setViewConsolidatedSummary(false);
              setViewMap(false);
              setViewSummary(true);
            }}
          >
            <Text fontSize={12}>View Summary</Text>
          </Box>
          <Box
            as="button"
            borderRadius="md"
            bg="#0d6efd"
            color="white"
            px={6}
            h={10}
            // mb={4}
            w={buttonWidth}
            onClick={() => {
              generateCSVFile();
              setIsModalOpen(true);
            }}
          >
            <Text fontSize={12}>Update Google Sheets</Text>
          </Box>
          <Modal isOpen={isModalOpen} onClose={handleCloseModal} isCentered>
            <ModalOverlay
              bg="rgba(0, 0, 0, 0.6)"
              backdropFilter="blur(8px)"
              zIndex="modalOverlay"
            />
            <ModalContent>
              <ModalCloseButton />
              <ModalBody mt={12}>
                <Text fontSize="14px" fontFamily="Montserrat">
                  The Google Sheet has been successfully updated.
                </Text>
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="blue" mr={3} onClick={handleViewSheet}>
                  View Sheet
                </Button>
                <Button colorScheme="gray" onClick={handleCloseModal}>
                  Back
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
          <HStack justify="flex-end" w="90%">
            <Input
              placeholder="Search By Name"
              value={consolidatedSearch}
              onChange={(e) => setConsolidatedSearch(e.target.value)}
            />
          </HStack>
          <Box
            borderWidth={1}
            borderColor="gray.500"
            //  borderRadius="12px"
            overflowX="auto"
            maxW="100%"
            maxH="600px"
            overflowY="auto"
            sx={{
              "&::-webkit-scrollbar": {
                width: "12px",
                backgroundColor: `rgba(0, 0, 0, 0.05)`,
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: `rgba(0, 0, 0, 0.05)`,
              },
            }}
          >
            <Table variant="simple" height="100%">
              <Thead position="sticky" top={-1}>
                <Tr>
                  <Th
                    pr={1}
                    bgColor="gray.100"
                    fontFamily="Poppins"
                    border="1px solid gray"
                  >
                    #
                  </Th>
                  <Th
                    textAlign="center"
                    pr={1}
                    pl={1}
                    bgColor="gray.100"
                    fontFamily="Poppins"
                    border="1px solid gray"
                  >
                    Name
                  </Th>
                  <Th
                    textAlign="center"
                    pr={1}
                    pl={1}
                    bgColor="gray.100"
                    fontFamily="Poppins"
                    border="1px solid gray"
                  >
                    S/o ,D/o ,W/o NAME
                  </Th>
                  <Th
                    textAlign="center"
                    pr={1}
                    pl={1}
                    bgColor="gray.100"
                    fontFamily="Poppins"
                    border="1px solid gray"
                  >
                    Email
                  </Th>
                  <Th
                    textAlign="center"
                    pr={1}
                    pl={1}
                    bgColor="gray.100"
                    fontFamily="Poppins"
                    border="1px solid gray"
                  >
                    Mobile
                  </Th>
                  <Th
                    textAlign="center"
                    pr={1}
                    pl={1}
                    bgColor="gray.100"
                    fontFamily="Poppins"
                    border="1px solid gray"
                  >
                    Occupation
                  </Th>
                  <Th
                    textAlign="center"
                    pr={1}
                    pl={1}
                    bgColor="gray.100"
                    fontFamily="Poppins"
                    border="1px solid gray"
                  >
                    Country Type
                  </Th>
                  <Th
                    textAlign="center"
                    pr={1}
                    pl={1}
                    bgColor="gray.100"
                    fontFamily="Poppins"
                    border="1px solid gray"
                  >
                    Current Address
                  </Th>
                  <Th
                    textAlign="center"
                    pr={1}
                    pl={1}
                    bgColor="gray.100"
                    fontFamily="Poppins"
                    border="1px solid gray"
                  >
                    Native Place
                  </Th>
                  <Th
                    textAlign="center"
                    pr={1}
                    pl={1}
                    bgColor="gray.100"
                    fontFamily="Poppins"
                    border="1px solid gray"
                  >
                    Action
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {paginatedData.map((item, index) => (
                  <Tr key={item.id}>
                    <Td
                      textAlign="center"
                      pr={1}
                      pl={1}
                      border="1px solid gray"
                    >
                      {startIndex + index + 1}
                    </Td>
                    <Td
                      textAlign="center"
                      pr={1}
                      pl={1}
                      border="1px solid gray"
                    >
                      {item.full_name}
                    </Td>
                    <Td
                      textAlign="center"
                      pr={1}
                      pl={1}
                      border="1px solid gray"
                    >
                      {item.so_wo_do}
                    </Td>
                    <Td
                      textAlign="center"
                      pr={1}
                      pl={1}
                      border="1px solid gray"
                    >
                      {item.email}
                    </Td>
                    <Td
                      textAlign="center"
                      pr={1}
                      pl={1}
                      border="1px solid gray"
                    >
                      {item.mobile_number}
                    </Td>
                    <Td
                      textAlign="center"
                      pr={1}
                      pl={1}
                      border="1px solid gray"
                    >
                      {item.occupation}
                    </Td>
                    <Td
                      textAlign="center"
                      pr={1}
                      pl={1}
                      border="1px solid gray"
                    >
                      {item.country_type}
                    </Td>
                    <Td
                      textAlign="center"
                      pr={1}
                      pl={1}
                      border="1px solid gray"
                    >
                      {item.address}, {item.city}, {item.state}, {item.country}
                    </Td>
                    <Td
                      textAlign="center"
                      pr={1}
                      pl={1}
                      border="1px solid gray"
                    >
                      {item.native_district}, {item.native_tehsil},
                      {item.native_village}
                    </Td>
                    <Td
                      textAlign="center"
                      pr={1}
                      pl={1}
                      border="1px solid gray"
                    >
                      <DeleteIcon
                        colorScheme="red"
                        _hover={{ color: "red" }}
                        _focus={{ color: "red" }}
                        cursor="pointer"
                        mr="12px"
                        onClick={() => {
                          setDeleteMobile(item.mobile_number);
                          onOpen();
                        }}
                      />
                      <AlertDialog
                        isOpen={isOpen && deleteMobile === item.mobile_number}
                        leastDestructiveRef={undefined}
                        onClose={onClose}
                        isCentered
                      >
                        <AlertDialogOverlay />

                        <AlertDialogContent>
                          <AlertDialogHeader>
                            <Icon as={FaExclamationTriangle} mt={4} mr={2} />
                            Delete User
                          </AlertDialogHeader>
                          <AlertDialogBody>
                            Are you sure you want to delete the user?
                          </AlertDialogBody>
                          <AlertDialogFooter>
                            <Button onClick={onClose}>Cancel</Button>
                            <Button
                              colorScheme="red"
                              ml={3}
                              onClick={() => {
                                deleteUser(deleteMobile);
                              }}
                              isLoading={isDeleting}
                            >
                              Delete
                            </Button>
                          </AlertDialogFooter>
                        </AlertDialogContent>
                      </AlertDialog>

                      <Link as={ReachLink} to={`update/${item.mobile_number}`}>
                        <EditIcon
                          cursor="pointer"
                          _hover={{ color: "green" }}
                          _focus={{ color: "green" }}
                        />
                      </Link>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>

          <VStack justify="flex-end" spacing={4}>
            <Pagination
              size="large"
              current={currentPage}
              total={totalItems}
              pageSize={pageSize}
              showSizeChanger={false}
              onChange={handlePageChange}
              showTotal={(total) => `Total ${total} items`}
              showQuickJumper
            />
            {/* <Flex alignItems="center"> */}
            <Select
              value={pageSize}
              onChange={handlePageSizeChange}
              variant="outline"
            >
              {pageSizeOptions.map((option) => (
                <option key={option} value={option}>
                  {option} / page
                </option>
              ))}
            </Select>
            {/* </Flex> */}
          </VStack>
        </VStack>
      ),
      md: (
        <VStack w="100%" align="stretch" spacing={4} p={4}>
           <HStack
            display="flex"
            alignItems="center"
            justifyContent="right"
            flexWrap="wrap"
            >
                  <Button
                  type="submit"
                  colorScheme="red"
                  size="md"
                  fontFamily="Montserrat"
                  alignContent="right"
                  alignSelf="center"
                  onClick={()=> {
                    localStorage.removeItem("adminInfo");
                    navigate("/admin", { replace: true });
                    window.location.reload(false);
                  } }
                >
                  Logout
                </Button>

            </HStack>
          <HStack
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexWrap="wrap"
          >
            <Link as={ReachLink} to="/form">
              <Box
                as="button"
                borderRadius="md"
                bg="#0d6efd"
                color="white"
                px={6}
                h={10}
                mb={0.9999}
                w={buttonWidth}
              >
                <AddIcon pb={1} /> Add New Entry
              </Box>
            </Link>
            <Box
              as="button"
              borderRadius="md"
              bg="#0d6efd"
              color="white"
              px={6}
              h={10}
              // mb={6}
              w={buttonWidth}
              onClick={() => {
                setViewConsolidatedSummary(false);
                setViewLoginActivity(true);
                fetchLoginData();
              }}
            >
              View Login Activity
            </Box>
            <Box
              as="button"
              borderRadius="md"
              bg="#0d6efd"
              color="white"
              px={6}
              h={10}
              // mb={4}
              w={buttonWidth}
              onClick={() => {
                setViewMap(true);
                setViewConsolidatedSummary(false);
              }}
            >
              <Text isTruncated>View Map Activity</Text>
            </Box>
            <Box
              as="button"
              borderRadius="md"
              bg="#0d6efd"
              color="white"
              px={6}
              h={10}
              // mb={4}
              w={buttonWidth}
              onClick={() => {
                setViewHome(true);
                setViewMap(false);
                setViewConsolidatedSummary(false);
              }}
            >
              <Text isTruncated>View Home</Text>
            </Box>
            <Box
              as="button"
              borderRadius="md"
              bg="#0d6efd"
              color="white"
              px={6}
              h={10}
              // mb={4}
              w={buttonWidth}
              onClick={() => {
                setViewConsolidatedSummary(false);
                setViewMap(false);
                setViewSummary(true);
              }}
            >
              <Text>View Summary</Text>
            </Box>
            <Box
              as="button"
              borderRadius="md"
              bg="#0d6efd"
              color="white"
              px={6}
              h={10}
              // mb={4}
              w={buttonWidth}
              onClick={() => {
                generateCSVFile();
                setIsModalOpen(true);
              }}
            >
              <Text>Update Google Sheets</Text>
            </Box>
            <Modal isOpen={isModalOpen} onClose={handleCloseModal} isCentered>
              <ModalOverlay
                bg="rgba(0, 0, 0, 0.6)"
                backdropFilter="blur(8px)"
                zIndex="modalOverlay"
              />
              <ModalContent>
                <ModalCloseButton />
                <ModalBody mt={12}>
                  <Text fontSize="14px" fontFamily="Montserrat">
                    The Google Sheet has been successfully updated.
                  </Text>
                </ModalBody>
                <ModalFooter>
                  <Button colorScheme="blue" mr={3} onClick={handleViewSheet}>
                    View Sheet
                  </Button>
                  <Button colorScheme="gray" onClick={handleCloseModal}>
                    Back
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </HStack>
          <HStack justify="flex-end">
            <Input
              placeholder="Search By Name"
              value={consolidatedSearch}
              onChange={(e) => setConsolidatedSearch(e.target.value)}
              w="40%"
            />
            {/* <Button>
              <SearchIcon
                onClick={() => {
                  fetchDataByName();
                }}
              />
            </Button> */}
          </HStack>
          <Box
            borderWidth={1}
            borderColor="gray.500"
            overflowX="auto"
            maxW="100%"
            maxH="600px"
            overflowY="auto"
            sx={{
              "&::-webkit-scrollbar": {
                width: "12px",
                backgroundColor: `rgba(0, 0, 0, 0.05)`,
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: `rgba(0, 0, 0, 0.05)`,
              },
            }}
          >
            <Table variant="simple" height="100%">
              <Thead position="sticky" top={-1}>
                <Tr>
                  <Th
                    pr={1}
                    bgColor="gray.100"
                    fontFamily="Poppins"
                    border="1px solid gray"
                  >
                    #
                  </Th>
                  <Th
                    textAlign="center"
                    pr={1}
                    pl={1}
                    bgColor="gray.100"
                    fontFamily="Poppins"
                    border="1px solid gray"
                  >
                    Name
                  </Th>
                  <Th
                    textAlign="center"
                    pr={1}
                    pl={1}
                    bgColor="gray.100"
                    fontFamily="Poppins"
                    border="1px solid gray"
                  >
                    S/o ,D/o ,W/o NAME
                  </Th>
                  <Th
                    textAlign="center"
                    pr={1}
                    pl={1}
                    bgColor="gray.100"
                    fontFamily="Poppins"
                    border="1px solid gray"
                  >
                    Email
                  </Th>
                  <Th
                    textAlign="center"
                    pr={1}
                    pl={1}
                    bgColor="gray.100"
                    fontFamily="Poppins"
                    border="1px solid gray"
                  >
                    Mobile
                  </Th>
                  <Th
                    textAlign="center"
                    pr={1}
                    pl={1}
                    bgColor="gray.100"
                    fontFamily="Poppins"
                    border="1px solid gray"
                  >
                    Occupation
                  </Th>
                  <Th
                    textAlign="center"
                    pr={1}
                    pl={1}
                    bgColor="gray.100"
                    fontFamily="Poppins"
                    border="1px solid gray"
                  >
                    Country Type
                  </Th>
                  <Th
                    textAlign="center"
                    pr={1}
                    pl={1}
                    bgColor="gray.100"
                    fontFamily="Poppins"
                    border="1px solid gray"
                  >
                    Current Address
                  </Th>
                  <Th
                    textAlign="center"
                    pr={1}
                    pl={1}
                    bgColor="gray.100"
                    fontFamily="Poppins"
                    border="1px solid gray"
                  >
                    Native Place
                  </Th>
                  <Th
                    textAlign="center"
                    pr={1}
                    pl={1}
                    bgColor="gray.100"
                    fontFamily="Poppins"
                    border="1px solid gray"
                  >
                    Action
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {paginatedData.map((item, index) => (
                  <Tr key={item.id}>
                    <Td
                      textAlign="center"
                      pr={1}
                      pl={1}
                      border="1px solid gray"
                    >
                      {startIndex + index + 1}
                    </Td>
                    <Td
                      textAlign="center"
                      pr={1}
                      pl={1}
                      border="1px solid gray"
                    >
                      {item.full_name}
                    </Td>
                    <Td
                      textAlign="center"
                      pr={1}
                      pl={1}
                      border="1px solid gray"
                    >
                      {item.so_wo_do}
                    </Td>
                    <Td
                      textAlign="center"
                      pr={1}
                      pl={1}
                      border="1px solid gray"
                    >
                      {item.email}
                    </Td>
                    <Td
                      textAlign="center"
                      pr={1}
                      pl={1}
                      border="1px solid gray"
                    >
                      {item.mobile_number}
                    </Td>
                    <Td
                      textAlign="center"
                      pr={1}
                      pl={1}
                      border="1px solid gray"
                    >
                      {item.occupation}
                    </Td>
                    <Td
                      textAlign="center"
                      pr={1}
                      pl={1}
                      border="1px solid gray"
                    >
                      {item.country_type}
                    </Td>
                    <Td
                      textAlign="center"
                      pr={1}
                      pl={1}
                      border="1px solid gray"
                    >
                      {item.address}, {item.city}, {item.state}, {item.country}
                    </Td>
                    <Td
                      textAlign="center"
                      pr={1}
                      pl={1}
                      border="1px solid gray"
                    >
                      {item.native_district}, {item.native_tehsil},
                      {item.native_village}
                    </Td>
                    <Td
                      textAlign="center"
                      pr={1}
                      pl={1}
                      border="1px solid gray"
                    >
                      <DeleteIcon
                        colorScheme="red"
                        _hover={{ color: "red" }}
                        _focus={{ color: "red" }}
                        cursor="pointer"
                        mr="12px"
                        onClick={() => {
                          setDeleteMobile(item.mobile_number);
                          onOpen();
                        }}
                      />
                      <AlertDialog
                        isOpen={isOpen && deleteMobile === item.mobile_number}
                        leastDestructiveRef={undefined}
                        onClose={onClose}
                        isCentered
                      >
                        <AlertDialogOverlay />

                        <AlertDialogContent>
                          <AlertDialogHeader>
                            <Icon as={FaExclamationTriangle} mt={4} mr={2} />
                            Delete User
                          </AlertDialogHeader>
                          <AlertDialogBody>
                            Are you sure you want to delete the user?
                          </AlertDialogBody>
                          <AlertDialogFooter>
                            <Button onClick={onClose}>Cancel</Button>
                            <Button
                              colorScheme="red"
                              ml={3}
                              onClick={() => {
                                deleteUser(deleteMobile);
                              }}
                              isLoading={isDeleting}
                            >
                              Delete
                            </Button>
                          </AlertDialogFooter>
                        </AlertDialogContent>
                      </AlertDialog>

                      <Link as={ReachLink} to={`update/${item.mobile_number}`}>
                        <EditIcon
                          cursor="pointer"
                          _hover={{ color: "green" }}
                          _focus={{ color: "green" }}
                        />
                      </Link>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>

          <HStack justify="flex-end" spacing={4}>
            <Pagination
              size="large"
              current={currentPage}
              total={totalItems}
              pageSize={pageSize}
              showSizeChanger={false}
              onChange={handlePageChange}
              showTotal={(total) => `Total ${total} items`}
              pageSizeOptions={pageSizeOptions.map((option) => `${option}`)}
              showQuickJumper
            />
            <Flex alignItems="center">
              <Select
                value={pageSize}
                onChange={handlePageSizeChange}
                variant="outline"
              >
                {pageSizeOptions.map((option) => (
                  <option key={option} value={option}>
                    {option} / page
                  </option>
                ))}
              </Select>
            </Flex>
          </HStack>
        </VStack>
      ),
    },
    customTheme.breakpoints
  );

  const buttonMapLayout = useBreakpointValue(
    {
      base: (
        <VStack w="100%" align="stretch" flexDir="column" alignItems="center">
          <HStack
            display="flex"
            alignItems="center"
            justifyContent="right"
            flexWrap="wrap"
            >
                  <Button
                  type="submit"
                  colorScheme="red"
                  size="md"
                  fontFamily="Montserrat"
                  alignContent="right"
                  alignSelf="center"
                  onClick={()=> {
                    localStorage.removeItem("adminInfo");
                    navigate("/admin", { replace: true });
                    window.location.reload(false);
                  } }
                >
                  Logout
                </Button>

            </HStack>
          <Box
            as="button"
            borderRadius="md"
            bg="#0d6efd"
            color="white"
            px={6}
            h={12}
            w={buttonWidth}
          >
            <Link
              fontSize={12}
              textDecoration="none"
              as={ReachLink}
              to="/form"
            >
              <AddIcon pb={1} /> Add New Entry
            </Link>
          </Box>
          <Box
            as="button"
            borderRadius="md"
            bg="#0d6efd"
            color="white"
            px={6}
            h={10}
            // mb={6}
            w={buttonWidth}
            onClick={() => {
              setViewMap(false);
              setViewLoginActivity(true);
              fetchLoginData();
            }}
          >
            <Text fontSize={12}>View Login Activity</Text>
          </Box>
          <Box
            as="button"
            borderRadius="md"
            bg="#0d6efd"
            color="white"
            px={6}
            h={10}
            // mb={4}
            w={buttonWidth}
            onClick={() => {
              setViewHome(true);
              setViewMap(false);
            }}
          >
            <Text fontSize="12px">View Home</Text>
          </Box>
          <Box
            as="button"
            borderRadius="md"
            bg="#0d6efd"
            color="white"
            px={6}
            h={10}
            // mb={4}
            w={buttonWidth}
            onClick={() => {
              setViewMap(false);
              setViewSummary(true);
            }}
          >
            <Text fontSize="12px" isTruncated>
              View Summary
            </Text>
          </Box>
          <Box
            as="button"
            borderRadius="md"
            bg="#0d6efd"
            color="white"
            px={6}
            h={14}
            // mb={4}
            w={buttonWidth}
            onClick={() => {
              setViewMap(false);
              setViewConsolidatedSummary(true);
              fetchConsolidatedSummary();
            }}
          >
            <Text fontSize="12px">View Consolidated Summary</Text>
          </Box>
          <FormControl id="map" w="100%">
            <HStack
              spacing="24px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexWrap="wrap"
              mt={4}
            >
              <FormLabel fontWeight="extrabold" fontFamily="Montserrat" mt={2}>
                Select a map to search :
              </FormLabel>
              <RadioGroup
                value={selectedMap}
                onChange={(value) => setSelectedMap(value)}
              >
                <VStack justify="flex-start">
                  <Radio value="India" size="lg" alignContent="center">
                    India
                  </Radio>
                  <Radio value="Abroad" size="lg">
                    Abroad
                  </Radio>

                  <Radio value="Rajasthan" size="lg">
                    Rajasthan
                  </Radio>
                </VStack>
              </RadioGroup>
            </HStack>
          </FormControl>

          {selectedMap === "India" && <India />}
          {selectedMap === "Abroad" && <World />}
          {selectedMap === "Rajasthan" && <Rajasthan />}
        </VStack>
      ),

      md: (
        <VStack w="100%" align="stretch" spacing={4} p={4}>
           <HStack
            display="flex"
            alignItems="center"
            justifyContent="right"
            flexWrap="wrap"
            >
                  <Button
                  type="submit"
                  colorScheme="red"
                  size="md"
                  fontFamily="Montserrat"
                  alignContent="right"
                  alignSelf="center"
                  onClick={()=> {
                    localStorage.removeItem("adminInfo");
                    navigate("/admin", { replace: true });
                    window.location.reload(false);
                  } }
                >
                  Logout
                </Button>

            </HStack>
          <HStack
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexWrap="wrap"
          >
            <Link as={ReachLink} to="/form">
              <Box
                as="button"
                borderRadius="md"
                bg="#0d6efd"
                color="white"
                px={6}
                h={10}
                mb={0.9999}
                w={buttonWidth}
              >
                <AddIcon pb={1} /> Add New Entry
              </Box>
            </Link>
            <Box
              as="button"
              borderRadius="md"
              bg="#0d6efd"
              color="white"
              px={6}
              h={10}
              // mb={6}
              w={buttonWidth}
              onClick={() => {
                setViewMap(false);
                setViewLoginActivity(true);
                fetchLoginData();
              }}
            >
              View Login Activity
            </Box>
            <Box
              as="button"
              borderRadius="md"
              bg="#0d6efd"
              color="white"
              px={6}
              h={10}
              // mb={6}
              w={buttonWidth}
              onClick={() => {
                setViewHome(true);
                setViewMap(false);
              }}
            >
              View Home
            </Box>
            <Box
              as="button"
              borderRadius="md"
              bg="#0d6efd"
              color="white"
              px={6}
              h={10}
              // mb={6}
              w={buttonWidth}
              onClick={() => {
                setViewMap(false);
                setViewSummary(true);
                setLoading(false);
              }}
            >
              View Summary
            </Box>
            <Box
              as="button"
              borderRadius="md"
              bg="#0d6efd"
              color="white"
              px={6}
              h={10}
              // mb={6}
              w={buttonWidth}
              onClick={() => {
                setViewMap(false);
                setViewConsolidatedSummary(true);
                fetchConsolidatedSummary();
              }}
            >
              View Consolidated Summary
            </Box>
          </HStack>

          <FormControl id="map" w="100%">
            <HStack
              spacing="24px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexWrap="wrap"
              mt={4}
            >
              <FormLabel fontWeight="extrabold" fontFamily="Montserrat" mt={2}>
                Select a map to search :
              </FormLabel>
              <RadioGroup
                value={selectedMap}
                onChange={(value) => setSelectedMap(value)}
              >
                <HStack spacing="24px">
                  <Radio value="India" size="lg">
                    India
                  </Radio>
                  <Radio value="Abroad" size="lg">
                    Abroad
                  </Radio>

                  <Radio value="Rajasthan" size="lg">
                    Rajasthan
                  </Radio>
                </HStack>
              </RadioGroup>
            </HStack>
          </FormControl>

          {selectedMap === "India" && <India />}
          {selectedMap === "Abroad" && <World />}
          {selectedMap === "Rajasthan" && <Rajasthan />}
        </VStack>
      ),
    },
    customTheme.breakpoints
  );

  return (
    <Box
      display={{ base: "block", md: "flex" }}
      justifyContent="space-between"
      alignContent="center"
      alignItems="center"
      w={{ base: "auto", md: "90%" }}
      // borderRadius="lg"
      p={{ base: 4, md: 6 }}
      bg="white"
      // borderWidth="1px"
      // borderColor="gray.300"
      ml={{ base: 4, md: "auto" }}
      mr={{ base: 4, md: "auto" }}
      mt={{ base: 4, md: 8 }}
      mb={{ base: 4, md: 8 }}
      max-width="1320px"
      h="fit-content"
      overflowY="scroll"
    >
      {viewHome ? (
        <Box
          display={{ base: "block", md: "flex" }}
          justifyContent="space-between"
          w="100%"
          borderRadius="lg"
          // p={{ base: 4, md: 6 }}
          bg="white"
          // borderWidth="2px"
          // borderColor="gray.300"
          // ml={{ base: 4, md: 8 }}
          // mr={{ base: 4, md: 8 }}
          mt={{ base: 4, md: 8 }}
          mb={{ base: 4, md: 8 }}
          overflowY="hidden"
        >
          {buttonHomeLayout}
        </Box>
      ) : null}

      {viewSummary ? (
        <Box
          display={{ base: "block", md: "flex" }}
          justifyContent="space-between"
          w="100%"
          borderRadius="lg"
          // p={{ base: 4, md: 6 }}
          bg="white"
          // borderWidth="2px"
          // borderColor="gray.300"
          // ml={{ base: 4, md: 8 }}
          // mr={{ base: 4, md: 8 }}
          mt={{ base: 4, md: 8 }}
          mb={{ base: 4, md: 8 }}
          overflowY="hidden"
        >
          {buttonSummaryLayout}
        </Box>
      ) : null}

      {viewConsolidatedSummary ? (
        <Box
          display={{ base: "block", md: "flex" }}
          justifyContent="space-between"
          w="100%"
          borderRadius="lg"
          // p={{ base: 4, md: 6 }}
          bg="white"
          // borderWidth="2px"
          // borderColor="gray.300"
          // ml={{ base: 4, md: 12 }}
          // mr={{ base: 4, md: 12 }}
          mt={{ base: 4, md: 12 }}
          mb={{ base: 4, md: 12 }}
          overflowY="hidden"
        >
          {buttonConsolidatedSummaryLayout}
        </Box>
      ) : null}

      {viewMap ? (
        <Box
          display={{ base: "block", md: "flex" }}
          justifyContent="space-between"
          w="100%"
          borderRadius="lg"
          // p={{ base: 4, md: 6 }}
          bg="white"
          // borderWidth="2px"
          // borderColor="gray.300"
          // ml={{ base: 4, md: 8 }}
          // mr={{ base: 4, md: 8 }}
          mt={{ base: 4, md: 8 }}
          mb={{ base: 4, md: 8 }}
          overflowY="hidden"
        >
          {buttonMapLayout}
        </Box>
      ) : null}

      {viewLoginActivity ? (
        <Box
          display={{ base: "block", md: "flex" }}
          justifyContent="space-between"
          w="100%"
          borderRadius="lg"
          // p={{ base: 4, md: 6 }}
          bg="white"
          // borderWidth="2px"
          // borderColor="gray.300"
          // ml={{ base: 4, md: 8 }}
          // mr={{ base: 4, md: 8 }}
          mt={{ base: 4, md: 8 }}
          mb={{ base: 4, md: 8 }}
          overflowY="hidden"
        >
          {buttonLoginActivityLayout}
        </Box>
      ) : null}
    </Box>
  );
};

export default AdminPanel;
