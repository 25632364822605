import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const MapContext = createContext();

const Provider = ({ children }) => {
  const [admin, setAdmin] = useState();
  const [viewHome, setViewHome] = useState(true);
  const [viewLoginActivity, setViewLoginActivity] = useState(false); 
  const [viewSummary, setViewSummary] = useState(false);
  const [viewMap, setViewMap] = useState(false);
  const [viewConsolidatedSummary, setViewConsolidatedSummary] = useState(false);

  const navigate = useNavigate();


  useEffect(() => {
    const adminInfo = JSON.parse(localStorage.getItem("adminInfo"));
    setAdmin(adminInfo);

    if (!adminInfo) {
      navigate("/admin");
    } else {
      const expirationDate = new Date(adminInfo.expirationDate);

      if (expirationDate < new Date()) {
        // Admin info has expired, delete from localStorage
        localStorage.removeItem("adminInfo");
        setAdmin(null);
        navigate("/admin");
      }
    }
  }, [navigate]);

  const setAdminInfo = (adminInfo) => {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 1); // 1 day from now
    adminInfo.expirationDate = expirationDate.toISOString();

    localStorage.setItem("adminInfo", JSON.stringify(adminInfo));
    setAdmin(adminInfo);
  };

  return (
    <MapContext.Provider
      value={{
        admin,
        setAdmin: setAdminInfo,
        viewHome,
        setViewHome,
        viewLoginActivity,
        setViewLoginActivity,
        viewSummary,
        setViewSummary,
        viewMap,
        setViewMap,
        viewConsolidatedSummary,
        setViewConsolidatedSummary,
      }}
    >
      {children}
    </MapContext.Provider>
  );
};

export const State = () => {
  return useContext(MapContext);
};

export default Provider;
