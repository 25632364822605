import {
    Box,
    Container,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
  } from "@chakra-ui/react";
  import { useEffect } from "react";
  import { useNavigate } from "react-router-dom"; // Import useNavigate instead of useHistory
  import Login from "./login";
  
  function Homepage() {
    const navigate = useNavigate(); // Use useNavigate instead of useHistory
    useEffect(() => {
      document.title = "Admin · Marwadi International Federation"; 
    }, []);
    useEffect(() => {
      const admin = JSON.parse(localStorage.getItem("adminInfo"));
  
      if (admin) navigate("/admin/dashboard",{replace:true}); // Use navigate instead of history.push
    }, [navigate]);
  
    return (
      <Container w="100%" maxW="xl" centerContent mb={14}>
        <Box
          display="flex"
          justifyContent="center"
          p={3}
          bg="white"
          w="100%"
          m="40px 0 15px 0"
      
        >

        </Box>
        <Box bg="white" w="100%" p={4} borderRadius="lg" borderWidth="1px">
          <Tabs isFitted variant="soft-rounded">
            <TabList mb="1em">
             
            </TabList>
            <TabPanels>
              <TabPanel>
                <Login />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Container>
    );
  }
  
  export default Homepage;
  