import { Route, Routes } from "react-router-dom";
import "./App.css";
import UpdateForm from "./updateForm";
import RegistrationForm from "./registerForm";
import AdminPanel from "./adminPanel";
import Navbar from "./header";
import NewNavbar from "./NewNavbar";
import Footer from "./footer";
import NewFooter from "./NewFooter"
import { Box } from "@chakra-ui/layout";
import Homepage from "./homepage";
import NavbarLogin from "./headerLogin";
import UploadImageForm from "./uploadImage";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route
          path="/admin"
          element={
            <div id="root">
              <div className="content">
              <NewNavbar />
                <Homepage />
              </div>
              <footer>
                <NewFooter />
              </footer>
            </div>
          }
          exact
        />
        <Route
          path="/admin/dashboard"
          element={
            <div id="root">
              <div className="content">
                <NewNavbar />
                <AdminPanel />
              </div>
              <footer>
                <NewFooter />
              </footer>
            </div>
          }
          exact
        />

        <Route
          path="/admin/dashboard/update/:mobile_number"
          element={
            <div>
              <NewNavbar />
              <UpdateForm />
              <NewFooter />
            </div>
          }
          exact
        />
        <Route
          path="/register"
          element={
            <div>
              <NewNavbar />
              <RegistrationForm />
              <NewFooter />
            </div>
          }
          exact
        />
        <Route
          path="/admin/dashboard/image/upload/:mobile_number"
          element={
            <div>
              <NewNavbar />
              <UploadImageForm />
              <NewFooter />
            </div>
          }
          exact
        />
      </Routes>
    </div>
  );
}

export default App;
