import React from "react";
import {
  Box,
  VStack,
  HStack,
  Text,
  Icon,
  useBreakpointValue,
} from "@chakra-ui/react";
import { FiPhone, FiMail, FiMapPin } from "react-icons/fi";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const handleRedirectNetwork = () => {
    window.location.href = `/india-network`;
  };
  return (
    <Box
      as="footer"
      bg="#5d5d5d"
      color="white"
      w="100%"
      height="fit-content"
      overflow="hidden"
    >
      {isMobile ? (
        <>
          <VStack align="stretch" spacing={8} mb={8} px={4}>
            <Box mb={1} alignSelf="center">
              <img
                src="https://bin-cdn-krn.s3.us-east-2.amazonaws.com/original/7b986f75-86ae-428d-940b-4a7f55a8a551.png"
                h={120}
                width={300}
                alt="Company Logo"
              />
              <HStack justify="center" mt={2}>
                <Text align="center">Follow us on : </Text>
                <HStack gap={2}>
                  <a href="https://www.youtube.com/@marwadiinternationalfedration">
                    <i class="fa fa-brands fa-youtube" />
                  </a>
                  <a href="https://www.google.com/maps/place/Marwadi+International+Federation/@21.0686228,82.7525294,5z/data=!3m1!4b1!4m6!3m5!1s0x8c7e2b8b1e027e5:0x25baec3c20b074d8!8m2!3d21.0686228!4d82.7525294!16s%2Fg%2F11tt2jns_2?entry=ttu">
                    <i class="fa fa-brands fa-google" />
                  </a>
                </HStack>
              </HStack>
            </Box>

            <VStack align="center" spacing={4} w="100%">
              <Text fontSize="lg" fontWeight="800" mb={4}>
                Quick Links
              </Text>
              <VStack spacing={2} align="center" w="100%">
                <a href="https://marwadiinternationalfederation.com/about/">
                  <ChevronRightIcon />
                  About
                </a>
                <a href="https://marwadiinternationalfederation.com/press/">
                  <ChevronRightIcon />
                  Press
                </a>
                <a onClick={handleRedirectNetwork}>
                  <ChevronRightIcon />
                  Network
                </a>
                <a href="https://marwadiinternationalfederation.com/gallery/">
                  <ChevronRightIcon />
                  Gallery
                </a>
                <a href="http://marwadiinternationalfederation.com/list-of-members/">
                  <ChevronRightIcon />
                  Member
                </a>
                <a href="https://marwadiinternationalfederation.com/book-marwadi-vyapari/">
                  <ChevronRightIcon />
                  Publication
                </a>
              </VStack>
            </VStack>
            <Box w="100%" alignContent="center" justifyItems="center">
              <Text fontSize="lg" fontWeight="800" mb={6} align="center">
                Contact Us
              </Text>
              <VStack align="center" spacing={2}>
                <HStack>
                  <Icon as={FiMail} mr={2} />
                  <a href="mailto:marwadiif@gmail.com">
                    Email : marwadiif@gmail.com
                  </a>
                </HStack>
                <HStack>
                  <Icon as={FiPhone} mr={2} />
                  <a href="tel:+91 9314503871">Phone : +91 9314503871</a>
                </HStack>

                <HStack w="80%">
                  <Icon as={FiMapPin} ml={6} mr={2} />
                  <Text>
                    Address : ABHYAM, C-121 A, Lal Kothi Opp Jyoti Nagar Thana,
                    near Rajasthan Vidhan Sabha, Jaipur, Rajasthan 302015
                  </Text>
                </HStack>
              </VStack>
            </Box>
          </VStack>
          <Text fontSize="lg" textAlign="center" bgColor="#212529">
            &copy; {new Date().getFullYear()} Marwadi International Federation.
          </Text>
        </>
      ) : (
        <>
          <HStack
            align="stretch"
            display="flex"
            justify="space-around"
            mb={10}
            mt={4}
          >
            <VStack align="flex-start" spacing={6} mb={6}>
              <Box mb={4}>
                <img
                  src="https://bin-cdn-krn.s3.us-east-2.amazonaws.com/original/7b986f75-86ae-428d-940b-4a7f55a8a551.png"
                  h={120}
                  width={300}
                  alt="Company Logo"
                />
                <HStack justify="center" mt={2}>
                  <Text align="center">Follow us on : </Text>
                  <HStack gap={2}>
                    <a href="https://www.youtube.com/@marwadiinternationalfedration">
                      <i class="fa fa-brands fa-youtube" />
                    </a>
                    <a href="https://www.google.com/maps/place/Marwadi+International+Federation/@21.0686228,82.7525294,5z/data=!3m1!4b1!4m6!3m5!1s0x8c7e2b8b1e027e5:0x25baec3c20b074d8!8m2!3d21.0686228!4d82.7525294!16s%2Fg%2F11tt2jns_2?entry=ttu">
                      <i class="fa fa-brands fa-google" />
                    </a>
                  </HStack>
                </HStack>
              </Box>
            </VStack>
            <Box
              alignContent="center"
              justifyContent="center"
              alignItems="center"
            >
              <Text fontSize="lg" fontWeight="800" mb={4}>
                Quick Links
              </Text>
              <VStack spacing={2} align="flex-start">
                <a href="https://marwadiinternationalfederation.com/about/">
                  <ChevronRightIcon />
                  About
                </a>
                <a href="https://marwadiinternationalfederation.com/press/">
                  <ChevronRightIcon />
                  Press
                </a>
                <a onClick={handleRedirectNetwork}>
                  <ChevronRightIcon />
                  Network
                </a>
                <a href="https://marwadiinternationalfederation.com/gallery/">
                  <ChevronRightIcon />
                  Gallery
                </a>
                <a href="http://marwadiinternationalfederation.com/list-of-members/">
                  <ChevronRightIcon />
                  Member
                </a>
                <a href="https://marwadiinternationalfederation.com/book-marwadi-vyapari/">
                  <ChevronRightIcon />
                  Publication
                </a>
              </VStack>
            </Box>
            <Box w="25.33%">
              <Text fontSize="lg" fontWeight="800" mb={6}>
                Contact Us
              </Text>
              <VStack align="flex-start" spacing={2}>
                <HStack>
                  <Icon as={FiMail} mr={2} />
                  <a href="mailto:marwadiif@gmail.com">
                    Email : marwadiif@gmail.com
                  </a>
                </HStack>
                <HStack>
                  <Icon as={FiPhone} mr={2} />
                  <a href="tel:+91 9314503871">Phone : +91 9314503871</a>
                </HStack>

                <HStack>
                  <Icon as={FiMapPin} mr={2} />
                  <Text>
                    Address : ABHYAM, C-121 A, Lal Kothi Opp Jyoti Nagar Thana,
                    near Rajasthan Vidhan Sabha, Jaipur, Rajasthan 302015
                  </Text>
                </HStack>
              </VStack>
            </Box>
          </HStack>
          <Text fontSize="lg" textAlign="center" bgColor="#212529" p={2}>
            Copyright &copy; {new Date().getFullYear()} Marwadi International
            Federation.
          </Text>
        </>
      )}
    </Box>
  );
};

export default Footer;
