export default  {
    Ajmer: [
      "Ajmer",
      "Arai",
      "Bhinai",
      "Bijainagar",
      "Kishangarh",
      "Masuda",
      "Nasirabad",
      "Pisangan",
      "Pushkar",
      "Roopangarh",
      "Sanver",
      "Sarwar",
      "Tantoti",
      "Tatgarh",
    ],
    Alwar: [
      "Alwar",
      "Bansur",
      "Govindgarh",
      "Kathumar",
      "Kishangarh Bas",
      "Kotkasim",
      "Laxmangarh",
      "Mala Khera",
      "Mundawar",
      "Narayanpur",
      "Neemrana",
      "Rajgarh",
      "Ramgarh",
      "Reni",
      "Tapukara",
      "Tehla",
      "Thanagazi",
    ],
    Anupgarh: [],
    Balotra: [],

    Banswara: ["Bagidora", "Banswara", "Garhi", "Ghatol"],
    Baran: ["Antah", "Atru", "Baran", "Chhabra", "Chhipabarod"],
    Barmer: [
      "Barmer",
      "Baytoo",
      "Chohtan",
      "Gudamalani",
      "Pachpadra",
      "Ramsar",
      "Sheo",
      "Siwana",
    ],
    Beawar: [],
    Bharatpur: [
      "Bayana",
      "Bharatpur",
      "Bhusawar",
      "Kaman",
      "Kumher",
      "Nadbai",
      "Nagar",
      "Pahari",
      "Rupbas",
      "Sikri",
      "Uchchain",
      "Weir",
    ],
    Bhilwara: [
      "Asind",
      "Badnor",
      "Banera",
      "Bhilwara",
      "Bijolia",
      "Hamirgarh",
      "Hurda",
      "Jahajpur",
      "Karera",
      "Kotri",
      "Mandal",
      "Mandalgarh",
      "Phooliya Kala",
      "Raipur",
      "Sahara",
    ],
    Bikaner: [
      "Bajju",
      "Bikaner",
      "Chhatargarh",
      "Dungargarh",
      "Khajuwala",
      "Kolayat",
      "Lunkaransar",
      "Nokha",
      "Poogal",
    ],
    Bundi: ["Bundi", "Hindaun", "Indragarh", "Kapren", "Nainwa"],
    Chittorgarh: [
      "Bari Sadri",
      "Begun",
      "Chittorgarh",
      "Dungla",
      "Kapasan",
      "Nimbahera",
      "Pratapgarh",
      "Rashmi",
      "Rawatbhata",
    ],
    Churu: [
      "Bidasar",
      "Churu",
      "Rajgarh",
      "Ratangarh",
      "Sardarshahar",
      "Siddhmukh",
      "Sujangarh",
      "Taranagar",
    ],
    Dausa: ["Bandikui", "Dausa", "Lalsot", "Mahwa"],
    Dheeg: [],
    Dholpur: ["Bari", "Dholpur", "Rajakhera", "Saramathura"],
    "Didwana-Kuchaman": [],
    Dudu: [],
    Dungarpur: [
      "Aspur",
      "Bichhiwara",
      "Chikhali",
      "Dowra",
      "Dungarpur",
      "Galiyakot",
      "Gamri Ahara",
      "Jhonthari",
      "Sabla",
      "Sagwara",
      "Simalwara",
    ],
    "Gangapur City": [],
    Hanumangarh: [
      "Bhadra",
      "Hanumangarh",
      "Nohar",
      "Pilibanga",
      "Rawatsar",
      "Sangria",
      "Tibbi",
    ],
    Jaipur: [
      "Amber",
      "Andhi",
      "Bassi",
      "Chaksu",
      "Chomu",
      "Jaipur",
      "Jamwa Ramgarh",
      "Jobner",
      "Kishangarh Renwal",
      "Kotkhawda",
      "Kotputli",
      "Madhorajpura",
      "Mauzmabad",
      "Pawta",
      "Phagi",
      "Phulera",
      "Sanganer",
      "Shahpura",
      "Toonga",
      "Viratnagar",
    ],
    "Jaipur (Rural)": [],

    Jaisalmer: ["Fatehgarh", "Jaisalmer", "Pokaran", "Sam"],
    Jalore: [
      "Ahore",
      "Bhinmal",
      "Jalore",
      "Jaswantpura",
      "Raniwara",
      "Sayla",
      "Siana",
    ],
    Jhalawar: [
      "Aklera",
      "Bakani",
      "Jhalarapatan",
      "Jhalawar",
      "Khanpur",
      "Manoharthana",
      "Pirawa",
    ],
    Jhunjhunu: [
      "Buhana",
      "Chirawa",
      "Jhunjhunu",
      "Khetri",
      "Malsisar",
      "Nawalgarh",
      "Pilani",
      "Surajgarh",
      "Udaipurwati",
    ],
    Jodhpur: ["Bhopalgarh", "Bilara", "Jodhpur", "Luni", "Osian", "Shergarh"],
    "Jodhpur (Rural)": [],
    Karauli: [
      "Karauli",
      "Hindaun",
      "Masalpur",
      "Mandrayal",
      "Nadoti",
      "Sapotra",
      "Suroth",
      "Shri Mahaveer Ji",
      "Todabhim",
    ],
    Kekri: [],
    "Khairthal-Tijara": [],
    Kota: [
      "Digod",
      "Itawa",
      "Kota",
      "Kaithoon",
      "Pipalda",
      "Ramganj Mandi",
      "Sangod",
    ],
    "Kotputli-Bahrod": [],
    Nagaur: [
      "Degana",
      "Jayal",
      "Khinvsar",
      "Ladnu",
      "Makrana",
      "Merta City",
      "Mundwa",
      "Nagaur",
      "Nawa",
      "Parbatsar",
      "Riyan Bari",
      "Sanjoo",
    ],
    "Neem Ka Thana": [],
    Pali: [
      "Bali",
      "Desuri",
      "Jaitaran",
      "Marwar Junction",
      "Pali",
      "Rohat",
      "Sojat",
      "Sumerpur",
    ],
    Phalodi: [],
    Pratapgarh: ["Arnod", "Chhoti Sadri", "Dhariawad", "Pratapgarh"],
    Rajsamand: [
      "Amet",
      "Bhim",
      "Deogarh",
      "Kumbhalgarh",
      "Nathdwara",
      "Rajsamand",
    ],
    Salumbar: [],
    Sanchore: [],
    "Sawai Madhopur": [
      "Bonli",
      "Bamanwas",
      "Chauth Ka Barawara",
      "Khandar",
      "Malarna Dungar",
      "Mitrpura",
      "Sawai Madhopur",
      "Wazirpur",
    ],
    Shahpura: [],
    Sikar: [
      "Danta Ramgarh",
      "Fatehpur",
      "Lachhmangarh",
      "Sikar",
      "Sri Madhopur",
    ],
    Sirohi: ["Abu Road", "Mount Abu", "Pindwara", "Sirohi"],
    "Sri Ganganagar": [
      "Gajsinghpur",
      "Karani",
      "Padampur",
      "Raisinghnagar",
      "Rawla",
      "Sadulshahar",
      "Shri Karanpur",
      "Sri Ganganagar",
      "Suratgarh",
      "Vijaynagar",
    ],
    Tonk: [
      "Deoli",
      "Duni",
      "Malpura",
      "Niwai",
      "Peeplu",
      "Todaraisingh",
      "Tonk",
      "Uniara",
    ],
    Udaipur: [
      "Girwa",
      "Gogunda",
      "Jhadol",
      "Kherwara",
      "Kotra",
      "Mavli",
      "Udaipur",
    ],
  };