import React, { useEffect, useRef, useState } from "react";
import "./App.css";
import {
  Box,
  VStack,
  HStack,
  Text,
  Select,
  Input,
  Button,
  useBreakpointValue,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  useToast,
  FormControl,
  FormLabel,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Stack,
  ModalFooter,
  Icon,
  RadioGroup,
  Radio,
  Tooltip,
  Link,
} from "@chakra-ui/react";
import { ExternalLinkIcon, SearchIcon } from "@chakra-ui/icons";
import { Image, Pagination, Popover } from "antd";
import axios from "axios";
import { Link as ReachLink, useNavigate } from "react-router-dom";
import { FiPhone, FiMail, FiMapPin } from "react-icons/fi";

import { parsePhoneNumberFromString } from "libphonenumber-js";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";


const AdvanceSearch = () => {
  // const { admin } = State();
  const [viewMoreEmail, setViewMoreEmail] = useState();
  const [selectedMap, setSelectedMap] = useState();
  const [selectedCountry, setSelectedCountry] = useState("");
  const [viewMore, setViewMore] = useState(false);
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedTehsil, setSelectedTehsil] = useState("");
  const [name, setName] = useState("");
  const [visible, setVisible] = useState(false);
  const [viewCurrentLocation, setViewCurrentLocation] = useState(false);
  const [viewNativePlace, setViewNativePlace] = useState(false);
  const [imageDisplay, setImageDisplay] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [data, setData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const [nativeLoading, setNativeLoading] = useState(false);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [tehsils, setTehsils] = useState([]);
  const [consolidatedSearch, setConsolidatedSearch] = useState();
  const [countries, setCountries] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [formData, setFormData] = useState([]);
  const buttonWidth = useBreakpointValue({ base: "70%", md: "auto" });

  const [visibleModal, setVisibleModal] = useState(false);
  // const [selectedItem, setSelectedItem] = useState(null);

  const [clickedSearch, setClickedSearch] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const mobileNumberRef = useRef(null);
  const [isMobileNumberFocused, setMobileNumberFocused] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        mobileNumberRef.current &&
        !mobileNumberRef.current.contains(event.target)
      ) {
        setMobileNumberFocused(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCloseModal = () => {
    setVisibleModal(false);
  };

  const toast = useToast();

  const navigate = useNavigate();

  useEffect(() => {
    fetchCountries();
  }, []);

  useEffect(() => {
    document.title = "Find Marwadi"; // Change the title here
  }, []);

  useEffect(() => {
    if (viewCurrentLocation) {
      if (selectedMap === "India") {
        fetchDataByNameOrProfessionIndia();
      } else {
        fetchDataByNameOrProfessionAbroad();
      }
    } else {
      fetchDataByNameOrProfessionNative();
    }
  }, [consolidatedSearch]);

  const fetchCountries = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/countries`
      );
      setCountries(response.data);
    } catch (error) {
      console.error("Error occurred while fetching countries:", error);
    }
  };

  const fetchDataIndia = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/advancesearch?city=${selectedCity}&state=${selectedState}&name=${consolidatedSearch}&ctype=India`
      );
      setData(response.data);
      setLoading(true);
      setNativeLoading(false);
    } catch (error) {
      console.error(error);
      // toast({
      //   title: "Error",
      //   description: "Failed to fetch data from API.",
      //   status: "error",
      //   duration: 3000,
      //   isClosable: true,
      // });
    }
  };

  const fetchDataAbroad = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/advancesearch?country=${selectedCountry}&name=${consolidatedSearch}&ctype=Abroad`
      );
      setData(response.data);
      setLoading(true);
      setNativeLoading(false);
    } catch (error) {
      console.error(error);
      // toast({
      //   title: "Error",
      //   description: "Failed to fetch data from API.",
      //   status: "error",
      //   duration: 3000,
      //   isClosable: true,
      // });
    }
  };

  const fetchDataNativePlace = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/advancesearch?native_district=${selectedDistrict}&native_tehsil=${selectedTehsil}`
      );
      setData(response.data);
      setNativeLoading(true);
      setLoading(false);
    } catch (error) {
      console.error(error);
      // toast({
      //   title: "Error",
      //   description: "Failed to fetch data from API.",
      //   status: "error",
      //   duration: 3000,
      //   isClosable: true,
      // });
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitted(true);
    setLoading(true);

    if (!name || !phone) {
      toast({
        title: "Form Submission Failed!",
        description: "Name and phone are required fields.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
      setLoading(false);
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email && !emailRegex.test(email)) {
      toast({
        title: "Form Submission Failed!",
        description: "Please enter a valid email address.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
      setLoading(false);
      return;
    }

    const phoneRegex = /^\+?[0-9\s\-()]{7,15}$/;
    if (!phoneRegex.test(phone)) {
      toast({
        title: "Form Submission Failed!",
        description: "Please enter a valid phone number. It should be between 7 and 15 characters long and may include a '+' sign.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
      setLoading(false);
      return;
    }


    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/submit-form`,
        {
          name,
          email,
          phone
        }
      );
      toast({
        title: "Form Submitted Successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
      setIsSubmitted(false);
      setLoading(false);
      setModalVisible(false);
      const entry = {
        timestamp: Date.now(),
        name: name,
        email: email,
        phone: phone,
      };

      // Add the entry to the form data array
      setFormData([...formData, entry]);

      if (clickedSearch === 'native') {
        setViewNativePlace(true);
        setViewCurrentLocation(false);
        setLoading(false);
        setConsolidatedSearch("");
      } else if (clickedSearch === 'current') {
        setViewCurrentLocation(true);
        setViewNativePlace(false);
        setNativeLoading(false);
        setConsolidatedSearch("");
      }



    } catch (error) {
      const statusCode = error.response?.status;
      const errorMessage = error.response?.data?.message || "Form Submission Failed!";

      if (statusCode === 400) {
        toast({
          title: "Join Us First!",
          description: errorMessage,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "bottom",
        });
        setLoading(false);
        // Keep the modal open so the user can join
      } else {
        toast({
          title: "Form Submission Failed!",
          description: errorMessage,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "bottom",
        });
        setModalVisible(false); // Close the modal for other errors
        setLoading(false);
      }
    }
  };

  const clearFormData = () => {
    setFormData([]);
    localStorage.removeItem("formData");
  };

  useEffect(() => {
    const storedFormData = localStorage.getItem("formData");

    if (storedFormData) {
      setFormData(JSON.parse(storedFormData));
    }

    // Clean up the form data on component unmount
    return () => {
      clearFormData();
    };
  }, []);

  useEffect(() => {
    if (formData.length > 0) {
      localStorage.setItem("formData", JSON.stringify(formData));
    }
  }, [formData]);

  useEffect(() => {
    const lastEntry = formData[formData.length - 1];
    if (lastEntry) {
      const elapsedTime = Date.now() - lastEntry.timestamp;
      const remainingTime = 3600000 - elapsedTime; // 1 hour - elapsed time in milliseconds

      if (remainingTime > 0) {
        const timeout = setTimeout(clearFormData, remainingTime);
        return () => clearTimeout(timeout);
      } else {
        clearFormData();
      }
    }
  }, [formData]);

  const fetchDataByNameOrProfessionAbroad = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/advancesearch?name=${consolidatedSearch}&ctype=${selectedMap}&country=${selectedCountry}`
      );
      setData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchDataByNameOrProfessionIndia = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/advancesearch?name=${consolidatedSearch}&ctype=${selectedMap}&state=${selectedState}&city=${selectedCity}`
      );
      setData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchDataByNameOrProfessionNative = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/advancesearch?name=${consolidatedSearch}&native_district=${selectedDistrict}&native_tehsil=${selectedTehsil}`
      );
      setData(response.data);
    } catch (error) {
      console.error(error);
    }
  };



  useEffect(() => {
    fetchStates();
  }, []);

  useEffect(() => {
    fetchDistricts();
  }, []);

  useEffect(() => {
    fetchTehsils();
  }, [selectedDistrict]);

  const fetchStates = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/states`
      );
      setStates(response.data);
    } catch (error) {
      console.error("Error occurred while fetching states:", error);
    }
  };

  useEffect(() => {
    fetchCities();
  }, [selectedState]);

  const fetchCities = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/cities?state=${selectedState}`
      );
      setCities(response.data);
    } catch (error) {
      console.error("Error occurred while fetching cities:", error);
    }
  };

  const fetchDistricts = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/districts`
      );
      setDistricts(response.data);
    } catch (error) {
      console.error("Error occurred while fetching ditricts:", error);
    }
  };

  const fetchTehsils = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/tehsils?native_district=${selectedDistrict}`
      );
      setTehsils(response.data);
    } catch (error) {
      console.error("Error occurred while fetching tehsils:", error);
    }
  };

  useEffect(() => {
    updatePagination();
  }, [data, pageSize]);

  const updatePagination = () => {
    const totalPageCount = Math.ceil(data.length / pageSize);
    setTotalItems(data.length);
    setTotalPages(totalPageCount);
    setCurrentPage(1);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handlePageSizeChange = (event) => {
    const newPageSize = Number(event.target.value);
    const newTotalPages = Math.ceil(totalItems / newPageSize);
    setCurrentPage(1);
    setPageSize(newPageSize);
    setTotalPages(newTotalPages);
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, totalItems);
  const paginatedData = data.slice(startIndex, endIndex);

  const pageSizeOptions = [5, 10, 25, 50, 100];

  const buttonCurrentLocationLayout = useBreakpointValue({
    base: (
      <VStack w="100%" align="stretch" flexDir="column" alignItems="center">
        <FormControl id="map" w="100%">
          <HStack
            spacing="24px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexWrap="wrap"
            mt={4}
          >
            <FormLabel fontWeight="extrabold" fontFamily="Montserrat" mt={2}>
              Select a option to search :
            </FormLabel>
            <RadioGroup
              value={selectedMap}
              onChange={(value) => setSelectedMap(value)}
              onClick={() => {
                setLoading(false);
                setNativeLoading(false);
              }}
            >
              <HStack spacing="24px">
                <Radio value="India" size="lg">
                  India
                </Radio>
                <Radio value="Abroad" size="lg">
                  Abroad
                </Radio>
              </HStack>
            </RadioGroup>
          </HStack>
        </FormControl>
        {selectedMap === "Abroad" && (
          <>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="center"
              w="100%"
              p={4}
              gap="20px"
              borderWidth="1.2px"
              borderRadius="12px"
              borderColor="gray.500"
            >
              <Text pr={5} textAlign="center">
                <b>Search in Abroad via Current Location</b>
              </Text>
              <HStack w="100%" spacing="40px">
                <Select
                  variant="flushed"
                  placeholder="Select a country"
                  value={selectedCountry}
                  onChange={(e) => setSelectedCountry(e.target.value)}
                >
                  {countries.map((country, index) => (
                    <option key={index} value={country}>
                      {country}
                    </option>
                  ))}
                </Select>

                <Button onClick={fetchDataAbroad} colorScheme="blue">
                  <SearchIcon />
                </Button>
              </HStack>
            </Box>
          </>
        )}
        {selectedMap === "India" && (
          <>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="center"
              marginTop="24px"
              w="100%"
              p={4}
              gap="40px"
              borderWidth="1.2px"
              borderRadius="12px"
              borderColor="gray.500"
            >
              <Text pr={5} textAlign="center">
                <b>Search in India via Current Location</b>
              </Text>
              <Select
                variant="flushed"
                value={selectedState}
                placeholder="Select State"
                onChange={(e) => setSelectedState(e.target.value)}
                borderColor="gray.400"
              >
                {states.map((state) => (
                  <option key={state} value={state}>
                    {state}
                  </option>
                ))}
              </Select>
              <HStack w="100%" spacing="40px">
                <Select
                  variant="flushed"
                  value={selectedCity}
                  placeholder="Select City"
                  onChange={(e) => setSelectedCity(e.target.value)}
                  borderColor="gray.400"
                >
                  {cities.map((city) => (
                    <option key={city} value={city}>
                      {city}
                    </option>
                  ))}
                </Select>

                <Button onClick={fetchDataIndia} colorScheme="blue">
                  <SearchIcon />
                </Button>
              </HStack>
            </Box>
          </>
        )}

        {loading && (
          <>

            <Input
              placeholder="Search By: Name or Profession"
              mt={4}
              value={consolidatedSearch}
              onChange={(e) => setConsolidatedSearch(e.target.value)}
              w="100%"
            />

            <Box
              borderWidth={1}
              borderColor="gray.500"
              overflowX="auto"
              maxW="100%"
              maxH="600px"
              overflowY="auto"
              sx={{
                '&::-webkit-scrollbar': {
                  width: '12px',
                  backgroundColor: `rgba(0, 0, 0, 0.05)`,
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: `rgba(0, 0, 0, 0.05)`,
                },
              }}
            >
              <Table variant="simple">
                <Thead position="sticky" top={-1} >
                  <Tr>
                    <Th
                      userSelect="none"
                      pr={1}
                      bgColor="gray.100"
                      fontFamily="Poppins"
                      border="1px solid gray"
                    >
                      #
                    </Th>
                    <Th
                      userSelect="none"
                      pr={1}
                      bgColor="gray.100"
                      fontFamily="Poppins"
                      border="1px solid gray"
                    >
                      Name
                    </Th>

                    <Th
                      userSelect="none"
                      pr={1}
                      bgColor="gray.100"
                      fontFamily="Poppins"
                      border="1px solid gray"
                    >
                      Profession
                    </Th>
                    <Th
                      userSelect="none"
                      bgColor="gray.100"
                      fontFamily="Poppins"
                      border="1px solid gray"
                    >
                      Email (Click on the icon to mail)
                    </Th>
                    <Th
                      userSelect="none"
                      bgColor="gray.100"
                      fontFamily="Poppins"
                      border="1px solid gray"
                    >
                      Phone (Click on the icon to connect)
                    </Th>
                    <Th
                      userSelect="none"
                      pr={1}
                      bgColor="gray.100"
                      fontFamily="Poppins"
                      border="1px solid gray"
                    >
                      Current Address
                    </Th>
                    <Th
                      userSelect="none"
                      pr={1}
                      bgColor="gray.100"
                      fontFamily="Poppins"
                      border="1px solid gray"
                    >
                      View More
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {paginatedData.map((item, index) => (
                    <Tr key={item.id}>
                      <Td
                        userSelect="none"
                        textAlign="center"
                        pr={1}
                        pl={1}
                        border="1px solid gray"
                      >
                        {startIndex + index + 1}
                      </Td>
                      <Td
                        userSelect="none"
                        textAlign="center"
                        pr={1}
                        pl={1}
                        border="1px solid gray"
                      >
                        {item.full_name}
                      </Td>
                      <Td
                        userSelect="none"
                        textAlign="center"
                        pr={1}
                        pl={1}
                        border="1px solid gray"
                      >
                        {item.occupation}
                      </Td>
                      <Td
                        userSelect="none"
                        textAlign="center"
                        pr={1}
                        pl={1}
                        border="1px solid gray"
                      >
                        <a href={`mailto:${item.email}`}>
                          {" "}
                          <Icon as={FiMail} mr={2} />
                        </a>
                      </Td>
                      <Td
                        userSelect="none"
                        textAlign="center"
                        pr={1}
                        pl={1}
                        border="1px solid gray"
                      >
                        {/* <Tooltip
                          hasArrow
                          label={`${item.mobile_number}`}
                          bg="gray.300"
                          color="black"
                        >
                          <a href={`tel:${item.mobile_number}`}>
                            <Icon as={FiPhone} mr={2} />
                          </a>
                        </Tooltip> */}
                        <a href={`tel:${item.mobile_number}`}>
                          <Icon as={FiPhone} mr={2} />
                        </a>
                      </Td>
                      <Td
                        userSelect="none"
                        textAlign="center"
                        pr={1}
                        pl={1}
                        border="1px solid gray"
                      >
                        {item.address}, {item.city}, {item.state},{" "}
                        {item.country}
                      </Td>

                      <Td
                        userSelect="none"
                        textAlign="center"
                        pr={1}
                        pl={1}
                        border="1px solid gray"
                      >
                        <ExternalLinkIcon
                          cursor="pointer"
                          color="green"
                          onClick={() => {
                            setVisible(true);
                            setImageDisplay(item.imageUrl);
                            // console.log(imageDisplay)
                            setVisibleModal(true);
                            setViewMore(true);
                            setViewMoreEmail(item.email);
                          }}
                        />
                      </Td>
                      <Modal
                        isOpen={visibleModal && viewMoreEmail === item.email}
                        size="5xl"
                        onClose={handleCloseModal}
                        isCentered
                      >
                        <ModalOverlay
                          bg="rgba(0, 0, 0,0.9)"
                          backdropFilter="blur(8px)"
                          zIndex="modalOverlay"
                        />
                        <ModalContent>
                          <ModalHeader>Detail Information</ModalHeader>
                          <Box
                            overflowX="auto"
                            maxW="100%"

                          // borderRadius="12px"
                          >
                            <Table variant="simple">
                              <Thead position="sticky" top={-1} >
                                <Tr>
                                  <Th
                                    userSelect="none"
                                    pr={1}
                                    bgColor="gray.100"
                                    fontFamily="Poppins"
                                    border="1px solid gray"
                                  >
                                    #
                                  </Th>
                                  <Th
                                    userSelect="none"
                                    pr={1}
                                    bgColor="gray.100"
                                    fontFamily="Poppins"
                                    border="1px solid gray"
                                  >
                                    Name
                                  </Th>
                                  <Th
                                    userSelect="none"
                                    pr={1}
                                    bgColor="gray.100"
                                    fontFamily="Poppins"
                                    border="1px solid gray"
                                  >
                                    S/O ,D/O ,W/O NAME
                                  </Th>
                                  <Th
                                    userSelect="none"
                                    pr={1}
                                    bgColor="gray.100"
                                    fontFamily="Poppins"
                                    border="1px solid gray"
                                  >
                                    Profession
                                  </Th>
                                  <Th
                                    userSelect="none"
                                    bgColor="gray.100"
                                    fontFamily="Poppins"
                                    border="1px solid gray"
                                  >
                                    Email (Click on the icon to mail)
                                  </Th>
                                  <Th
                                    userSelect="none"
                                    bgColor="gray.100"
                                    fontFamily="Poppins"
                                    border="1px solid gray"
                                  >
                                    Phone (Click on the icon to connect)
                                  </Th>
                                  <Th
                                    userSelect="none"
                                    pr={1}
                                    bgColor="gray.100"
                                    fontFamily="Poppins"
                                    border="1px solid gray"
                                  >
                                    Current Address
                                  </Th>

                                  <Th
                                    userSelect="none"
                                    pr={1}
                                    bgColor="gray.100"
                                    fontFamily="Poppins"
                                    border="1px solid gray"
                                  >
                                    Native Place
                                  </Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                                <Tr key={item.id}>
                                  <Td
                                    userSelect="none"
                                    textAlign="center"
                                    pr={1}
                                    pl={1}
                                    border="1px solid gray"
                                  >
                                    {startIndex + index + 1}
                                  </Td>
                                  <Td
                                    userSelect="none"
                                    textAlign="center"
                                    pr={1}
                                    pl={1}
                                    border="1px solid gray"
                                  >
                                    {item.full_name}
                                  </Td>
                                  <Td
                                    userSelect="none"
                                    textAlign="center"
                                    pr={1}
                                    pl={1}
                                    border="1px solid gray"
                                  >
                                    {item.so_wo_do}
                                  </Td>
                                  <Td
                                    userSelect="none"
                                    textAlign="center"
                                    pr={1}
                                    pl={1}
                                    border="1px solid gray"
                                  >
                                    {item.occupation}
                                  </Td>
                                  <Td
                                    userSelect="none"
                                    textAlign="center"
                                    pr={1}
                                    pl={1}
                                    border="1px solid gray"
                                  >
                                    <a href={`mailto:${item.email}`}>
                                      <Icon as={FiMail} mr={2} />
                                    </a>
                                  </Td>
                                  <Td
                                    userSelect="none"
                                    textAlign="center"
                                    pr={1}
                                    pl={1}
                                    border="1px solid gray"
                                  >
                                    {/* <Tooltip
                                      hasArrow
                                      label={`${item.mobile_number}`}
                                      bg="gray.300"
                                      color="black"
                                    >
                                      <a href={`tel:${item.mobile_number}`}>
                                        <Icon as={FiPhone} mr={2} />
                                      </a>
                                    </Tooltip> */}
                                    <a href={`tel:${item.mobile_number}`}>
                                      <Icon as={FiPhone} mr={2} />
                                    </a>
                                  </Td>
                                  <Td
                                    userSelect="none"
                                    textAlign="center"
                                    pr={1}
                                    pl={1}
                                    border="1px solid gray"
                                  >
                                    {item.address}, {item.city}, {item.state},{" "}
                                    {item.country}
                                  </Td>
                                  <Td
                                    userSelect="none"
                                    textAlign="center"
                                    pr={1}
                                    pl={1}
                                    border="1px solid gray"
                                  >
                                    {item.native_district}, {item.native_tehsil}
                                    ,{item.native_village}
                                  </Td>
                                </Tr>
                              </Tbody>
                            </Table>
                          </Box>
                          <ModalFooter>
                            <Button
                              colorScheme="red"
                              mr={3}
                              onClick={handleCloseModal}
                            >
                              Close
                            </Button>
                          </ModalFooter>
                        </ModalContent>
                      </Modal>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>

            <HStack justify="flex-end" spacing={4}>
              <VStack w="100%" align="stretch">
                <Pagination
                  size="large"
                  current={currentPage}
                  total={totalItems}
                  pageSize={pageSize}
                  showSizeChanger={false}
                  onChange={handlePageChange}
                  showTotal={(total) => `Total ${total} items`}
                  showQuickJumper
                />
                <Flex alignItems="center">
                  <Select
                    value={pageSize}
                    onChange={handlePageSizeChange}
                    variant="outline"
                  >
                    {pageSizeOptions.map((option) => (
                      <option key={option} value={option}>
                        {option} / page
                      </option>
                    ))}
                  </Select>
                </Flex>
              </VStack>
            </HStack>
          </>
        )}
      </VStack>
    ),

    md: (
      <VStack w="100%" align="stretch" spacing={4} p={4}>
        <FormControl id="map" w="100%">
          <HStack
            spacing="24px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexWrap="wrap"
            mt={4}
          >
            <FormLabel fontWeight="extrabold" fontFamily="Montserrat" mt={2}>
              Select a option to search :
            </FormLabel>
            <RadioGroup
              value={selectedMap}
              onChange={(value) => setSelectedMap(value)}
              onClick={() => {
                setLoading(false);
                setNativeLoading(false);
              }}
            >
              <HStack spacing="24px">
                <Radio value="India" size="lg">
                  India
                </Radio>
                <Radio value="Abroad" size="lg">
                  Abroad
                </Radio>
              </HStack>
            </RadioGroup>
          </HStack>
        </FormControl>
        <HStack>
          {selectedMap === "India" && (
            <>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                w="100%"
                p={4}
                //  pb={8}
                gap="80px"
                borderWidth="1.2px"
                borderRadius="12px"
                borderColor="gray.300"
              >
                <VStack w="100%" align="stretch">
                  <Text pr={5} textAlign="left" fontFamily="Montserrat">
                    <b>Search in India via Current Location</b>
                  </Text>
                  <HStack
                    spacing={0}
                    gap={0}
                    borderWidth="1.2px"
                    borderRadius="12px"
                    borderColor="gray.500"
                  >
                    <Select
                      variant="unstyled"
                      value={selectedState}
                      placeholder="Select State"
                      onChange={(e) => setSelectedState(e.target.value)}
                      borderColor="gray.400"
                      w="52%"
                      fontFamily="Montserrat"
                      textAlign="center"
                      pl={1}
                    >
                      {states.map((state) => (
                        <option key={state} value={state}>
                          {state}
                        </option>
                      ))}
                    </Select>
                    <Select
                      variant="flushed"
                      value={selectedCity}
                      placeholder="Select City"
                      onChange={(e) => setSelectedCity(e.target.value)}
                      borderColor="gray.400"
                      w="52%"
                      borderBottomWidth={0}
                      borderLeftWidth="1.2px"
                      fontFamily="Montserrat"
                      textAlign="center"
                      focusBorderColor="none"
                      pl={1}
                    >
                      {cities.map((city) => (
                        <option key={city} value={city}>
                          {city}
                        </option>
                      ))}
                    </Select>

                    <Button
                      onClick={fetchDataIndia}
                      colorScheme="blue"
                      borderRadius={11}
                    >
                      <SearchIcon />
                    </Button>
                  </HStack>
                </VStack>
              </Box>
            </>
          )}

          {selectedMap === "Abroad" && (
            <>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                w="100%"
                p={4}
                // pb={8}
                gap="80px"
                borderWidth="1.2px"
                borderRadius="12px"
                borderColor="gray.300"
              >
                <VStack w="100%" align="stretch">
                  <Text pr={5} textAlign="left" fontFamily="Montserrat">
                    <b>Search in Abroad via Current Location</b>
                  </Text>
                  <HStack
                    spacing={0}
                    gap={0}
                    borderWidth="1.2px"
                    borderRadius="12px"
                    borderColor="gray.500"
                  >
                    <Select
                      variant="flushed"
                      placeholder="Select a country"
                      textAlign="center"
                      value={selectedCountry}
                      onChange={(e) => setSelectedCountry(e.target.value)}
                      fontFamily="Montserrat"
                      borderBottomWidth={0}
                      focusBorderColor="none"
                      pl={1}
                    >
                      {countries.map((country, index) => (
                        <option key={index} value={country}>
                          {country}
                        </option>
                      ))}
                    </Select>

                    <Button
                      onClick={fetchDataAbroad}
                      colorScheme="blue"
                      borderRadius={11}
                    >
                      <SearchIcon />
                    </Button>
                  </HStack>
                </VStack>
              </Box>
            </>
          )}
        </HStack>
        {loading && (
          <>
            <HStack justify="flex-end">
              <Input
                placeholder="Search By: Name or profession"
                value={consolidatedSearch}
                onChange={(e) => setConsolidatedSearch(e.target.value)}
                w="40%"
              />
            </HStack>
            <Box
              borderWidth={1}
              borderColor="gray.500"
              overflow="auto"
              maxW="100%"
              maxH="600px"
              sx={{
                '&::-webkit-scrollbar': {
                  width: '12px',
                  backgroundColor: `rgba(0, 0, 0, 0.05)`,
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: `rgba(0, 0, 0, 0.05)`,
                },
              }}
            >

              <Table variant="simple">
                <Thead position="sticky" top={-1} >
                  <Tr>
                    <Th
                      userSelect="none"
                      pr={1}
                      bgColor="gray.100"
                      fontFamily="Poppins"
                      border="1px solid gray"
                    >
                      #
                    </Th>
                    <Th
                      userSelect="none"
                      pr={1}
                      bgColor="gray.100"
                      fontFamily="Poppins"
                      border="1px solid gray"
                    >
                      Name
                    </Th>

                    <Th
                      userSelect="none"
                      pr={1}
                      bgColor="gray.100"
                      fontFamily="Poppins"
                      border="1px solid gray"
                    >
                      Profession
                    </Th>
                    <Th
                      userSelect="none"
                      bgColor="gray.100"
                      fontFamily="Poppins"
                      border="1px solid gray"
                    >
                      Email (Click on the icon to mail)
                    </Th>
                    <Th
                      userSelect="none"
                      bgColor="gray.100"
                      fontFamily="Poppins"
                      border="1px solid gray"
                    >
                      Phone (Click on the icon to connect)
                    </Th>
                    <Th
                      userSelect="none"
                      pr={1}
                      bgColor="gray.100"
                      fontFamily="Poppins"
                      border="1px solid gray"
                    >
                      Current Address
                    </Th>
                    <Th
                      userSelect="none"
                      pr={1}
                      bgColor="gray.100"
                      fontFamily="Poppins"
                      border="1px solid gray"
                    >
                      View More
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {paginatedData.map((item, index) => (
                    <Tr key={item.id}>
                      <Td
                        userSelect="none"
                        textAlign="center"
                        pr={1}
                        pl={1}
                        border="1px solid gray"
                      >
                        {startIndex + index + 1}
                      </Td>
                      <Td
                        userSelect="none"
                        textAlign="center"
                        pr={1}
                        pl={1}
                        border="1px solid gray"
                      >
                        {item.full_name}
                      </Td>
                      <Td
                        userSelect="none"
                        textAlign="center"
                        pr={1}
                        pl={1}
                        border="1px solid gray"
                      >
                        {item.occupation}
                      </Td>
                      <Td
                        userSelect="none"
                        textAlign="center"
                        pr={1}
                        pl={1}
                        border="1px solid gray"
                      >
                        <a href={`mailto:${item.email}`}>
                          {" "}
                          <Icon as={FiMail} mr={2} />
                        </a>
                      </Td>
                      <Td
                        userSelect="none"
                        textAlign="center"
                        pr={1}
                        pl={1}
                        border="1px solid gray"
                      >
                        {/* <Tooltip
                          hasArrow
                          label={`${item.mobile_number}`}
                          bg="gray.300"
                          color="black"
                        >
                          <a href={`tel:${item.mobile_number}`}>
                            <Icon as={FiPhone} mr={2} />
                          </a>
                        </Tooltip> */}
                        <a href={`tel:${item.mobile_number}`}>
                          <Icon as={FiPhone} mr={2} />
                        </a>
                      </Td>
                      <Td
                        userSelect="none"
                        textAlign="center"
                        pr={1}
                        pl={1}
                        border="1px solid gray"
                      >
                        {item.address}, {item.city}, {item.state},{" "}
                        {item.country}
                      </Td>

                      <Td
                        userSelect="none"
                        textAlign="center"
                        pr={1}
                        pl={1}
                        border="1px solid gray"
                      >
                        <ExternalLinkIcon
                          cursor="pointer"
                          color="green"
                          onClick={() => {
                            setVisible(true);
                            setImageDisplay(item.imageUrl);
                            // console.log(imageDisplay)
                            setVisibleModal(true);
                            setViewMore(true);
                            setViewMoreEmail(item.email);
                          }}
                        />
                      </Td>
                      <Modal
                        isOpen={visibleModal && viewMoreEmail === item.email}
                        size="5xl"
                        onClose={handleCloseModal}
                        isCentered
                      >
                        <ModalOverlay
                          bg="rgba(0, 0, 0,0.9)"
                          backdropFilter="blur(8px)"
                          zIndex="modalOverlay"
                        />
                        <ModalContent>
                          <ModalHeader>Detail Information</ModalHeader>
                          <Box
                            overflowX="auto"
                            maxW="100%"

                          // borderRadius="12px"
                          >
                            <Table variant="simple">
                              <Thead position="sticky" top={-1} >
                                <Tr>
                                  <Th
                                    userSelect="none"
                                    pr={1}
                                    bgColor="gray.100"
                                    fontFamily="Poppins"
                                    border="1px solid gray"
                                  >
                                    #
                                  </Th>
                                  <Th
                                    userSelect="none"
                                    pr={1}
                                    bgColor="gray.100"
                                    fontFamily="Poppins"
                                    border="1px solid gray"
                                  >
                                    Name
                                  </Th>
                                  <Th
                                    userSelect="none"
                                    pr={1}
                                    bgColor="gray.100"
                                    fontFamily="Poppins"
                                    border="1px solid gray"
                                  >
                                    S/O ,D/O ,W/O NAME
                                  </Th>
                                  <Th
                                    userSelect="none"
                                    pr={1}
                                    bgColor="gray.100"
                                    fontFamily="Poppins"
                                    border="1px solid gray"
                                  >
                                    Profession
                                  </Th>
                                  <Th
                                    userSelect="none"
                                    bgColor="gray.100"
                                    fontFamily="Poppins"
                                    border="1px solid gray"
                                  >
                                    Email (Click on the icon to mail)
                                  </Th>
                                  <Th
                                    userSelect="none"
                                    bgColor="gray.100"
                                    fontFamily="Poppins"
                                    border="1px solid gray"
                                  >
                                    Phone (Click on the icon to connect)
                                  </Th>
                                  <Th
                                    userSelect="none"
                                    pr={1}
                                    bgColor="gray.100"
                                    fontFamily="Poppins"
                                    border="1px solid gray"
                                  >
                                    Current Address
                                  </Th>

                                  <Th
                                    userSelect="none"
                                    pr={1}
                                    bgColor="gray.100"
                                    fontFamily="Poppins"
                                    border="1px solid gray"
                                  >
                                    Native Place
                                  </Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                                <Tr key={item.id}>
                                  <Td
                                    userSelect="none"
                                    textAlign="center"
                                    pr={1}
                                    pl={1}
                                    border="1px solid gray"
                                  >
                                    {startIndex + index + 1}
                                  </Td>
                                  <Td
                                    userSelect="none"
                                    textAlign="center"
                                    pr={1}
                                    pl={1}
                                    border="1px solid gray"
                                  >
                                    {item.full_name}
                                  </Td>
                                  <Td
                                    userSelect="none"
                                    textAlign="center"
                                    pr={1}
                                    pl={1}
                                    border="1px solid gray"
                                  >
                                    {item.so_wo_do}
                                  </Td>
                                  <Td
                                    userSelect="none"
                                    textAlign="center"
                                    pr={1}
                                    pl={1}
                                    border="1px solid gray"
                                  >
                                    {item.occupation}
                                  </Td>
                                  <Td
                                    userSelect="none"
                                    textAlign="center"
                                    pr={1}
                                    pl={1}
                                    border="1px solid gray"
                                  >
                                    <a href={`mailto:${item.email}`}>
                                      <Icon as={FiMail} mr={2} />
                                    </a>
                                  </Td>
                                  <Td
                                    userSelect="none"
                                    textAlign="center"
                                    pr={1}
                                    pl={1}
                                    border="1px solid gray"
                                  >
                                    {/* <Tooltip
                                      hasArrow
                                      label={`${item.mobile_number}`}
                                      bg="gray.300"
                                      color="black"
                                    >
                                      <a href={`tel:${item.mobile_number}`}>
                                        <Icon as={FiPhone} mr={2} />
                                      </a>
                                    </Tooltip> */}
                                    <a href={`tel:${item.mobile_number}`}>
                                      <Icon as={FiPhone} mr={2} />
                                    </a>
                                  </Td>
                                  <Td
                                    userSelect="none"
                                    textAlign="center"
                                    pr={1}
                                    pl={1}
                                    border="1px solid gray"
                                  >
                                    {item.address}, {item.city}, {item.state},{" "}
                                    {item.country}
                                  </Td>
                                  <Td
                                    userSelect="none"
                                    textAlign="center"
                                    pr={1}
                                    pl={1}
                                    border="1px solid gray"
                                  >
                                    {item.native_district}, {item.native_tehsil}
                                    ,{item.native_village}
                                  </Td>
                                </Tr>
                              </Tbody>
                            </Table>
                          </Box>
                          <ModalFooter>
                            <Button
                              colorScheme="red"
                              mr={3}
                              onClick={handleCloseModal}
                            >
                              Close
                            </Button>
                          </ModalFooter>
                        </ModalContent>
                      </Modal>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>

            <HStack justify="flex-end" spacing={4}>
              <Pagination
                size="large"
                current={currentPage}
                total={totalItems}
                pageSize={pageSize}
                showSizeChanger={false}
                onChange={handlePageChange}
                showTotal={(total) => `Total ${total} items`}
                showQuickJumper
              />
              <Flex alignItems="center">
                <Select
                  value={pageSize}
                  onChange={handlePageSizeChange}
                  variant="outline"
                >
                  {pageSizeOptions.map((option) => (
                    <option key={option} value={option}>
                      {option} / page
                    </option>
                  ))}
                </Select>
              </Flex>
            </HStack>
          </>
        )}
      </VStack>
    ),
  });

  const buttonNativePlaceLayout = useBreakpointValue({
    base: (
      <VStack w="100%" align="stretch" flexDir="column" alignItems="center">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
          marginTop="24px"
          w="100%"
          p={4}
          gap="40px"
          borderWidth="1.2px"
          borderRadius="12px"
          borderColor="gray.500"
        >
          <Text pr={5} textAlign="center">
            <b>Search via Native Location in Rajasthan</b>
          </Text>
          <Select
            variant="flushed"
            value={selectedDistrict}
            placeholder="Select District"
            onChange={(e) => setSelectedDistrict(e.target.value)}
            borderColor="gray.400"
          >
            {districts.map((district) => (
              <option key={district} value={district}>
                {district}
              </option>
            ))}
          </Select>
          <HStack w="100%" spacing="40px">
            <Select
              variant="flushed"
              value={selectedTehsil}
              placeholder="Select Tehsil"
              onChange={(e) => setSelectedTehsil(e.target.value)}
              borderColor="gray.400"
            >
              {tehsils.map((tehsil) => (
                <option key={tehsil} value={tehsil}>
                  {tehsil}
                </option>
              ))}
            </Select>

            <Button onClick={fetchDataNativePlace} colorScheme="blue">
              <SearchIcon />
            </Button>
          </HStack>
        </Box>
        {nativeLoading && (
          <>
            <Input
              placeholder="Search By: Name or profession"
              value={consolidatedSearch}
              onChange={(e) => setConsolidatedSearch(e.target.value)}
              mt={4}
              w="100%"
            />

            <Box
              borderWidth={1}
              borderColor="gray.500"
              overflowX="auto"
              maxW="100%"
              maxH="600px"
              overflowY="auto"
              sx={{
                '&::-webkit-scrollbar': {
                  width: '12px',
                  backgroundColor: `rgba(0, 0, 0, 0.05)`,
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: `rgba(0, 0, 0, 0.05)`,
                },
              }}
            >
              <Table variant="simple">
                <Thead position="sticky" top={-1} >
                  <Tr>
                    <Th
                      userSelect="none"
                      pr={1}
                      bgColor="gray.100"
                      fontFamily="Poppins"
                      border="1px solid gray"
                    >
                      #
                    </Th>
                    <Th
                      userSelect="none"
                      pr={1}
                      bgColor="gray.100"
                      fontFamily="Poppins"
                      border="1px solid gray"
                    >
                      Name
                    </Th>
                    <Th
                      userSelect="none"
                      pr={1}
                      bgColor="gray.100"
                      fontFamily="Poppins"
                      border="1px solid gray"
                    >
                      Profession
                    </Th>
                    <Th
                      userSelect="none"
                      bgColor="gray.100"
                      fontFamily="Poppins"
                      border="1px solid gray"
                    >
                      Email (Click on the icon to mail)
                    </Th>
                    <Th
                      userSelect="none"
                      bgColor="gray.100"
                      fontFamily="Poppins"
                      border="1px solid gray"
                    >
                      Phone (Click on the icon to connect)
                    </Th>
                    <Th
                      userSelect="none"
                      pr={1}
                      bgColor="gray.100"
                      fontFamily="Poppins"
                      border="1px solid gray"
                    >
                      Current Address
                    </Th>
                    <Th
                      userSelect="none"
                      pr={1}
                      bgColor="gray.100"
                      fontFamily="Poppins"
                      border="1px solid gray"
                    >
                      View More
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {paginatedData.map((item, index) => (
                    <Tr key={item.id}>
                      <Td
                        userSelect="none"
                        textAlign="center"
                        pr={1}
                        pl={1}
                        border="1px solid gray"
                      >
                        {startIndex + index + 1}
                      </Td>
                      <Td
                        userSelect="none"
                        textAlign="center"
                        pr={1}
                        pl={1}
                        border="1px solid gray"
                      >
                        {item.full_name}
                      </Td>
                      <Td
                        userSelect="none"
                        textAlign="center"
                        pr={1}
                        pl={1}
                        border="1px solid gray"
                      >
                        {item.occupation}
                      </Td>
                      <Td
                        userSelect="none"
                        textAlign="center"
                        pr={1}
                        pl={1}
                        border="1px solid gray"
                      >
                        <a href={`mailto:${item.email}`}>
                          {" "}
                          <Icon as={FiMail} mr={2} />
                        </a>
                      </Td>
                      <Td
                        userSelect="none"
                        textAlign="center"
                        pr={1}
                        pl={1}
                        border="1px solid gray"
                      >
                        {/* <Tooltip
                          hasArrow
                          label={`${item.mobile_number}`}
                          bg="gray.300"
                          color="black"
                        >
                          <a href={`tel:${item.mobile_number}`}>
                            <Icon as={FiPhone} mr={2} />
                          </a>
                        </Tooltip> */}
                        <a href={`tel:${item.mobile_number}`}>
                          <Icon as={FiPhone} mr={2} />
                        </a>
                      </Td>
                      <Td
                        userSelect="none"
                        textAlign="center"
                        pr={1}
                        pl={1}
                        border="1px solid gray"
                      >
                        {item.address}, {item.city}, {item.state},{" "}
                        {item.country}
                      </Td>
                      <Td
                        userSelect="none"
                        textAlign="center"
                        pr={1}
                        pl={1}
                        border="1px solid gray"
                      >
                        <ExternalLinkIcon
                          cursor="pointer"
                          color="green"
                          onClick={() => {
                            setVisible(true);
                            setImageDisplay(item.imageUrl);
                            // console.log(imageDisplay)
                            setVisibleModal(true);
                            setViewMore(true);
                            setViewMoreEmail(item.email);
                          }}
                        />
                      </Td>
                      <Modal
                        isOpen={visibleModal && viewMoreEmail === item.email}
                        size="5xl"
                        onClose={handleCloseModal}
                        isCentered
                      >
                        <ModalOverlay
                          bg="rgba(0, 0, 0,0.9)"
                          backdropFilter="blur(8px)"
                          zIndex="modalOverlay"
                        />
                        <ModalContent>
                          <ModalHeader>Detail Information</ModalHeader>
                          <Box
                            overflowX="auto"
                            maxW="100%"

                          // borderRadius="12px"
                          >
                            <Table variant="simple">
                              <Thead position="sticky" top={-1} >
                                <Tr>
                                  <Th
                                    userSelect="none"
                                    pr={1}
                                    bgColor="gray.100"
                                    fontFamily="Poppins"
                                    border="1px solid gray"
                                  >
                                    #
                                  </Th>
                                  <Th
                                    userSelect="none"
                                    pr={1}
                                    bgColor="gray.100"
                                    fontFamily="Poppins"
                                    border="1px solid gray"
                                  >
                                    Name
                                  </Th>
                                  <Th
                                    userSelect="none"
                                    pr={1}
                                    bgColor="gray.100"
                                    fontFamily="Poppins"
                                    border="1px solid gray"
                                  >
                                    S/O ,D/O ,W/O NAME
                                  </Th>
                                  <Th
                                    userSelect="none"
                                    pr={1}
                                    bgColor="gray.100"
                                    fontFamily="Poppins"
                                    border="1px solid gray"
                                  >
                                    Profession
                                  </Th>
                                  <Th
                                    userSelect="none"
                                    bgColor="gray.100"
                                    fontFamily="Poppins"
                                    border="1px solid gray"
                                  >
                                    Email (Click on the icon to mail)
                                  </Th>
                                  <Th
                                    userSelect="none"
                                    bgColor="gray.100"
                                    fontFamily="Poppins"
                                    border="1px solid gray"
                                  >
                                    Phone (Click on the icon to connect)
                                  </Th>
                                  <Th
                                    userSelect="none"
                                    pr={1}
                                    bgColor="gray.100"
                                    fontFamily="Poppins"
                                    border="1px solid gray"
                                  >
                                    Current Address
                                  </Th>

                                  <Th
                                    userSelect="none"
                                    pr={1}
                                    bgColor="gray.100"
                                    fontFamily="Poppins"
                                    border="1px solid gray"
                                  >
                                    Native Place
                                  </Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                                <Tr key={item.id}>
                                  <Td
                                    userSelect="none"
                                    textAlign="center"
                                    pr={1}
                                    pl={1}
                                    border="1px solid gray"
                                  >
                                    {startIndex + index + 1}
                                  </Td>
                                  <Td
                                    userSelect="none"
                                    textAlign="center"
                                    pr={1}
                                    pl={1}
                                    border="1px solid gray"
                                  >
                                    {item.full_name}
                                  </Td>
                                  <Td
                                    userSelect="none"
                                    textAlign="center"
                                    pr={1}
                                    pl={1}
                                    border="1px solid gray"
                                  >
                                    {item.so_wo_do}
                                  </Td>
                                  <Td
                                    userSelect="none"
                                    textAlign="center"
                                    pr={1}
                                    pl={1}
                                    border="1px solid gray"
                                  >
                                    {item.occupation}
                                  </Td>
                                  <Td
                                    userSelect="none"
                                    textAlign="center"
                                    pr={1}
                                    pl={1}
                                    border="1px solid gray"
                                  >
                                    <a href={`mailto:${item.email}`}>
                                      <Icon as={FiMail} mr={2} />
                                    </a>
                                  </Td>
                                  <Td
                                    userSelect="none"
                                    textAlign="center"
                                    pr={1}
                                    pl={1}
                                    border="1px solid gray"
                                  >
                                    {/* <Tooltip
                                      hasArrow
                                      label={`${item.mobile_number}`}
                                      bg="gray.300"
                                      color="black"
                                    >
                                      <a href={`tel:${item.mobile_number}`}>
                                        <Icon as={FiPhone} mr={2} />
                                      </a>
                                    </Tooltip> */}
                                    <a href={`tel:${item.mobile_number}`}>
                                      <Icon as={FiPhone} mr={2} />
                                    </a>
                                  </Td>
                                  <Td
                                    userSelect="none"
                                    textAlign="center"
                                    pr={1}
                                    pl={1}
                                    border="1px solid gray"
                                  >
                                    {item.address}, {item.city}, {item.state},{" "}
                                    {item.country}
                                  </Td>
                                  <Td
                                    userSelect="none"
                                    textAlign="center"
                                    pr={1}
                                    pl={1}
                                    border="1px solid gray"
                                  >
                                    {item.native_district}, {item.native_tehsil}
                                    ,{item.native_village}
                                  </Td>
                                </Tr>
                              </Tbody>
                            </Table>
                          </Box>
                          <ModalFooter>
                            <Button
                              colorScheme="red"
                              mr={3}
                              onClick={handleCloseModal}
                            >
                              Close
                            </Button>
                          </ModalFooter>
                        </ModalContent>
                      </Modal>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>

            <HStack justify="flex-end" spacing={4}>
              <VStack w="100%" align="stretch">
                <Pagination
                  size="large"
                  current={currentPage}
                  total={totalItems}
                  pageSize={pageSize}
                  showSizeChanger={false}
                  onChange={handlePageChange}
                  showTotal={(total) => `Total ${total} items`}
                  showQuickJumper
                />
                <Flex alignItems="center">
                  <Select
                    value={pageSize}
                    onChange={handlePageSizeChange}
                    variant="outline"
                  >
                    {pageSizeOptions.map((option) => (
                      <option key={option} value={option}>
                        {option} / page
                      </option>
                    ))}
                  </Select>
                </Flex>
              </VStack>
            </HStack>
          </>
        )}
      </VStack>
    ),

    md: (
      <VStack w="100%" align="stretch" spacing={4} p={4} justify="center">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          justifySelf="center"
          //   marginTop="24px"
          w="98%"
          p={4}
          gap="40px"
          borderWidth="1.2px"
          borderRadius="12px"
          borderColor="gray.300"
        >
          <VStack w="100%" align="stretch">
            <Text pr={5} textAlign="left" fontFamily="Montserrat">
              <b>Search via Native Location in Rajasthan</b>
            </Text>
            <HStack
              spacing={0}
              gap={0}
              borderWidth="1.2px"
              borderRadius="12px"
              borderColor="gray.500"
            >
              <Select
                ml={1}
                // textAlign="center"
                variant="flushed"
                focusBorderColor="none"
                borderBottomWidth={0}
                borderRightWidth="1.2px"
                borderRightColor="gray.500"
                _focus={{ boxShadow: "none" }}
                _active={{ boxShadow: "none" }}
                value={selectedDistrict}
                placeholder="Select District"
                onChange={(e) => setSelectedDistrict(e.target.value)}
                borderColor="gray.400"
                w="52%"
                fontFamily="Montserrat"
              >
                {districts.map((district) => (
                  <option key={district} value={district}>
                    {district}
                  </option>
                ))}
              </Select>
              <Select
                variant="unstyled"
                value={selectedTehsil}
                placeholder="Select Tehsil"
                onChange={(e) => setSelectedTehsil(e.target.value)}
                borderColor="gray.400"
                w="52%"
                fontFamily="Montserrat"
                focusBorderColor="none"
                pl={1}
                // borderRightWidth="1px"
                // borderRightColor="gray.500"
                _focus={{ boxShadow: "none" }}
                _active={{ boxShadow: "none" }}
              // borderLeftWidth="1.2px"
              >
                {tehsils.map((tehsil) => (
                  <option key={tehsil} value={tehsil}>
                    {tehsil}
                  </option>
                ))}
              </Select>

              <Button
                onClick={fetchDataNativePlace}
                colorScheme="blue"
                borderRadius={11}
              >
                <SearchIcon />
              </Button>
            </HStack>
          </VStack>
        </Box>
        {nativeLoading && (
          <>
            <HStack justify="flex-end">
              <Input
                placeholder="Search By: Name or profession"
                value={consolidatedSearch}
                onChange={(e) => setConsolidatedSearch(e.target.value)}
                w="40%"
              />
            </HStack>
            <Box
              borderWidth={1}
              borderColor="gray.500"
              overflowX="auto"
              maxW="100%"
              maxH="600px"
              overflowY="auto"
              sx={{
                '&::-webkit-scrollbar': {
                  width: '12px',
                  backgroundColor: `rgba(0, 0, 0, 0.05)`,
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: `rgba(0, 0, 0, 0.05)`,
                },
              }}
            >
              <Table variant="simple">
                <Thead position="sticky" top={-1} >
                  <Tr>
                    <Th
                      userSelect="none"
                      pr={1}
                      bgColor="gray.100"
                      fontFamily="Poppins"
                      border="1px solid gray"
                    >
                      #
                    </Th>
                    <Th
                      userSelect="none"
                      pr={1}
                      bgColor="gray.100"
                      fontFamily="Poppins"
                      border="1px solid gray"
                    >
                      Name
                    </Th>

                    <Th
                      userSelect="none"
                      pr={1}
                      bgColor="gray.100"
                      fontFamily="Poppins"
                      border="1px solid gray"
                    >
                      Profession
                    </Th>
                    <Th
                      userSelect="none"
                      bgColor="gray.100"
                      fontFamily="Poppins"
                      border="1px solid gray"
                    >
                      Email (Click on the icon to mail)
                    </Th>
                    <Th
                      userSelect="none"
                      bgColor="gray.100"
                      fontFamily="Poppins"
                      border="1px solid gray"
                    >
                      Phone (Click on the icon to connect)
                    </Th>
                    <Th
                      userSelect="none"
                      pr={1}
                      bgColor="gray.100"
                      fontFamily="Poppins"
                      border="1px solid gray"
                    >
                      Current Address
                    </Th>
                    <Th
                      userSelect="none"
                      pr={1}
                      bgColor="gray.100"
                      fontFamily="Poppins"
                      border="1px solid gray"
                    >
                      View More
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {paginatedData.map((item, index) => (
                    <Tr key={item.id}>
                      <Td
                        userSelect="none"
                        textAlign="center"
                        pr={1}
                        pl={1}
                        border="1px solid gray"
                      >
                        {startIndex + index + 1}
                      </Td>
                      <Td
                        userSelect="none"
                        textAlign="center"
                        pr={1}
                        pl={1}
                        border="1px solid gray"
                      >
                        {item.full_name}
                      </Td>
                      <Td
                        userSelect="none"
                        textAlign="center"
                        pr={1}
                        pl={1}
                        border="1px solid gray"
                      >
                        {item.occupation}
                      </Td>
                      <Td
                        userSelect="none"
                        textAlign="center"
                        pr={1}
                        pl={1}
                        border="1px solid gray"
                      >
                        <a href={`mailto:${item.email}`}>
                          {" "}
                          <Icon as={FiMail} mr={2} />
                        </a>
                      </Td>
                      <Td
                        userSelect="none"
                        textAlign="center"
                        pr={1}
                        pl={1}
                        border="1px solid gray"
                      >
                        {/* <Tooltip
                          hasArrow
                          label={`${item.mobile_number}`}
                          bg="gray.300"
                          color="black"
                        >
                          <a href={`tel:${item.mobile_number}`}>
                            <Icon as={FiPhone} mr={2} />
                          </a>
                        </Tooltip> */}
                        <a href={`tel:${item.mobile_number}`}>
                          <Icon as={FiPhone} mr={2} />
                        </a>
                      </Td>
                      <Td
                        userSelect="none"
                        textAlign="center"
                        pr={1}
                        pl={1}
                        border="1px solid gray"
                      >
                        {item.address}, {item.city}, {item.state},{" "}
                        {item.country}
                      </Td>
                      <Td
                        userSelect="none"
                        textAlign="center"
                        pr={1}
                        pl={1}
                        border="1px solid gray"
                      >
                        <ExternalLinkIcon
                          cursor="pointer"
                          color="green"
                          onClick={() => {
                            setVisible(true);
                            setImageDisplay(item.imageUrl);
                            // console.log(imageDisplay)
                            setVisibleModal(true);
                            setViewMore(true);
                            setViewMoreEmail(item.email);
                          }}
                        />
                      </Td>
                      <Modal
                        isOpen={visibleModal && viewMoreEmail === item.email}
                        size="5xl"
                        onClose={handleCloseModal}
                        isCentered
                      >
                        <ModalOverlay
                          bg="rgba(0, 0, 0,0.9)"
                          backdropFilter="blur(8px)"
                          zIndex="modalOverlay"
                        />
                        <ModalContent>
                          <ModalHeader>Detail Information</ModalHeader>
                          <Box
                            overflowX="auto"
                            maxW="100%"

                          // borderRadius="12px"
                          >
                            <Table variant="simple">
                              <Thead position="sticky" top={-1} >
                                <Tr>
                                  <Th
                                    userSelect="none"
                                    pr={1}
                                    bgColor="gray.100"
                                    fontFamily="Poppins"
                                    border="1px solid gray"
                                  >
                                    #
                                  </Th>
                                  <Th
                                    userSelect="none"
                                    pr={1}
                                    bgColor="gray.100"
                                    fontFamily="Poppins"
                                    border="1px solid gray"
                                  >
                                    Name
                                  </Th>
                                  <Th
                                    userSelect="none"
                                    pr={1}
                                    bgColor="gray.100"
                                    fontFamily="Poppins"
                                    border="1px solid gray"
                                  >
                                    S/O ,D/O ,W/O NAME
                                  </Th>
                                  <Th
                                    userSelect="none"
                                    pr={1}
                                    bgColor="gray.100"
                                    fontFamily="Poppins"
                                    border="1px solid gray"
                                  >
                                    Profession
                                  </Th>
                                  <Th
                                    userSelect="none"
                                    bgColor="gray.100"
                                    fontFamily="Poppins"
                                    border="1px solid gray"
                                  >
                                    Email (Click on the icon to mail)
                                  </Th>
                                  <Th
                                    userSelect="none"
                                    bgColor="gray.100"
                                    fontFamily="Poppins"
                                    border="1px solid gray"
                                  >
                                    Phone (Click on the icon to connect)
                                  </Th>
                                  <Th
                                    userSelect="none"
                                    pr={1}
                                    bgColor="gray.100"
                                    fontFamily="Poppins"
                                    border="1px solid gray"
                                  >
                                    Current Address
                                  </Th>

                                  <Th
                                    userSelect="none"
                                    pr={1}
                                    bgColor="gray.100"
                                    fontFamily="Poppins"
                                    border="1px solid gray"
                                  >
                                    Native Place
                                  </Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                                <Tr key={item.id}>
                                  <Td
                                    userSelect="none"
                                    textAlign="center"
                                    pr={1}
                                    pl={1}
                                    border="1px solid gray"
                                  >
                                    {startIndex + index + 1}
                                  </Td>
                                  <Td
                                    userSelect="none"
                                    textAlign="center"
                                    pr={1}
                                    pl={1}
                                    border="1px solid gray"
                                  >
                                    {item.full_name}
                                  </Td>
                                  <Td
                                    userSelect="none"
                                    textAlign="center"
                                    pr={1}
                                    pl={1}
                                    border="1px solid gray"
                                  >
                                    {item.so_wo_do}
                                  </Td>
                                  <Td
                                    userSelect="none"
                                    textAlign="center"
                                    pr={1}
                                    pl={1}
                                    border="1px solid gray"
                                  >
                                    {item.occupation}
                                  </Td>
                                  <Td
                                    userSelect="none"
                                    textAlign="center"
                                    pr={1}
                                    pl={1}
                                    border="1px solid gray"
                                  >
                                    <a href={`mailto:${item.email}`}>
                                      <Icon as={FiMail} mr={2} />
                                    </a>
                                  </Td>
                                  <Td
                                    userSelect="none"
                                    textAlign="center"
                                    pr={1}
                                    pl={1}
                                    border="1px solid gray"
                                  >
                                    {/* <Tooltip
                                      hasArrow
                                      label={`${item.mobile_number}`}
                                      bg="gray.300"
                                      color="black"
                                    >
                                      <a href={`tel:${item.mobile_number}`}>
                                        <Icon as={FiPhone} mr={2} />
                                      </a>
                                    </Tooltip> */}
                                    <a href={`tel:${item.mobile_number}`}>
                                      <Icon as={FiPhone} mr={2} />
                                    </a>
                                  </Td>
                                  <Td
                                    userSelect="none"
                                    textAlign="center"
                                    pr={1}
                                    pl={1}
                                    border="1px solid gray"
                                  >
                                    {item.address}, {item.city}, {item.state},{" "}
                                    {item.country}
                                  </Td>
                                  <Td
                                    userSelect="none"
                                    textAlign="center"
                                    pr={1}
                                    pl={1}
                                    border="1px solid gray"
                                  >
                                    {item.native_district}, {item.native_tehsil}
                                    ,{item.native_village}
                                  </Td>
                                </Tr>
                              </Tbody>
                            </Table>
                          </Box>
                          <ModalFooter>
                            <Button
                              colorScheme="red"
                              mr={3}
                              onClick={handleCloseModal}
                            >
                              Close
                            </Button>
                          </ModalFooter>
                        </ModalContent>
                      </Modal>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>

            <HStack justify="flex-end" spacing={4}>
              <Pagination
                size="large"
                current={currentPage}
                total={totalItems}
                pageSize={pageSize}
                showSizeChanger={false}
                onChange={handlePageChange}
                showTotal={(total) => `Total ${total} items`}
                showQuickJumper
              />
              <Flex alignItems="center">
                <Select
                  value={pageSize}
                  onChange={handlePageSizeChange}
                  variant="outline"
                >
                  {pageSizeOptions.map((option) => (
                    <option key={option} value={option}>
                      {option} / page
                    </option>
                  ))}
                </Select>
              </Flex>
            </HStack>
          </>
        )}
      </VStack>
    ),
  });

  return (
    <Box
      display={{ base: "block", md: "flex" }}
      justifyContent="center"
      alignItems="center"
      w={{ base: "auto", md: "90%" }}
      // borderRadius="lg"
      p={{ base: 4, md: 8 }}
      bg="white"
      // borderWidth="1px"
      // borderColor="gray.300"
      ml={{ base: 4, md: "auto" }}
      mr={{ base: 4, md: "auto" }}
      mt={{ base: 4, md: 8 }}
      mb={{ base: 4, md: "auto" }}
      maxW="1320px"
      h="fit-content"
      overflowY="scroll"
      justifyItems="flex-end"
    >
      <Box
        display={{ base: "block", md: "flex" }}
        justifyContent="center"
        alignItems="center"
        w="100%"
        // borderRadius="lg"
        // p={{ base: 4, md: 6 }}
        bg="white"
        // borderWidth="2px"
        // borderColor="gray.300"
        // ml={{ base: 4, md: 8 }}
        // mr={{ base: 4, md: 8 }}
        mt={{ base: 4, md: 8 }}
        mb={{ base: 4, md: "auto" }}
        overflowY="hidden"
        justifyItems="flex-end"
      >
        {useBreakpointValue({
          base: (
            <VStack
              w="100%"
              align="stretch"
              flexDir="column"
              alignItems="center"
            >
              <Box
                as="button"
                borderRadius="md"
                bg="#0d6efd"
                color="white"
                px={6}
                h={10}
                // mb={4}
                w={buttonWidth}
                onClick={() => {
                  if (formData.length > 0) {
                    setViewNativePlace(true);
                    setViewCurrentLocation(false);
                    setLoading(false);
                    setConsolidatedSearch("");
                  } else {
                    setModalVisible(true);
                  }
                }}
              >
                <Text fontSize={9.99}>
                  Search via <b>NATIVE PLACE</b>
                </Text>
              </Box>
              <Box
                as="button"
                borderRadius="md"
                bg="#0d6efd"
                color="white"
                px={6}
                h={10}
                mb={4}
                w={buttonWidth}
                onClick={() => {
                  if (formData.length > 0) {
                    setViewCurrentLocation(true);
                    setViewNativePlace(false);
                    setNativeLoading(false);
                    setConsolidatedSearch("");
                  } else {
                    setModalVisible(true);
                  }
                }}
              >
                <Text fontSize={9.99}>
                  Search via <b>CURRENT LOCATION</b>
                </Text>
              </Box>

              {viewCurrentLocation && (
                <Box
                  display={{ base: "block", md: "flex" }}
                  justifyContent="space-between"
                  w="100%"
                  borderRadius="lg"
                  bg="white"
                  mb={{ base: 4, md: 8 }}
                  overflowY="hidden"
                >
                  {buttonCurrentLocationLayout}
                </Box>
              )}

              {viewNativePlace && (
                <Box
                  display={{ base: "block", md: "flex" }}
                  justifyContent="space-between"
                  w="100%"
                  borderRadius="lg"
                  bg="white"
                  mb={{ base: 4, md: 8 }}
                  overflowY="hidden"
                >
                  {buttonNativePlaceLayout}
                </Box>
              )}
              {!formData.length && (
                <Modal
                  isOpen={modalVisible}
                  size="lg"
                  onClose={() => setModalVisible(false)}
                >
                  <ModalOverlay
                    bg="rgba(0, 0, 0, 0.6)"
                    backdropFilter="blur(8px)"
                    zIndex="modalOverlay"
                  />
                  <ModalContent>
                    <ModalHeader fontFamily="sans-serif" mb={4}>
                      Fill out the form details
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                      <Stack spacing={4}>
                        <FormControl 
                        isInvalid={!name && isSubmitted}
                        isRequired>
                          <FormLabel fontWeight="extrabold" fontFamily="Montserrat">Name</FormLabel>
                          <Input
                            name="name"
                            value={name}
                            type="text"
                            placeholder="Enter your name"
                            onChange={(e) => {
                              setName(e.target.value);
                            }}
                          />
                        </FormControl>


                        <FormControl
                          id="phone"
                          isRequired
                          isInvalid={!phone && isSubmitted}
                        >
                          <FormLabel fontWeight="extrabold" fontFamily="Montserrat">
                            Phone number
                          </FormLabel>
                          <PhoneInput
                            value={phone}
                            country={"in"}
                            countryCodeEditable={false}
                            placeholder="Enter phone number registered with MIF"
                            onChange={(value) => {
                              setPhone(value);
                            }}
                            id="phone"
                            autoComplete="off"
                            onClick={() => setMobileNumberFocused(true)}
                            style={{
                              border: `${(!phone && isSubmitted) ? "2px solid #E53E3E": "1px solid #ccc"}`,
                              borderRadius: "4px",
                              padding: "8px",
                              fontSize: "14px",
                              outline: "none",
                              borderColor: "#E0E0E0",
                              boxShadow: isMobileNumberFocused && (!isSubmitted)
                                ? "0 0 0 2px #3182ce"
                                : "none",
                            }}
                            inputProps={{
                              className: "phone-input",
                              ref: mobileNumberRef
                            }}
                          />
                        </FormControl>

                        <FormControl>
                          <FormLabel fontWeight="extrabold"  fontFamily="Montserrat">Email</FormLabel>
                          <Input
                            name="email"
                            value={email}
                            type="email"
                            placeholder="Enter email registered with MIF"
                            onChange={(e) => {
                              setEmail(e.target.value);
                            }}
                          />
                        </FormControl>

                        {/* Add more form fields as needed */}
                      </Stack>
                    </ModalBody>




                    <ModalFooter mt={4} marginBottom={"-1rem"} >

                      <Button
                        type="submit"
                        form="form"
                        mx={2}
                        colorScheme="green"
                        onClick={handleFormSubmit}
                        fontFamily="Montserrat"
                        isLoading={loading}
                      >
                        Submit
                      </Button>
                      <Button
                        colorScheme="red"
                        mx={2}
                        onClick={() => setModalVisible(false)}
                        fontFamily="Montserrat"
                      >
                        Cancel
                      </Button>


                    </ModalFooter>
                    <ModalFooter mb={2} >
                    <Link 
                    mr={2}
                    onClick={() => navigate('/form')}
                    color='blue.400' href='#'>
                       Not a MIF member, please <span className="font-bold"> Join Us</span>
                    </Link>
                    </ModalFooter>
                  </ModalContent>
                </Modal>
              )}
            </VStack>
          ),

          md: (
            <VStack w="100%" align="stretch" spacing={4} justify="center">
              <HStack
                display="flex"
                justify="flex-end"
                alignItems="center"
                justifyContent="center"
                flexWrap="wrap"
              >
                <Box
                  as="button"
                  borderRadius="md"
                  bg="#0d6efd"
                  color="white"
                  px={6}
                  h={10}
                  //   mb={6}
                  w={buttonWidth}
                  fontFamily="Montserrat"
                  onClick={() => {
                    if (formData.length > 0) {
                      setViewNativePlace(true);
                      setViewCurrentLocation(false);
                      setLoading(false);
                      setConsolidatedSearch("");
                    } else {
                      setClickedSearch('native');
                      setModalVisible(true);

                    }
                  }}
                  justifySelf="center"
                  alignSelf="center"
                >
                  Search via <b>NATIVE PLACE</b>
                </Box>
                <Box
                  as="button"
                  borderRadius="md"
                  bg="#0d6efd"
                  color="white"
                  px={6}
                  h={10}
                  // mb={6}
                  fontFamily="Montserrat"
                  w={buttonWidth}
                  onClick={() => {
                    if (formData.length > 0) {
                      setViewCurrentLocation(true);
                      setViewNativePlace(false);
                      setNativeLoading(false);
                      setConsolidatedSearch("");
                    } else {
                      setClickedSearch('current');
                      setModalVisible(true);
                    }
                  }}
                >
                  Search via <b>CURRENT LOCATION</b>
                </Box>
              </HStack>

              {viewCurrentLocation && (
                <Box
                  display={{ base: "block", md: "flex" }}
                  justifyContent="space-between"
                  w="100%"
                  borderRadius="lg"
                  bg="white"
                  mb={{ base: 4, md: 8 }}
                  overflowY="hidden"
                >
                  {buttonCurrentLocationLayout}
                </Box>
              )}

              {viewNativePlace && (
                <Box
                  display={{ base: "block", md: "flex" }}
                  justifyContent="space-between"
                  w="100%"
                  borderRadius="lg"
                  bg="white"
                  mb={{ base: 4, md: 8 }}
                  overflowY="hidden"
                >
                  {buttonNativePlaceLayout}
                </Box>
              )}

              {!formData.length && (
                <Modal
                  isOpen={modalVisible}
                  size="lg"
                  onClose={() => setModalVisible(false)}
                  blockScrollOnMount={false}
                  closeOnOverlayClick={false}
                >
                  <ModalOverlay
                    bg="rgba(0, 0, 0, 0.6)"
                    backdropFilter="blur(8px)"
                    zIndex="modalOverlay"
                  />
                  <ModalContent>
                    <ModalHeader fontFamily="sans-serif" mb={4}>
                      Fill out the form details
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                      <form onSubmit={handleFormSubmit}>
                        <Stack spacing={4}>
                          <FormControl 
                          isInvalid={!name && isSubmitted}
                          isRequired>
                            <FormLabel fontWeight="extrabold" fontFamily="Montserrat">Name</FormLabel>
                            <Input
                              name="name"
                              value={name}
                              type="text"
                              placeholder="Enter your name"
                              onChange={(e) => {
                                setName(e.target.value);
                              }}
                            />
                          </FormControl>


                          <FormControl
                            id="phone"
                            isRequired
                            isInvalid={!phone && isSubmitted}
                          >
                            <FormLabel fontWeight="extrabold" fontFamily="Montserrat">
                              Phone number
                            </FormLabel>
                            <PhoneInput

                              value={phone}
                              country={"in"}
                              countryCodeEditable={false}
                              placeholder="Enter phone number registered with MIF"
                              onChange={(value) => {
                                setPhone(value);
                              }}
                              id="phone"
                              autoComplete="off"
                              onClick={() => setMobileNumberFocused(true)}
                              style={{
                                border: `${(!phone && isSubmitted) ? "2px solid #E53E3E": "1px solid #ccc"}`,
                                borderRadius: "4px",
                                padding: "8px",
                                fontSize: "14px",
                                outline: "none",
                                borderColor: "#E0E0E0",
                                boxShadow: isMobileNumberFocused && (!isSubmitted)
                                  ? "0 0 0 2px #3182ce"
                                  : "none",
                              }}
                              inputProps={{
                                className: "phone-input",
                                ref: mobileNumberRef
                              }}
                            />
                          </FormControl>

                          <FormControl>
                            <FormLabel fontWeight="extrabold"  fontFamily="Montserrat">Email</FormLabel>
                            <Input
                              name="email"
                              value={email}
                              type="email"
                              placeholder="Enter email registered with MIF"
                              onChange={(e) => {
                                setEmail(e.target.value);
                              }}
                            />
                          </FormControl>

                          {/* Add more form fields as needed */}
                        </Stack>
                      </form>
                    </ModalBody>
                    <ModalFooter mt={4} marginBottom={"-1rem"} >
                      <Button
                        type="submit"
                        form="form"
                        mx={2}
                        colorScheme="green"
                        onClick={handleFormSubmit}
                        fontFamily="Montserrat"
                        isLoading={loading}
                      >
                        Submit
                      </Button>
                      <Button
                        colorScheme="red"
                        mx={2}
                        onClick={() => setModalVisible(false)}
                        fontFamily="Montserrat"
                      >
                        Cancel
                      </Button>


                    </ModalFooter>

                    <ModalFooter mb={2} >
                    <Link 
                     mr={2}
                    onClick={() => navigate('/form')}
                    color='blue.400' href='#'>
                       Not a MIF member, please <span className="font-bold"> Join Us</span> 
                    </Link>
                    
                    </ModalFooter>

                  </ModalContent>
                </Modal>
              )}
            </VStack>
          ),
        })}
      </Box>
    </Box>
  );
};

export default AdvanceSearch;
