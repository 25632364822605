import React, { useEffect, useRef, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import {
  VStack,
  HStack,
  FormControl,
  FormLabel,
  Input,
  Select,
  Text,
  Checkbox,
  Button,
  RadioGroup,
  Radio,
  useBreakpointValue,
  Box,
  Flex,
  useToast,
} from "@chakra-ui/react";
import { State } from "./provider";

const UploadImageForm = () => {
  useEffect(() => {
    document.title = "Upload · Marwadi International Federation";
  }, []);
  const { mobile_number } = useParams();
  // console.log(Email);
  const value = State();
  const { admin } = value || {};
  const [fullName, setFullName] = useState();
  const [relation, setRelation] = useState();
  const [email, setEmail] = useState();
  const [mobileNumber, setMobileNumber] = useState();
  const [location, setLocation] = useState();
  const [address, setAddress] = useState();
  const [selectedMap, setSelectedMap] = useState();
  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedState, setSelectedState] = useState();
  const [selectedCity, setSelectedCity] = useState();

  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedTehsil, setSelectedTehsil] = useState("");
  const [village, setVillage] = useState("");
  const [occupation, setOccupation] = useState();
  const [name, setName] = useState();
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [emailValid, setEmailValid] = useState(true);
  const [phoneValid, setPhoneValid] = useState(true);
  const emailRef = useRef(null);
  const fullNameRef = useRef(null);
  const relationRef = useRef(null);
  const mobileNumberRef = useRef(null);
  const occupationRef = useRef(null);
  const nameRef = useRef(null);
  const firstInvalidFieldRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [resgistered, setResgistered] = useState();
  const [lastModified, setLastModified] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const [pic, setPic] = useState();
  const [picLoading, setPicLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();
  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${admin.token}`,
      },
    };
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/user/${mobile_number}`, config)
      .then((res) => {
        setFullName(res.data.full_name);
        setRelation(res.data.so_wo_do);
        setEmail(res.data.email);
        setMobileNumber(res.data.mobile_number);
        setLocation(res.data.country_type);
        setAddress(res.data.address);
        setSelectedCountry(res.data.country);
        setSelectedState(res.data.state);
        setSelectedCity(res.data.city);
        setSelectedDistrict(res.data.native_district);
        setSelectedTehsil(res.data.native_tehsil);
        setVillage(res.data.native_village);
        setOccupation(res.data.occupation);
        setName(res.data.name);

        const registrationTime = res.data.registration_time;
        const lastModifiedTime = res.data.last_modified;

        if (registrationTime) {
          const adjustedRegistrationTime = new Date(registrationTime);
          adjustedRegistrationTime.setHours(
            adjustedRegistrationTime.getHours() - 5
          );
          adjustedRegistrationTime.setMinutes(
            adjustedRegistrationTime.getMinutes() - 30
          );

          const formattedRegistrationTime =
            adjustedRegistrationTime.toLocaleString("en-IN", {
              month: "2-digit",
              day: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
            });
          setResgistered(formattedRegistrationTime);
        } else {
          setResgistered("");
        }

        if (lastModifiedTime) {
          const adjustedModifiedTime = new Date(lastModifiedTime);
          adjustedModifiedTime.setHours(adjustedModifiedTime.getHours() - 5);
          adjustedModifiedTime.setMinutes(
            adjustedModifiedTime.getMinutes() - 30
          );

          const formattedModifiedTime = adjustedModifiedTime.toLocaleString(
            "en-IN",
            {
              month: "2-digit",
              day: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
            }
          );
          setLastModified(formattedModifiedTime);
        } else {
          setLastModified("");
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const submitHandler = async () => {
    try {
      if (!pic) {
        toast({
          title: "Please Upload The Image",
          status: "warning",
          duration: 5000,
          isClosable: true,
          position: "bottom",
        });
        return;
      }
      if (!selectedMap) {
        toast({
          title: "Please choose the Mapping Field",
          status: "warning",
          duration: 5000,
          isClosable: true,
          position: "bottom",
        });
        return;
      }
      // const data = await response.json();
      const imageUrl = pic;
      // console.log(imageUrl);

      await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/image/upload`, {
        imageUrl,
        mobile_number,
        selectedMap,
      });

      toast({
        title: "Image Uploaded Successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
      navigate("/admin/dashboard", { replace: true });
    } catch (error) {
      console.error(error);
      toast({
        title: "Unable to Upload Image",
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
    }
  };

  const postDetails = async (pics) => {
    setPicLoading(true);

    if (!pics) {
      toast({
        title: "Please Select an Image!",
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
      setPicLoading(false);
      return;
    }

    if (pics.type === "image/jpeg" || pics.type === "image/png") {
      const formData = new FormData();
      formData.append("file", pics);

      try {
        const response = await fetch(process.env.REACT_APP_IMAGE_CLOUD_URI, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_IMAGE_CLOUD_TOKEN}`,
          },
          body: formData,
        });
        if (response.ok) {
          const data = await response.json();
          setPic(data.url.toString());
          // toast({
          //   title: "Image uploaded successfully!",
          //   status: "success",
          //   duration: 5000,
          //   isClosable: true,
          //   position: "bottom",
          // });
          setPicLoading(false);
        } else {
          toast({
            title: "Failed to upload image",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "bottom",
          });
          setPicLoading(false);
        }
      } catch (error) {
        toast({
          title: "Failed to upload image",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "bottom",
        });
        setPicLoading(false);
      }
    } else {
      toast({
        title: "Please Select an Image!",
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
      setPicLoading(false);
      return;
    }
  };

  var districtArray = {
    "Andhra Pradesh": [
      "Anantapur",
      "Chittoor",
      "East Godavari",
      "Guntur",
      "Krishna",
      "Kurnool",
      "Nellore",
      "Prakasam",
      "Srikakulam",
      "Visakhapatnam",
      "Vizianagaram",
      "West Godavari",
      "YSR Kadapa",
    ],
    "Arunachal Pradesh": [
      "Anjaw",
      "Changlang",
      "Dibang Valley",
      "East Kameng",
      "East Siang",
      "Kamle",
      "Kra Daadi",
      "Kurung Kumey",
      "Lepa Rada",
      "Lohit",
      "Longding",
      "Lower Dibang Valley",
      "Lower Siang",
      "Lower Subansiri",
      "Namsai",
      "Pakke Kessang",
      "Papum Pare",
      "Shi Yomi",
      "Siang",
      "Tawang",
      "Tirap",
      "Upper Siang",
      "Upper Subansiri",
      "West Kameng",
      "West Siang",
    ],
    Assam: [
      "Baksa",
      "Barpeta",
      "Biswanath",
      "Bongaigaon",
      "Cachar",
      "Charaideo",
      "Chirang",
      "Darrang",
      "Dhemaji",
      "Dhubri",
      "Dibrugarh",
      "Dima Hasao",
      "Goalpara",
      "Golaghat",
      "Hailakandi",
      "Hojai",
      "Jorhat",
      "Kamrup",
      "Kamrup Metropolitan",
      "Karbi Anglong",
      "Karimganj",
      "Kokrajhar",
      "Lakhimpur",
      "Majuli",
      "Morigaon",
      "Nagaon",
      "Nalbari",
      "Sivasagar",
      "Sonitpur",
      "South Salmara-Mankachar",
      "Tinsukia",
      "Udalguri",
      "West Karbi Anglong",
    ],
    Bihar: [
      "Araria",
      "Arwal",
      "Aurangabad",
      "Banka",
      "Begusarai",
      "Bhagalpur",
      "Bhojpur",
      "Buxar",
      "Darbhanga",
      "East Champaran (Motihari)",
      "Gaya",
      "Gopalganj",
      "Jamui",
      "Jehanabad",
      "Kaimur (Bhabua)",
      "Katihar",
      "Khagaria",
      "Kishanganj",
      "Lakhisarai",
      "Madhepura",
      "Madhubani",
      "Munger (Monghyr)",
      "Muzaffarpur",
      "Nalanda",
      "Nawada",
      "Patna",
      "Purnia (Purnea)",
      "Rohtas",
      "Saharsa",
      "Samastipur",
      "Saran",
      "Sheikhpura",
      "Sheohar",
      "Sitamarhi",
      "Siwan",
      "Supaul",
      "Vaishali",
      "West Champaran",
    ],
    Chhattisgarh: [
      "Balod",
      "Baloda Bazar",
      "Balrampur",
      "Bastar",
      "Bemetara",
      "Bijapur",
      "Bilaspur",
      "Dantewada (South Bastar)",
      "Dhamtari",
      "Durg",
      "Gariyaband",
      "Janjgir-Champa",
      "Jashpur",
      "Kabirdham (Kawardha)",
      "Kanker (North Bastar)",
      "Kondagaon",
      "Korba",
      "Korea (Koriya)",
      "Mahasamund",
      "Mungeli",
      "Narayanpur",
      "Raigarh",
      "Raipur",
      "Rajnandgaon",
      "Sukma",
      "Surajpur",
      "Surguja",
    ],
    Goa: ["North Goa", "South Goa"],
    Gujarat: [
      "Ahmedabad",
      "Amreli",
      "Anand",
      "Aravalli",
      "Banaskantha (Palanpur)",
      "Bharuch",
      "Bhavnagar",
      "Botad",
      "Chhota Udepur",
      "Dahod",
      "Dangs (Ahwa)",
      "Devbhoomi Dwarka",
      "Gandhinagar",
      "Gir Somnath",
      "Jamnagar",
      "Junagadh",
      "Kheda (Nadiad)",
      "Kutch",
      "Mahisagar",
      "Mehsana",
      "Morbi",
      "Narmada (Rajpipla)",
      "Navsari",
      "Panchmahal (Godhra)",
      "Patan",
      "Porbandar",
      "Rajkot",
      "Sabarkantha (Himmatnagar)",
      "Surat",
      "Surendranagar",
      "Tapi (Vyara)",
      "Vadodara",
      "Valsad",
    ],
    Haryana: [
      "Ambala",
      "Bhiwani",
      "Charkhi Dadri",
      "Faridabad",
      "Fatehabad",
      "Gurugram",
      "Hisar",
      "Jhajjar",
      "Jind",
      "Kaithal",
      "Karnal",
      "Kurukshetra",
      "Mahendragarh",
      "Nuh",
      "Palwal",
      "Panchkula",
      "Panipat",
      "Rewari",
      "Rohtak",
      "Sirsa",
      "Sonipat",
      "Yamunanagar",
    ],
    "Himachal Pradesh": [
      "Bilaspur",
      "Chamba",
      "Hamirpur",
      "Kangra",
      "Kinnaur",
      "Kullu",
      "Lahaul &amp; Spiti",
      "Mandi",
      "Shimla",
      "Sirmaur (Sirmour)",
      "Solan",
      "Una",
    ],
    "Jammu and Kashmir": [
      "Anantnag",
      "Bandipore",
      "Baramulla",
      "Budgam",
      "Doda",
      "Ganderbal",
      "Jammu",
      "Kathua",
      "Kishtwar",
      "Kulgam",
      "Kupwara",
      "Poonch",
      "Pulwama",
      "Rajauri",
      "Ramban",
      "Reasi",
      "Samba",
      "Shopian district",
      "Srinagar",
      "Udhampur",
    ],
    Jharkhand: [
      "Bokaro",
      "Chatra",
      "Deoghar",
      "Dhanbad",
      "Dumka",
      "East Singhbhum",
      "Garhwa",
      "Giridih",
      "Godda",
      "Gumla",
      "Hazaribag",
      "Jamtara",
      "Khunti",
      "Koderma",
      "Latehar",
      "Lohardaga",
      "Pakur",
      "Palamu",
      "Ramgarh",
      "Ranchi",
      "Sahibganj",
      "Seraikela-Kharsawan",
      "Simdega",
      "West Singhbhum",
    ],
    Karnataka: [
      "Bagalkot",
      "Ballari (Bellary)",
      "Belagavi (Belgaum)",
      "Bengaluru (Bangalore) Rural",
      "Bengaluru (Bangalore) Urban",
      "Bidar",
      "Chamarajanagar",
      "Chikballapur",
      "Chikkamagaluru (Chikmagalur)",
      "Chitradurga",
      "Dakshina Kannada",
      "Davanagere",
      "Dharwad",
      "Gadag",
      "Hassan",
      "Haveri",
      "Kalaburagi (Gulbarga)",
      "Kodagu",
      "Kolar",
      "Koppal",
      "Mandya",
      "Mysuru (Mysore)",
      "Raichur",
      "Ramanagara",
      "Shivamogga (Shimoga)",
      "Tumakuru (Tumkur)",
      "Udupi",
      "Uttara Kannada (Karwar)",
      "Vijayapura (Bijapur)",
      "Yadgir",
    ],
    Kerala: [
      "Alappuzha",
      "Ernakulam",
      "Idukki",
      "Kannur",
      "Kasaragod",
      "Kollam",
      "Kottayam",
      "Kozhikode",
      "Malappuram",
      "Palakkad",
      "Pathanamthitta",
      "Thiruvananthapuram",
      "Thrissur",
      "Wayanad",
    ],
    "Madhya Pradesh": [
      "Agar Malwa",
      "Alirajpur",
      "Anuppur",
      "Ashoknagar",
      "Balaghat",
      "Barwani",
      "Betul",
      "Bhind",
      "Bhopal",
      "Burhanpur",
      "Chhatarpur",
      "Chhindwara",
      "Damoh",
      "Datia",
      "Dewas",
      "Dhar",
      "Dindori",
      "Guna",
      "Gwalior",
      "Harda",
      "Hoshangabad",
      "Indore",
      "Jabalpur",
      "Jhabua",
      "Katni",
      "Khandwa",
      "Khargone",
      "Mandla",
      "Mandsaur",
      "Morena",
      "Narsinghpur",
      "Neemuch",
      "Panna",
      "Raisen",
      "Rajgarh",
      "Ratlam",
      "Rewa",
      "Sagar",
      "Satna",
      "Sehore",
      "Seoni",
      "Shahdol",
      "Shajapur",
      "Sheopur",
      "Shivpuri",
      "Sidhi",
      "Singrauli",
      "Tikamgarh",
      "Ujjain",
      "Umaria",
      "Vidisha",
    ],
    Maharashtra: [
      "Ahmednagar",
      "Akola",
      "Amravati",
      "Aurangabad",
      "Beed",
      "Bhandara",
      "Buldhana",
      "Chandrapur",
      "Dhule",
      "Gadchiroli",
      "Gondia",
      "Hingoli",
      "Jalgaon",
      "Jalna",
      "Kolhapur",
      "Latur",
      "Mumbai City",
      "Mumbai Suburban",
      "Nagpur",
      "Nanded",
      "Nandurbar",
      "Nashik",
      "Osmanabad",
      "Palghar",
      "Parbhani",
      "Pune",
      "Raigad",
      "Ratnagiri",
      "Sangli",
      "Satara",
      "Sindhudurg",
      "Solapur",
      "Thane",
      "Wardha",
      "Washim",
      "Yavatmal",
    ],
    Manipur: [
      "Bishnupur",
      "Chandel",
      "Churachandpur",
      "Imphal East",
      "Imphal West",
      "Jiribam",
      "Kakching",
      "Kamjong",
      "Kangpokpi",
      "Noney",
      "Pherzawl",
      "Senapati",
      "Tamenglong",
      "Tengnoupal",
      "Thoubal",
      "Ukhrul",
    ],
    Meghalaya: [
      "East Garo Hills",
      "East Jaintia Hills",
      "East Khasi Hills",
      "North Garo Hills",
      "Ri Bhoi",
      "South Garo Hills",
      "South West Garo Hills ",
      "South West Khasi Hills",
      "West Garo Hills",
      "West Jaintia Hills",
      "West Khasi Hills",
    ],
    Mizoram: [
      "Aizawl",
      "Champhai",
      "Kolasib",
      "Lawngtlai",
      "Lunglei",
      "Mamit",
      "Saiha",
      "Serchhip",
    ],
    Nagaland: [
      "Dimapur",
      "Kiphire",
      "Kohima",
      "Longleng",
      "Mokokchung",
      "Mon",
      "Peren",
      "Phek",
      "Tuensang",
      "Wokha",
      "Zunheboto",
    ],
    Odisha: [
      "Angul",
      "Balangir",
      "Balasore",
      "Bargarh",
      "Bhadrak",
      "Boudh",
      "Cuttack",
      "Deogarh",
      "Dhenkanal",
      "Gajapati",
      "Ganjam",
      "Jagatsinghapur",
      "Jajpur",
      "Jharsuguda",
      "Kalahandi",
      "Kandhamal",
      "Kendrapara",
      "Kendujhar (Keonjhar)",
      "Khordha",
      "Koraput",
      "Malkangiri",
      "Mayurbhanj",
      "Nabarangpur",
      "Nayagarh",
      "Nuapada",
      "Puri",
      "Rayagada",
      "Sambalpur",
      "Subarnapur (Sonepur)",
      "Sundargarh",
    ],
    // Puducherry: ["Karaikal", "Mahe", "Pondicherry", "Yanam"],
    Punjab: [
      "Amritsar",
      "Barnala",
      "Bathinda",
      "Faridkot",
      "Fatehgarh Sahib",
      "Fazilka",
      "Ferozepur",
      "Gurdaspur",
      "Hoshiarpur",
      "Jalandhar",
      "Kapurthala",
      "Ludhiana",
      "Mansa",
      "Moga",
      "Muktsar",
      "Nawanshahr (Shahid Bhagat Singh Nagar)",
      "Pathankot",
      "Patiala",
      "Rupnagar",
      "Sahibzada Ajit Singh Nagar (Mohali)",
      "Sangrur",
      "Tarn Taran",
    ],
    Rajasthan: [
      "Ajmer",
      "Alwar",
      "Anupgarh",
      "Balotra",
      "Banswara",
      "Baran",
      "Barmer",
      "Beawar",
      "Bharatpur",
      "Bhilwara",
      "Bikaner",
      "Bundi",
      "Chittorgarh",
      "Churu",
      "Dausa",
      "Dheeg",
      "Didwana-Kuchaman",
      "Dholpur",
      "Dudu",
      "Dungarpur",
      "Gangapur City",
      "Hanumangarh",
      "Jaipur",
      "Jaipur (Rural)",
      "Jaisalmer",
      "Jalore",
      "Jhalawar",
      "Jhunjhunu",
      "Jodhpur",
      "Jodhpur (Rural)",
      "Karauli",
      "Kekri",
      "Kota",
      "Kotputli-Bahrod",
      "Khairthal-Tijara",
      "Nagaur",
      "Neem Ka Thana",
      "Pali",
      "Phalodi",
      "Pratapgarh",
      "Rajsamand",
      "Salumbar",
      "Sanchore",
      "Sawai Madhopur",
      "Shahpura",
      "Sikar",
      "Sirohi",
      "Sri Ganganagar",
      "Tonk",
      "Udaipur",
    ],
    Sikkim: ["East Sikkim", "North Sikkim", "South Sikkim", "West Sikkim"],
    "Tamil Nadu": [
      "Ariyalur",
      "Chennai",
      "Coimbatore",
      "Cuddalore",
      "Dharmapuri",
      "Dindigul",
      "Erode",
      "Kanchipuram",
      "Kanyakumari",
      "Karur",
      "Krishnagiri",
      "Madurai",
      "Nagapattinam",
      "Namakkal",
      "Nilgiris",
      "Perambalur",
      "Pudukkottai",
      "Ramanathapuram",
      "Salem",
      "Sivaganga",
      "Thanjavur",
      "Theni",
      "Thoothukudi (Tuticorin)",
      "Tiruchirappalli",
      "Tirunelveli",
      "Tirupathur",
      "Tiruppur",
      "Tiruvallur",
      "Tiruvannamalai",
      "Tiruvarur",
      "Vellore",
      "Viluppuram",
      "Virudhunagar",
    ],
    Telangana: [
      "Adilabad",
      "Bhadradri Kothagudem",
      "Hyderabad",
      "Jagtial",
      "Jangaon",
      "Jayashankar Bhupalapally",
      "Jogulamba Gadwal",
      "Kamareddy",
      "Karimnagar",
      "Khammam",
      "Komaram Bheem Asifabad",
      "Mahabubabad",
      "Mahabubnagar",
      "Mancherial",
      "Medak",
      "Medchal",
      "Mulugu",
      "Nagarkurnool",
      "Nalgonda",
      "Nirmal",
      "Nizamabad",
      "Peddapalli",
      "Rajanna Sircilla",
      "Rangareddy",
      "Sangareddy",
      "Siddipet",
      "Suryapet",
      "Vikarabad",
      "Wanaparthy",
      "Warangal Rural",
      "Warangal Urban",
      "Yadadri Bhuvanagiri",
    ],
    Tripura: [
      "Dhalai",
      "Gomati",
      "Khowai",
      "North Tripura",
      "Sepahijala",
      "South Tripura",
      "Unakoti",
      "West Tripura",
    ],
    "Uttar Pradesh": [
      "Agra",
      "Aligarh",
      "Allahabad",
      "Ambedkar Nagar",
      "Amethi (Chatrapati Sahuji Mahraj Nagar)",
      "Amroha (J.P. Nagar)",
      "Auraiya",
      "Azamgarh",
      "Baghpat",
      "Bahraich",
      "Ballia",
      "Balrampur",
      "Banda",
      "Barabanki",
      "Bareilly",
      "Basti",
      "Bhadohi",
      "Bijnor",
      "Budaun",
      "Bulandshahr",
      "Chandauli",
      "Chitrakoot",
      "Deoria",
      "Etah",
      "Etawah",
      "Faizabad",
      "Farrukhabad",
      "Fatehpur",
      "Firozabad",
      "Gautam Buddha Nagar",
      "Ghaziabad",
      "Ghazipur",
      "Gonda",
      "Gorakhpur",
      "Hamirpur",
      "Hapur (Panchsheel Nagar)",
      "Hardoi",
      "Hathras",
      "Jalaun",
      "Jaunpur",
      "Jhansi",
      "Kannauj",
      "Kanpur Dehat",
      "Kanpur Nagar",
      "Kasganj",
      "Kaushambi",
      "Kushinagar (Padrauna)",
      "Lakhimpur - Kheri",
      "Lalitpur",
      "Lucknow",
      "Maharajganj",
      "Mahoba",
      "Mainpuri",
      "Mathura",
      "Mau",
      "Meerut",
      "Mirzapur",
      "Moradabad",
      "Muzaffarnagar",
      "Pilibhit",
      "Pratapgarh",
      "Prayagraj (Allahabad)",
      "Raebareli",
      "Rampur",
      "Saharanpur",
      "Sambhal (Bhim Nagar)",
      "Sant Kabir Nagar",
      "Shahjahanpur",
      "Shamali (Prabuddh Nagar)",
      "Shravasti",
      "Siddharth Nagar",
      "Sitapur",
      "Sonbhadra",
      "Sultanpur",
      "Unnao",
      "Varanasi",
    ],
    Uttarakhand: [
      "Almora",
      "Bageshwar",
      "Chamoli",
      "Champawat",
      "Dehradun",
      "Haridwar",
      "Nainital",
      "Pauri Garhwal",
      "Pithoragarh",
      "Rudraprayag",
      "Tehri Garhwal",
      "Udham Singh Nagar",
      "Uttarkashi",
    ],
    "West Bengal": [
      "Alipurduar",
      "Bankura",
      "Birbhum",
      "Cooch Behar",
      "Dakshin Dinajpur (South Dinajpur)",
      "Darjeeling",
      "Hooghly",
      "Howrah",
      "Jalpaiguri",
      "Jhargram",
      "Kalimpong",
      "Kolkata",
      "Malda",
      "Murshidabad",
      "Nadia",
      "North 24 Parganas",
      "Paschim Medinipur (West Medinipur)",
      "Purba Medinipur (East Medinipur)",
      "Purulia",
      "South 24 Parganas",
      "Uttar Dinajpur (North Dinajpur)",
    ],
    "Andaman and Nicobar Islands": [
      "Nicobar",
      "North and Middle Andaman",
      "South Andaman",
    ],
    Chandigarh: ["Chandigarh"],
    "Dadra and Nagar Haveli and Daman and Diu": [
      "Dadra and Nagar Haveli",
      "Daman",
      "Diu",
    ],
    Delhi: [
      "Central Delhi",
      "East Delhi",
      "New Delhi",
      "North Delhi",
      "North East Delhi",
      "North West Delhi",
      "Shahdara",
      "South Delhi",
      "South East Delhi",
      "South West Delhi",
      "West Delhi",
    ],
    Ladakh: ["Kargil", "Leh"],
    Lakshadweep: [
      "Agatti",
      "Amini",
      "Androth",
      "Bitra",
      "Chetlat",
      "Kadmat",
      "Kalpeni",
      "Kavaratti",
      "Kiltan",
      "Minicoy",
    ],
    Puducherry: ["Karaikal", "Mahe", "Puducherry", "Yanam"],
  };

  var tehsils = {
    Ajmer: [
      "Ajmer",
      "Arai",
      "Bhinai",
      "Bijainagar",
      "Kishangarh",
      "Masuda",
      "Nasirabad",
      "Pisangan",
      "Pushkar",
      "Roopangarh",
      "Sanver",
      "Sarwar",
      "Tantoti",
      "Tatgarh",
    ],
    Alwar: [
      "Alwar",
      "Bansur",
      "Govindgarh",
      "Kathumar",
      "Kishangarh Bas",
      "Kotkasim",
      "Laxmangarh",
      "Mala Khera",
      "Mundawar",
      "Narayanpur",
      "Neemrana",
      "Rajgarh",
      "Ramgarh",
      "Reni",
      "Tapukara",
      "Tehla",
      "Thanagazi",
    ],
    Anupgarh: [],
    Balotra: [],

    Banswara: ["Bagidora", "Banswara", "Garhi", "Ghatol"],
    Baran: ["Antah", "Atru", "Baran", "Chhabra", "Chhipabarod"],
    Barmer: [
      "Barmer",
      "Baytoo",
      "Chohtan",
      "Gudamalani",
      "Pachpadra",
      "Ramsar",
      "Sheo",
      "Siwana",
    ],
    Beawar: [],
    Bharatpur: [
      "Bayana",
      "Bharatpur",
      "Bhusawar",
      "Kaman",
      "Kumher",
      "Nadbai",
      "Nagar",
      "Pahari",
      "Rupbas",
      "Sikri",
      "Uchchain",
      "Weir",
    ],
    Bhilwara: [
      "Asind",
      "Badnor",
      "Banera",
      "Bhilwara",
      "Bijolia",
      "Hamirgarh",
      "Hurda",
      "Jahajpur",
      "Karera",
      "Kotri",
      "Mandal",
      "Mandalgarh",
      "Phooliya Kala",
      "Raipur",
      "Sahara",
    ],
    Bikaner: [
      "Bajju",
      "Bikaner",
      "Chhatargarh",
      "Dungargarh",
      "Khajuwala",
      "Kolayat",
      "Lunkaransar",
      "Nokha",
      "Poogal",
    ],
    Bundi: ["Bundi", "Hindaun", "Indragarh", "Kapren", "Nainwa"],
    Chittorgarh: [
      "Bari Sadri",
      "Begun",
      "Chittorgarh",
      "Dungla",
      "Kapasan",
      "Nimbahera",
      "Pratapgarh",
      "Rashmi",
      "Rawatbhata",
    ],
    Churu: [
      "Bidasar",
      "Churu",
      "Rajgarh",
      "Ratangarh",
      "Sardarshahar",
      "Siddhmukh",
      "Sujangarh",
      "Taranagar",
    ],
    Dausa: ["Bandikui", "Dausa", "Lalsot", "Mahwa"],
    Dheeg: [],
    Dholpur: ["Bari", "Dholpur", "Rajakhera", "Saramathura"],
    "Didwana-Kuchaman": [],
    Dudu: [],
    Dungarpur: [
      "Aspur",
      "Bichhiwara",
      "Chikhali",
      "Dowra",
      "Dungarpur",
      "Galiyakot",
      "Gamri Ahara",
      "Jhonthari",
      "Sabla",
      "Sagwara",
      "Simalwara",
    ],
    "Gangapur City": [],
    Hanumangarh: [
      "Bhadra",
      "Hanumangarh",
      "Nohar",
      "Pilibanga",
      "Rawatsar",
      "Sangria",
      "Tibbi",
    ],
    Jaipur: [
      "Amber",
      "Andhi",
      "Bassi",
      "Chaksu",
      "Chomu",
      "Jaipur",
      "Jamwa Ramgarh",
      "Jobner",
      "Kishangarh Renwal",
      "Kotkhawda",
      "Kotputli",
      "Madhorajpura",
      "Mauzmabad",
      "Pawta",
      "Phagi",
      "Phulera",
      "Sanganer",
      "Shahpura",
      "Toonga",
      "Viratnagar",
    ],
    "Jaipur (Rural)": [],

    Jaisalmer: ["Fatehgarh", "Jaisalmer", "Pokaran", "Sam"],
    Jalore: [
      "Ahore",
      "Bhinmal",
      "Jalore",
      "Jaswantpura",
      "Raniwara",
      "Sayla",
      "Siana",
    ],
    Jhalawar: [
      "Aklera",
      "Bakani",
      "Jhalarapatan",
      "Jhalawar",
      "Khanpur",
      "Manoharthana",
      "Pirawa",
    ],
    Jhunjhunu: [
      "Buhana",
      "Chirawa",
      "Jhunjhunu",
      "Khetri",
      "Malsisar",
      "Nawalgarh",
      "Pilani",
      "Surajgarh",
      "Udaipurwati",
    ],
    Jodhpur: ["Bhopalgarh", "Bilara", "Jodhpur", "Luni", "Osian", "Shergarh"],
    "Jodhpur (Rural)": [],
    Karauli: [
      "Karauli",
      "Hindaun",
      "Masalpur",
      "Mandrayal",
      "Nadoti",
      "Sapotra",
      "Suroth",
      "Shri Mahaveer Ji",
      "Todabhim",
    ],
    Kekri: [],
    "Khairthal-Tijara": [],
    Kota: [
      "Digod",
      "Itawa",
      "Kota",
      "Kaithoon",
      "Pipalda",
      "Ramganj Mandi",
      "Sangod",
    ],
    "Kotputli-Bahrod": [],
    Nagaur: [
      "Degana",
      "Jayal",
      "Khinvsar",
      "Ladnu",
      "Makrana",
      "Merta City",
      "Mundwa",
      "Nagaur",
      "Nawa",
      "Parbatsar",
      "Riyan Bari",
      "Sanjoo",
    ],
    "Neem Ka Thana": [],
    Pali: [
      "Bali",
      "Desuri",
      "Jaitaran",
      "Marwar Junction",
      "Pali",
      "Rohat",
      "Sojat",
      "Sumerpur",
    ],
    Phalodi: [],
    Pratapgarh: ["Arnod", "Chhoti Sadri", "Dhariawad", "Pratapgarh"],
    Rajsamand: [
      "Amet",
      "Bhim",
      "Deogarh",
      "Kumbhalgarh",
      "Nathdwara",
      "Rajsamand",
    ],
    Salumbar: [],
    Sanchore: [],
    "Sawai Madhopur": [
      "Bonli",
      "Bamanwas",
      "Chauth Ka Barawara",
      "Khandar",
      "Malarna Dungar",
      "Mitrpura",
      "Sawai Madhopur",
      "Wazirpur",
    ],
    Shahpura: [],
    Sikar: [
      "Danta Ramgarh",
      "Fatehpur",
      "Lachhmangarh",
      "Sikar",
      "Sri Madhopur",
    ],
    Sirohi: ["Abu Road", "Mount Abu", "Pindwara", "Sirohi"],
    "Sri Ganganagar": [
      "Gajsinghpur",
      "Karani",
      "Padampur",
      "Raisinghnagar",
      "Rawla",
      "Sadulshahar",
      "Shri Karanpur",
      "Sri Ganganagar",
      "Suratgarh",
      "Vijaynagar",
    ],
    Tonk: [
      "Deoli",
      "Duni",
      "Malpura",
      "Niwai",
      "Peeplu",
      "Todaraisingh",
      "Tonk",
      "Uniara",
    ],
    Udaipur: [
      "Girwa",
      "Gogunda",
      "Jhadol",
      "Kherwara",
      "Kotra",
      "Mavli",
      "Udaipur",
    ],
  };

  const states = [
    "Andaman and Nicobar Islands",
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chandigarh",
    "Chhattisgarh",
    "Dadra and Nagar Haveli",
    "Daman and Diu",
    "Delhi",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Lakshadweep",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Puducherry",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
  ];

  // Replace these with your own email and API token
  const userEmail = process.env.REACT_APP_MAP_API_EMAIL;
  const apiToken = process.env.REACT_APP_MAP_API_TOKEN;

  useEffect(() => {
    // Get the authorization token
    fetch("https://www.universal-tutorial.com/api/getaccesstoken", {
      headers: {
        Accept: "application/json",
        "api-token": apiToken,
        "user-email": userEmail,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // Store the authorization token in local storage
        localStorage.setItem("authToken", data.auth_token);
      })
      .catch((error) => {
        console.error("Error fetching authorization token:", error);
      });
  }, []);

  useEffect(() => {
    // Get all countries
    fetch("https://www.universal-tutorial.com/api/countries", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setCountry(data);
      })
      .catch((error) => {
        console.error("Error fetching countries:", error);
      });
  }, []);

  useEffect(() => {
    // Get states by country
    if (selectedCountry) {
      fetch(
        `https://www.universal-tutorial.com/api/states/${selectedCountry}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Accept: "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setState(data);
        })
        .catch((error) => {
          console.error("Error fetching states:", error);
        });
    }
  }, [selectedCountry]);

  useEffect(() => {
    // Get cities by state
    if (selectedState) {
      fetch(`https://www.universal-tutorial.com/api/cities/${selectedState}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Accept: "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setCity(data);
        })
        .catch((error) => {
          console.error("Error fetching cities:", error);
        });
    }
  }, [selectedState]);

  const handleDistrictChange = (e) => {
    setSelectedDistrict(e.target.value);
    setSelectedTehsil("");
  };

  const handleTehsilChange = (e) => {
    setSelectedTehsil(e.target.value);
  };

  const handleRadioClick = () => {
    setSelectedCountry("");
    setSelectedState("");
    setSelectedCity("");
  };

  return (
    <Box
      display={{ base: "block", md: "flex" }}
      justifyContent="space-between"
      alignContent="center"
      alignItems="center"
      w={{ base: "auto", md: "90%" }}
      borderRadius="lg"
      p={{ base: 4, md: 6 }}
      bg="white"
      borderWidth="1px"
      borderColor="gray.300"
      ml={{ base: 4, md: "auto" }}
      mr={{ base: 4, md: "auto" }}
      mt={{ base: 4, md: 8 }}
      mb={{ base: 4, md: 8 }}
      max-width="1320px"
      h="fit-content"
    >
      <VStack w="100%" align="stretch">
        {useBreakpointValue({
          base: (
            <>
              <Text fontFamily="Montserrat" align="center">
                Last Modified : {lastModified}
              </Text>
              <Text align="center" mb={4} fontFamily="Montserrat">
                Registered : {resgistered}
              </Text>
            </>
          ),
          md: (
            <HStack
              mb={6}
              flexWrap={{ base: "wrap", md: "nowrap" }}
              spacing={16}
              justify="space-evenly"
            >
              <Text fontFamily="Montserrat">
                Last Modified : {lastModified}
              </Text>
              <Text fontFamily="Montserrat">Registered : {resgistered}</Text>
            </HStack>
          ),
        })}

        {useBreakpointValue({
          base: (
            <>
              <FormControl
                id="fullname"
                isRequired
                w={{ base: "100%", md: "50%" }}
                isInvalid={!fullName && isSubmitted}
                isDisabled
              >
                <FormLabel fontWeight="extrabold" fontFamily="Montserrat">
                  Full Name
                </FormLabel>
                <Input
                  placeholder="Full Name"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  className={fullName ? "valid-input" : ""}
                  ref={fullNameRef}
                />
                {/* {!fullName && (
                  <FormErrorMessage>{formErrors.fullName}</FormErrorMessage>
                )} */}
              </FormControl>
              <FormControl
                id="relation"
                isRequired
                w={{ base: "100%", md: "50%" }}
                isInvalid={!relation && isSubmitted}
                isDisabled
              >
                <FormLabel fontWeight="extrabold" fontFamily="Montserrat">
                  S/o, W/o, D/o
                </FormLabel>
                <Input
                  placeholder="S/o, W/o, D/o Name"
                  value={relation}
                  onChange={(e) => setRelation(e.target.value)}
                  className={relation ? "valid-input" : ""}
                  ref={relationRef}
                />
                {/* {!relation && (
                  <FormErrorMessage>{formErrors.relation}</FormErrorMessage>
                )} */}
              </FormControl>
            </>
          ),
          md: (
            <HStack flexWrap={{ base: "wrap", md: "nowrap" }} spacing={4}>
              <FormControl
                id="fullname"
                isRequired
                w={{ base: "100%", md: "50%" }}
                isInvalid={!fullName && isSubmitted}
                isDisabled
              >
                <FormLabel fontWeight="extrabold" fontFamily="Montserrat">
                  Full Name
                </FormLabel>
                <Input
                  placeholder="Full Name"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  className={fullName ? "valid-input" : ""}
                  ref={fullNameRef}
                />
                {/* {!fullName && (
                  <FormErrorMessage>{formErrors.fullName}</FormErrorMessage>
                )} */}
              </FormControl>
              <FormControl
                id="relation"
                isRequired
                w={{ base: "100%", md: "50%" }}
                isInvalid={!relation && isSubmitted}
                isDisabled
              >
                <FormLabel fontWeight="extrabold" fontFamily="Montserrat">
                  S/o, W/o, D/o
                </FormLabel>
                <Input
                  placeholder="S/o, W/o, D/o Name"
                  value={relation}
                  onChange={(e) => setRelation(e.target.value)}
                  className={relation ? "valid-input" : ""}
                  ref={relationRef}
                />
                {/* {!relation && (
                  <FormErrorMessage>{formErrors.relation}</FormErrorMessage>
                )} */}
              </FormControl>
            </HStack>
          ),
        })}

        {useBreakpointValue({
          base: (
            <>
              <FormControl
                id="email"
                isRequired
                w={{ base: "100%", md: "50%" }}
                isInvalid={(!email && isSubmitted) || !emailValid}
                isDisabled
              >
                <FormLabel fontWeight="extrabold" fontFamily="Montserrat">
                  Email
                </FormLabel>
                <Input
                  type="email"
                  value={email}
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                  className={email ? "valid-input" : ""}
                  ref={emailRef}
                />
                {/* {!email && (
                  <FormErrorMessage>{formErrors.email}</FormErrorMessage>
                )} */}
              </FormControl>
              <FormControl
                id="mobilenumber"
                isRequired
                w={{ base: "100%", md: "50%" }}
                isInvalid={(!mobileNumber && isSubmitted) || !phoneValid}
                isDisabled
              >
                <FormLabel fontWeight="extrabold" fontFamily="Montserrat">
                  Mobile Number
                </FormLabel>
                <Input
                  type="tel"
                  value={mobileNumber}
                  placeholder="Mobile Number"
                  onChange={(e) => setMobileNumber(e.target.value)}
                  className={mobileNumber ? "valid-input" : ""}
                  autoComplete="off"
                  ref={mobileNumberRef}
                />
                {/* {!mobileNumber && (
                  <FormErrorMessage>{formErrors.mobileNumber}</FormErrorMessage>
                )} */}
              </FormControl>
            </>
          ),
          md: (
            <HStack flexWrap={{ base: "wrap", md: "nowrap" }} spacing={4}>
              <FormControl
                id="email"
                isRequired
                w={{ base: "100%", md: "50%" }}
                isInvalid={(!email && isSubmitted) || !emailValid}
                isDisabled
              >
                <FormLabel fontWeight="extrabold" fontFamily="Montserrat">
                  Email
                </FormLabel>
                <Input
                  type="email"
                  value={email}
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                  className={email ? "valid-input" : ""}
                  ref={emailRef}
                />
                {/* {!email && (
                  <FormErrorMessage>{formErrors.email}</FormErrorMessage>
                )} */}
              </FormControl>
              <FormControl
                id="mobilenumber"
                isRequired
                w={{ base: "100%", md: "50%" }}
                isInvalid={(!mobileNumber && isSubmitted) || !phoneValid}
                isDisabled
              >
                <FormLabel fontWeight="extrabold" fontFamily="Montserrat">
                  Mobile Number
                </FormLabel>
                <Input
                  type="tel"
                  value={mobileNumber}
                  placeholder="Mobile Number"
                  onChange={(e) => setMobileNumber(e.target.value)}
                  className={mobileNumber ? "valid-input" : ""}
                  autoComplete="off"
                  ref={mobileNumberRef}
                />
                {/* {!mobileNumber && (
                  <FormErrorMessage>{formErrors.mobileNumber}</FormErrorMessage>
                )} */}
              </FormControl>
            </HStack>
          ),
        })}

        <FormControl
          id="location"
          isRequired
          w="100%"
          isInvalid={!location && isSubmitted}
          isDisabled
        >
          <FormLabel fontWeight="extrabold" fontFamily="Montserrat">
            Current Address
          </FormLabel>
          <RadioGroup
            value={location}
            onChange={(value) => setLocation(value)}
            onClick={handleRadioClick}
            className={location ? "valid-input" : ""}
          >
            <HStack spacing="24px">
              <Radio value="India" size="lg">
                India
              </Radio>
              <Radio value="Abroad" size="lg">
                Abroad
              </Radio>
            </HStack>
          </RadioGroup>
          {/* {!location && (
            <FormErrorMessage>{formErrors.location}</FormErrorMessage>
          )} */}
        </FormControl>

        {useBreakpointValue({
          base: (
            <>
              {location === "India" && (
                <>
                  <FormControl
                    id="address"
                    isRequired
                    w={{ base: "100%", md: "50%" }}
                    isInvalid={!address && isSubmitted}
                    isDisabled
                  >
                    <FormLabel fontWeight="extrabold" fontFamily="Montserrat">
                      Address
                    </FormLabel>
                    <Input
                      type="text"
                      placeholder="Current Address"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      className={address ? "valid-input" : ""}
                    />
                    {/* {!address && (
                      <FormErrorMessage>{formErrors.address}</FormErrorMessage>
                    )} */}
                  </FormControl>

                  <FormControl
                    id="state"
                    isRequired
                    w={{ base: "100%", md: "25%" }}
                    isInvalid={!selectedState && isSubmitted}
                    isDisabled
                  >
                    <FormLabel fontWeight="extrabold" fontFamily="Montserrat">
                      State
                    </FormLabel>
                    <Select
                      value={selectedState}
                      onChange={(e) => setSelectedState(e.target.value)}
                      className={selectedState ? "valid-input" : ""}
                    >
                      <option value="">-- Select State --</option>
                      {Object.keys(districtArray).map((state) => (
                        <option key={state} value={state}>
                          {state}
                        </option>
                      ))}
                    </Select>
                    {/* {!selectedState && (
                      <FormErrorMessage>
                        {formErrors.selectedState}
                      </FormErrorMessage>
                    )} */}
                  </FormControl>

                  {selectedState && (
                    <FormControl
                      id="city"
                      isRequired
                      w={{ base: "100%", md: "25%" }}
                      isInvalid={!selectedCity && isSubmitted}
                      isDisabled
                    >
                      <FormLabel fontWeight="extrabold" fontFamily="Montserrat">
                        District
                      </FormLabel>
                      <Select
                        value={selectedCity}
                        onChange={(e) => setSelectedCity(e.target.value)}
                        className={selectedCity ? "valid-input" : ""}
                      >
                        <option value="">-- Select District --</option>
                        {districtArray[selectedState].map((district) => (
                          <option key={district} value={district}>
                            {district}
                          </option>
                        ))}
                      </Select>
                      {/* {!selectedCity && (
                        <FormErrorMessage>
                          {formErrors.selectedCity}
                        </FormErrorMessage>
                      )} */}
                    </FormControl>
                  )}
                </>
              )}

              {location === "Abroad" && (
                <>
                  <FormControl
                    id="address"
                    isRequired
                    w={{ base: "100%", md: "50%" }}
                    isInvalid={!address && isSubmitted}
                    isDisabled
                  >
                    <FormLabel fontWeight="extrabold" fontFamily="Montserrat">
                      Address
                    </FormLabel>
                    <Input
                      type="text"
                      value={address}
                      placeholder="Current Address"
                      onChange={(e) => setAddress(e.target.value)}
                      className={address ? "valid-input" : ""}
                    />
                    {/* {!address && (
                      <FormErrorMessage>{formErrors.address}</FormErrorMessage>
                    )} */}
                  </FormControl>
                  <FormControl
                    id="country"
                    isRequired
                    w={{ base: "100%", md: "25%" }}
                    isInvalid={!selectedCountry && isSubmitted}
                    isDisabled
                  >
                    <FormLabel fontWeight="extrabold" fontFamily="Montserrat">
                      Country
                    </FormLabel>
                    <Select
                      value={selectedCountry}
                      onChange={(e) => setSelectedCountry(e.target.value)}
                      className={selectedCountry ? "valid-input" : ""}
                    >
                      <option value="">-- Select Country --</option>
                      {country.map((c) => (
                        <option
                          key={c.country_short_name}
                          value={c.country_name}
                        >
                          {c.country_name}
                        </option>
                      ))}
                    </Select>
                    {/* <FormErrorMessage>
                      {formErrors.selectedCountry}
                    </FormErrorMessage> */}
                  </FormControl>
                  {selectedCountry && (
                    <FormControl
                      id="state"
                      isRequired
                      w={{ base: "100%", md: "25%" }}
                      isInvalid={!selectedState && isSubmitted}
                      isDisabled
                    >
                      <FormLabel fontWeight="extrabold" fontFamily="Montserrat">
                        State
                      </FormLabel>
                      <Select
                        value={selectedState}
                        onChange={(e) => setSelectedState(e.target.value)}
                        className={selectedState ? "valid-input" : ""}
                      >
                        <option value="">-- Select State --</option>
                        {state.map((s) => (
                          <option key={s.state_name} value={s.state_name}>
                            {s.state_name}
                          </option>
                        ))}
                      </Select>
                      {/* {!selectedState && (
                        <FormErrorMessage>
                          {formErrors.selectedState}
                        </FormErrorMessage>
                      )} */}
                    </FormControl>
                  )}
                  {selectedState && (
                    <FormControl
                      id="city"
                      isRequired
                      w={{ base: "100%", md: "25%" }}
                      isInvalid={!selectedCity && isSubmitted}
                      isDisabled
                    >
                      <FormLabel fontWeight="extrabold" fontFamily="Montserrat">
                        City
                      </FormLabel>
                      <Select
                        value={selectedCity}
                        onChange={(e) => setSelectedCity(e.target.value)}
                        className={selectedCity ? "valid-input" : ""}
                      >
                        <option value="">-- Select City --</option>
                        {city.map((ci) => (
                          <option key={ci.city_name} value={ci.city_name}>
                            {ci.city_name}
                          </option>
                        ))}
                      </Select>
                      {/* {!selectedCity && (
                        <FormErrorMessage>
                          {formErrors.selectedCity}
                        </FormErrorMessage>
                      )} */}
                    </FormControl>
                  )}
                </>
              )}
            </>
          ),
          md: (
            <>
              {location === "India" && (
                <HStack flexWrap={{ base: "wrap", md: "nowrap" }} spacing={4}>
                  <FormControl
                    id="address"
                    isRequired
                    w={{ base: "100%", md: "50%" }}
                    isInvalid={!address && isSubmitted}
                    isDisabled
                  >
                    <FormLabel fontWeight="extrabold" fontFamily="Montserrat">
                      Address
                    </FormLabel>
                    <Input
                      type="text"
                      placeholder="Current Address"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      className={address ? "valid-input" : ""}
                    />
                    {/* {!address && (
                      <FormErrorMessage>{formErrors.address}</FormErrorMessage>
                    )} */}
                  </FormControl>

                  <FormControl
                    id="state"
                    isRequired
                    w={{ base: "100%", md: "25%" }}
                    isInvalid={!selectedState && isSubmitted}
                    isDisabled
                  >
                    <FormLabel fontWeight="extrabold" fontFamily="Montserrat">
                      State
                    </FormLabel>
                    <Select
                      value={selectedState}
                      onChange={(e) => setSelectedState(e.target.value)}
                      className={selectedState ? "valid-input" : ""}
                    >
                      <option value="">-- Select State --</option>
                      {Object.keys(districtArray).map((state) => (
                        <option key={state} value={state}>
                          {state}
                        </option>
                      ))}
                    </Select>
                    {/* {!selectedState && (
                      <FormErrorMessage>
                        {formErrors.selectedState}
                      </FormErrorMessage>
                    )} */}
                  </FormControl>

                  {selectedState && (
                    <FormControl
                      id="city"
                      isRequired
                      w={{ base: "100%", md: "25%" }}
                      isInvalid={!selectedCity && isSubmitted}
                      isDisabled
                    >
                      <FormLabel fontWeight="extrabold" fontFamily="Montserrat">
                        District
                      </FormLabel>
                      <Select
                        value={selectedCity}
                        onChange={(e) => setSelectedCity(e.target.value)}
                        className={selectedCity ? "valid-input" : ""}
                      >
                        <option value="">-- Select District --</option>
                        {districtArray[selectedState].map((district) => (
                          <option key={district} value={district}>
                            {district}
                          </option>
                        ))}
                      </Select>
                      {/* {!selectedCity && (
                        <FormErrorMessage>
                          {formErrors.selectedCity}
                        </FormErrorMessage>
                      )} */}
                    </FormControl>
                  )}
                </HStack>
              )}

              {location === "Abroad" && (
                <HStack flexWrap={{ base: "wrap", md: "nowrap" }} spacing={4}>
                  <FormControl
                    id="address"
                    isRequired
                    w={{ base: "100%", md: "50%" }}
                    isInvalid={!address && isSubmitted}
                    isDisabled
                  >
                    <FormLabel fontWeight="extrabold" fontFamily="Montserrat">
                      Address
                    </FormLabel>
                    <Input
                      type="text"
                      value={address}
                      placeholder="Current Address"
                      onChange={(e) => setAddress(e.target.value)}
                      className={address ? "valid-input" : ""}
                    />
                    {/* {!address && (
                      <FormErrorMessage>{formErrors.address}</FormErrorMessage>
                    )} */}
                  </FormControl>
                  <FormControl
                    id="country"
                    isRequired
                    w={{ base: "100%", md: "25%" }}
                    isInvalid={!selectedCountry && isSubmitted}
                    isDisabled
                  >
                    <FormLabel fontWeight="extrabold" fontFamily="Montserrat">
                      Country
                    </FormLabel>
                    <Select
                      value={selectedCountry}
                      onChange={(e) => setSelectedCountry(e.target.value)}
                      className={selectedCountry ? "valid-input" : ""}
                    >
                      <option value="">-- Select Country --</option>
                      {country.map((c) => (
                        <option
                          key={c.country_short_name}
                          value={c.country_name}
                        >
                          {c.country_name}
                        </option>
                      ))}
                    </Select>
                    {/* <FormErrorMessage>
                      {formErrors.selectedCountry}
                    </FormErrorMessage> */}
                  </FormControl>
                  {selectedCountry && (
                    <FormControl
                      id="state"
                      isRequired
                      w={{ base: "100%", md: "25%" }}
                      isInvalid={!selectedState && isSubmitted}
                      isDisabled
                    >
                      <FormLabel fontWeight="extrabold" fontFamily="Montserrat">
                        State
                      </FormLabel>
                      <Select
                        value={selectedState}
                        onChange={(e) => setSelectedState(e.target.value)}
                        className={selectedState ? "valid-input" : ""}
                      >
                        <option value="">-- Select State --</option>
                        {state.map((s) => (
                          <option key={s.state_name} value={s.state_name}>
                            {s.state_name}
                          </option>
                        ))}
                      </Select>
                      {/* {!selectedState && (
                        <FormErrorMessage>
                          {formErrors.selectedState}
                        </FormErrorMessage>
                      )} */}
                    </FormControl>
                  )}
                  {selectedState && (
                    <FormControl
                      id="city"
                      isRequired
                      w={{ base: "100%", md: "25%" }}
                      isInvalid={!selectedCity && isSubmitted}
                      isDisabled
                    >
                      <FormLabel fontWeight="extrabold" fontFamily="Montserrat">
                        City
                      </FormLabel>
                      <Select
                        value={selectedCity}
                        onChange={(e) => setSelectedCity(e.target.value)}
                        className={selectedCity ? "valid-input" : ""}
                      >
                        <option value="">-- Select City --</option>
                        {city.map((ci) => (
                          <option key={ci.city_name} value={ci.city_name}>
                            {ci.city_name}
                          </option>
                        ))}
                      </Select>
                      {/* {!selectedCity && (
                        <FormErrorMessage>
                          {formErrors.selectedCity}
                        </FormErrorMessage>
                      )} */}
                    </FormControl>
                  )}
                </HStack>
              )}
            </>
          ),
        })}

        <HStack flexWrap={{ base: "wrap", md: "nowrap" }} spacing={4}>
          <Text fontWeight="extrabold" color="gray.300" mt="16px">
            NATIVE ADDRESS IN RAJASTHAN
          </Text>
        </HStack>

        {useBreakpointValue({
          base: (
            <>
              <FormControl
                w={{ base: "100%", md: "48%" }}
                isRequired
                isInvalid={!selectedDistrict && isSubmitted}
                isDisabled
              >
                <FormLabel fontWeight="extrabold" fontFamily="Montserrat">
                  District
                </FormLabel>
                <Select
                  value={selectedDistrict}
                  onChange={handleDistrictChange}
                  className={selectedDistrict ? "valid-input" : ""}
                >
                  <option value="">-- Select District --</option>
                  {Object.keys(tehsils).map((district) => (
                    <option key={district} value={district}>
                      {district}
                    </option>
                  ))}
                </Select>
                {/* {!selectedDistrict && (
                  <FormErrorMessage>
                    {formErrors.selectedDistrict}
                  </FormErrorMessage>
                )} */}
              </FormControl>

              {selectedDistrict && (
                <FormControl w={{ base: "100%", md: "48%" }} isDisabled>
                  <FormLabel fontWeight="extrabold" fontFamily="Montserrat">
                    Tehsil
                  </FormLabel>
                  <Select
                    value={selectedTehsil}
                    onChange={handleTehsilChange}
                    className={selectedTehsil ? "valid-input" : ""}
                  >
                    <option value="">-- Select Tehsil --</option>
                    {tehsils[selectedDistrict].map((tehsil) => (
                      <option key={tehsil} value={tehsil}>
                        {tehsil}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              )}
              {selectedDistrict && (
                <FormControl
                  id="village"
                  w={{ base: "100%", md: "25%" }}
                  isDisabled
                >
                  <FormLabel fontWeight="extrabold" fontFamily="Montserrat">
                    Village
                  </FormLabel>
                  <Input
                    placeholder="Village"
                    value={village}
                    onChange={(e) => setVillage(e.target.value)}
                    type="text"
                    className={village ? "valid-input" : ""}
                  />
                </FormControl>
              )}
            </>
          ),
          md: (
            <HStack flexWrap={{ base: "wrap", md: "nowrap" }} spacing={4}>
              <FormControl
                w={{ base: "100%", md: "48%" }}
                isRequired
                isInvalid={!selectedDistrict && isSubmitted}
                isDisabled
              >
                <FormLabel fontWeight="extrabold" fontFamily="Montserrat">
                  District
                </FormLabel>
                <Select
                  value={selectedDistrict}
                  onChange={handleDistrictChange}
                  className={selectedDistrict ? "valid-input" : ""}
                >
                  <option value="">-- Select District --</option>
                  {Object.keys(tehsils).map((district) => (
                    <option key={district} value={district}>
                      {district}
                    </option>
                  ))}
                </Select>
                {/* {!selectedDistrict && (
                  <FormErrorMessage>
                    {formErrors.selectedDistrict}
                  </FormErrorMessage>
                )} */}
              </FormControl>

              {selectedDistrict && (
                <FormControl w={{ base: "100%", md: "48%" }} isDisabled>
                  <FormLabel fontWeight="extrabold" fontFamily="Montserrat">
                    Tehsil
                  </FormLabel>
                  <Select
                    value={selectedTehsil}
                    onChange={handleTehsilChange}
                    className={selectedTehsil ? "valid-input" : ""}
                  >
                    <option value="">-- Select Tehsil --</option>
                    {tehsils[selectedDistrict].map((tehsil) => (
                      <option key={tehsil} value={tehsil}>
                        {tehsil}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              )}
              {selectedDistrict && (
                <FormControl
                  id="village"
                  w={{ base: "100%", md: "25%" }}
                  isDisabled
                >
                  <FormLabel fontWeight="extrabold" fontFamily="Montserrat">
                    Village
                  </FormLabel>
                  <Input
                    placeholder="Village"
                    value={village}
                    onChange={(e) => setVillage(e.target.value)}
                    type="text"
                    className={village ? "valid-input" : ""}
                  />
                </FormControl>
              )}
            </HStack>
          ),
        })}

        <FormControl
          id="occupation"
          isRequired
          w={{ base: "100%", md: "40%" }}
          isInvalid={!occupation && isSubmitted}
          isDisabled
        >
          <FormLabel
            fontSize="lg"
            fontWeight="extrabold"
            fontFamily="Montserrat"
          >
            Occupation
          </FormLabel>
          <Input
            type="text"
            placeholder="Occupation"
            value={occupation}
            onChange={(e) => setOccupation(e.target.value)}
            className={occupation ? "invalid-input" : ""}
            bg="gray.100"
            size="lg"
            ref={occupationRef}
            autoComplete="off"
          />
          {/* {!occupation && (
            <FormErrorMessage>{formErrors.occupation}</FormErrorMessage>
          )} */}
        </FormControl>

        <HStack>
          <Checkbox
            isRequired
            defaultChecked
            isChecked
            fontSize="lg"
            fontWeight="extrabold"
            fontFamily="Montserrat"
            size="lg"
            mt="12px"
            mb="8px"
            name="checkbox"
            color="gray.300"
          >
            By submitting this form, I consent to Founder General Secretary of
            <i> MARWADI INTERNATIONAL FEDERATION (MIF)</i> for using my name and
            details for records of MIF and its office bearers list. I have read
            and understood all the terms and conditions of MIF.
          </Checkbox>
        </HStack>

        <FormControl
          id="name"
          isRequired
          w={{ base: "100%", md: "50%" }}
          isInvalid={!name && isSubmitted}
          isDisabled
        >
          <Flex align="center">
            <FormLabel
              fontSize="lg"
              fontWeight="extrabold"
              fontFamily="Montserrat"
            >
              Name
            </FormLabel>
            <Input
              type="text"
              placeholder="Your Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className={name ? "invalid-input" : ""}
              size="lg"
              mb="16px"
              ref={nameRef}
              autoComplete="off"
            />
          </Flex>
          {/* {!name && <FormErrorMessage>{formErrors.name}</FormErrorMessage>} */}
        </FormControl>

        {useBreakpointValue({
          base: (
            <>
              <VStack justify="space-between">
                <FormControl id="map" w="100%" isRequired>
                  <HStack
                    spacing="24px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexWrap="wrap"
                    mt={4}
                  >
                    <FormLabel
                      fontWeight="extrabold"
                      fontFamily="Montserrat"
                      mt={2}
                    >
                      Choose the option where you want to add image :
                    </FormLabel>
                    <RadioGroup
                      value={selectedMap}
                      onChange={(value) => setSelectedMap(value)}
                    >
                      <VStack justify="flex-start">
                        {location === "India" &&
                          selectedState !== "Rajasthan" && (
                          <Radio value="India" size="lg" alignContent="center">
                            India
                          </Radio>
                        )}
                        {location === "Abroad" && (
                          <Radio value="Abroad" size="lg">
                            Abroad
                          </Radio>
                        )}
                        {location === "India" &&
                          selectedState === "Rajasthan" && (
                            <Radio value="Rajasthan" size="lg">
                              Rajasthan
                            </Radio>
                          )}
                      </VStack>
                    </RadioGroup>
                  </HStack>
                </FormControl>

                <FormControl id="pic" w="100%">
                  <FormLabel>Upload your Picture</FormLabel>
                  <Input
                    type="file"
                    p={1}
                    accept="image/*"
                    onChange={(e) => postDetails(e.target.files[0])}
                  />
                </FormControl>

                <Button
                  type="submit"
                  colorScheme="green"
                  size="lg"
                  isLoading={picLoading}
                  mt={6}
                  mb={-2}
                  onClick={submitHandler}
                  fontFamily="Montserrat"
                  alignSelf="flex-start"
                >
                  Upload Image
                </Button>
              </VStack>
            </>
          ),
          md: (
            <>
              <FormControl id="map" w="100%" isRequired>
                <HStack
                  spacing="24px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  flexWrap="wrap"
                  mt={4}
                >
                  <FormLabel
                    fontWeight="extrabold"
                    fontFamily="Montserrat"
                    mt={2}
                  >
                    Choose the option where you want to add image :
                  </FormLabel>
                  <RadioGroup
                    value={selectedMap}
                    onChange={(value) => setSelectedMap(value)}
                  >
                    <VStack justify="flex-start">
                      {location === "India" && 
                        selectedState !== "Rajasthan" && (
                        <Radio value="India" size="lg" alignContent="center">
                          India
                        </Radio>
                      )}
                      {location === "Abroad" && (
                        <Radio value="Abroad" size="lg">
                          Abroad
                        </Radio>
                      )}
                      {location === "India" &&
                        selectedState === "Rajasthan" && (
                          <Radio value="Rajasthan" size="lg">
                            Rajasthan
                          </Radio>
                        )}
                    </VStack>
                  </RadioGroup>
                </HStack>
              </FormControl>

              <HStack justify="space-between">
                <FormControl id="pic" w="60%">
                  <FormLabel>Upload your Picture</FormLabel>
                  <Input
                    type="file"
                    p={1}
                    accept="image/*"
                    onChange={(e) => postDetails(e.target.files[0])}
                  />
                </FormControl>

                <Button
                  type="submit"
                  colorScheme="green"
                  size="lg"
                  isLoading={picLoading}
                  mt={6}
                  mb={-2}
                  onClick={submitHandler}
                  fontFamily="Montserrat"
                  alignSelf="flex-end"
                >
                  Upload Image
                </Button>
              </HStack>
            </>
          ),
        })}
      </VStack>
    </Box>
  );
};

export default UploadImageForm;
