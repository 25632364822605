import React from "react";
import ReactDOM from 'react-dom/client'
// import ReactDOM from "react-dom";
import "./index.css";
import { Route, Routes } from "react-router-dom";
import App from "./App";
import { Box, ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import Provider from "./provider";
import NavbarRegister from "./headerRegister";
import NavbarRaj from "./headerRaj";
import NavbarIndia from "./headerIndia";
import NewNavbar from "./NewNavbar";
import NavbarAdvanceSearch from "./headerAdvanceSearch";
import AdvanceSearch from "./advanceSearch";
import India from "./indiaUser";
import Rajasthan from "./rajasthanUser";
import RegistrationFormUser from "./registerFormUser";
import Footer from "./footerUser";
import NewFooter from "./NewFooter"
import World from "./worldUser";
import NavbarWorld from "./headerWorld";

const RedirectToExternalSite = () => {
  window.location.href = "http://marwadiinternationalfederation.com/";
  return null;
};
// ReactDOM.render(
//   <ChakraProvider>
//     <BrowserRouter>
//       <Routes>
//         {/* <Route path="/" element={<RedirectToExternalSite />} /> */}
//         <Route
//           path="/india-network"
//           element={
//             <div id="root">
//               <div className="content">
//                 <NavbarIndia />
//                 <India />
//               </div>
//               <footer>
//                 <Footer />
//               </footer>
//             </div>
//           }
//         />
//         <Route
//           path="/rajasthan-network"
//           element={
//             <div id="root">
//               <div className="content">
//                 <NavbarRaj />
//                 <Rajasthan />
//               </div>
//               <footer>
//                 <Footer />
//               </footer>
//             </div>
//           }
//         />
//         <Route
//           path="/abroad-network"
//           element={
//             <div id="root">
//               <div className="content">
//                 <NavbarWorld />
//                 <World />
//               </div>
//               <footer>
//                 <Footer />
//               </footer>
//             </div>
//           }
//         />
//         <Route
//           path="/form"
//           element={
//             <div>
//               <NavbarRegister />
//               <RegistrationFormUser />
//               <Footer />
//             </div>
//           }
//         />
//         <Route
//           path="/advance-search"
//           element={
//             <div id="root">
//               <div className="content">
//                 <NavbarAdvanceSearch />
//                 <AdvanceSearch />
//               </div>
//               <footer>
//                 <Footer />
//               </footer>
//             </div>
//           }
//         />
//         <Route
//           path="/*"
//           element={
//             <Provider>
//               <App />
//             </Provider>
//           }
//         />
//       </Routes>
//     </BrowserRouter>
//   </ChakraProvider>,
//   document.getElementById("root")
// );

ReactDOM.createRoot(document.getElementById('root')).render(
  <ChakraProvider>
  <BrowserRouter>
    <Routes>
      {/* <Route path="/" element={<RedirectToExternalSite />} /> */}
      <Route
        path="/india-network"
        element={
          <div id="root">
            <div className="content">
              <NewNavbar />
              <India />
            </div>
            <footer>
              <NewFooter />
            </footer>
          </div>
        }
      />
      <Route
        path="/rajasthan-network"
        element={
          <div id="root">
            <div className="content">
              <NewNavbar />
              <Rajasthan />
            </div>
            <footer>
              <NewFooter />
            </footer>
          </div>
        }
      />
      <Route
        path="/abroad-network"
        element={
          <div id="root">
            <div className="content">
              <NewNavbar />
              <World />
            </div>
            <footer>
              <NewFooter />
            </footer>
          </div>
        }
      />
      <Route
        path="/form"
        element={
          <div>
            <NewNavbar />
            <RegistrationFormUser />
            <NewFooter />
          </div>
        }
      />
      <Route
        path="/advance-search"
        element={
          <div id="root">
            <div className="content">
              <NewNavbar />
              <AdvanceSearch />
            </div>
            <footer>
              <NewFooter />
            </footer>
          </div>
        }
      />
      <Route
        path="/*"
        element={
          <Provider>
            <App />
          </Provider>
        }
      />
    </Routes>
  </BrowserRouter>
</ChakraProvider>
)
