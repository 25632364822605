import React, { useEffect, useRef, useState } from "react";
import AWS from "aws-sdk";
import axios from "axios";
import "./App.css";
import {
  VStack,
  HStack,
  FormControl,
  FormLabel,
  Input,
  Select,
  Text,
  Checkbox,
  Button,
  RadioGroup,
  Radio,
  useBreakpointValue,
  Box,
  Flex,
  useToast,
  Image,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  Modal,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const RegistrationForm = () => {
  useEffect(() => {
    document.title = "Register · Marwadi International Federation";
  }, []);
  const handlePhoneNumberChange = (value) => {
    setMobileNumber(value);
  };
  const [isMobileNumberFocused, setMobileNumberFocused] = useState(false);
  const handleMobileNumberFocus = () => {
    setMobileNumberFocused(true);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        mobileNumberRef.current &&
        !mobileNumberRef.current.contains(event.target)
      ) {
        setMobileNumberFocused(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const [fullName, setFullName] = useState("");
  const [relation, setRelation] = useState();
  const [email, setEmail] = useState();
  const [mobileNumber, setMobileNumber] = useState();
  const [location, setLocation] = useState();
  const [address, setAddress] = useState();

  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedState, setSelectedState] = useState();
  const [selectedCity, setSelectedCity] = useState();

  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedTehsil, setSelectedTehsil] = useState("");
  const [village, setVillage] = useState("");
  const [occupation, setOccupation] = useState();
  const [name, setName] = useState();
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [emailValid, setEmailValid] = useState(true);
  const [phoneValid, setPhoneValid] = useState(true);
  const emailRef = useRef(null);
  const fullNameRef = useRef(null);
  const relationRef = useRef(null);
  const mobileNumberRef = useRef(null);
  const occupationRef = useRef(null);
  const nameRef = useRef(null);
  const firstInvalidFieldRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [reference, setReference] = useState("");
  const navigate = useNavigate();
  const toast = useToast();
  const [isChecked, setIsChecked] = useState(true);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  var districtArray = {
    "Andhra Pradesh": [
      "Anantapur",
      "Chittoor",
      "East Godavari",
      "Guntur",
      "Krishna",
      "Kurnool",
      "Nellore",
      "Prakasam",
      "Srikakulam",
      "Visakhapatnam",
      "Vizianagaram",
      "West Godavari",
      "YSR Kadapa",
    ],
    "Arunachal Pradesh": [
      "Anjaw",
      "Changlang",
      "Dibang Valley",
      "East Kameng",
      "East Siang",
      "Kamle",
      "Kra Daadi",
      "Kurung Kumey",
      "Lepa Rada",
      "Lohit",
      "Longding",
      "Lower Dibang Valley",
      "Lower Siang",
      "Lower Subansiri",
      "Namsai",
      "Pakke Kessang",
      "Papum Pare",
      "Shi Yomi",
      "Siang",
      "Tawang",
      "Tirap",
      "Upper Siang",
      "Upper Subansiri",
      "West Kameng",
      "West Siang",
    ],
    Assam: [
      "Baksa",
      "Barpeta",
      "Biswanath",
      "Bongaigaon",
      "Cachar",
      "Charaideo",
      "Chirang",
      "Darrang",
      "Dhemaji",
      "Dhubri",
      "Dibrugarh",
      "Dima Hasao",
      "Goalpara",
      "Golaghat",
      "Hailakandi",
      "Hojai",
      "Jorhat",
      "Kamrup",
      "Kamrup Metropolitan",
      "Karbi Anglong",
      "Karimganj",
      "Kokrajhar",
      "Lakhimpur",
      "Majuli",
      "Morigaon",
      "Nagaon",
      "Nalbari",
      "Sivasagar",
      "Sonitpur",
      "South Salmara-Mankachar",
      "Tinsukia",
      "Udalguri",
      "West Karbi Anglong",
    ],
    Bihar: [
      "Araria",
      "Arwal",
      "Aurangabad",
      "Banka",
      "Begusarai",
      "Bhagalpur",
      "Bhojpur",
      "Buxar",
      "Darbhanga",
      "East Champaran (Motihari)",
      "Gaya",
      "Gopalganj",
      "Jamui",
      "Jehanabad",
      "Kaimur (Bhabua)",
      "Katihar",
      "Khagaria",
      "Kishanganj",
      "Lakhisarai",
      "Madhepura",
      "Madhubani",
      "Munger (Monghyr)",
      "Muzaffarpur",
      "Nalanda",
      "Nawada",
      "Patna",
      "Purnia (Purnea)",
      "Rohtas",
      "Saharsa",
      "Samastipur",
      "Saran",
      "Sheikhpura",
      "Sheohar",
      "Sitamarhi",
      "Siwan",
      "Supaul",
      "Vaishali",
      "West Champaran",
    ],
    Chhattisgarh: [
      "Balod",
      "Baloda Bazar",
      "Balrampur",
      "Bastar",
      "Bemetara",
      "Bijapur",
      "Bilaspur",
      "Dantewada (South Bastar)",
      "Dhamtari",
      "Durg",
      "Gariyaband",
      "Janjgir-Champa",
      "Jashpur",
      "Kabirdham (Kawardha)",
      "Kanker (North Bastar)",
      "Kondagaon",
      "Korba",
      "Korea (Koriya)",
      "Mahasamund",
      "Mungeli",
      "Narayanpur",
      "Raigarh",
      "Raipur",
      "Rajnandgaon",
      "Sukma",
      "Surajpur",
      "Surguja",
    ],
    Goa: ["North Goa", "South Goa"],
    Gujarat: [
      "Ahmedabad",
      "Amreli",
      "Anand",
      "Aravalli",
      "Banaskantha (Palanpur)",
      "Bharuch",
      "Bhavnagar",
      "Botad",
      "Chhota Udepur",
      "Dahod",
      "Dangs (Ahwa)",
      "Devbhoomi Dwarka",
      "Gandhinagar",
      "Gir Somnath",
      "Jamnagar",
      "Junagadh",
      "Kheda (Nadiad)",
      "Kutch",
      "Mahisagar",
      "Mehsana",
      "Morbi",
      "Narmada (Rajpipla)",
      "Navsari",
      "Panchmahal (Godhra)",
      "Patan",
      "Porbandar",
      "Rajkot",
      "Sabarkantha (Himmatnagar)",
      "Surat",
      "Surendranagar",
      "Tapi (Vyara)",
      "Vadodara",
      "Valsad",
    ],
    Haryana: [
      "Ambala",
      "Bhiwani",
      "Charkhi Dadri",
      "Faridabad",
      "Fatehabad",
      "Gurugram",
      "Hisar",
      "Jhajjar",
      "Jind",
      "Kaithal",
      "Karnal",
      "Kurukshetra",
      "Mahendragarh",
      "Nuh",
      "Palwal",
      "Panchkula",
      "Panipat",
      "Rewari",
      "Rohtak",
      "Sirsa",
      "Sonipat",
      "Yamunanagar",
    ],
    "Himachal Pradesh": [
      "Bilaspur",
      "Chamba",
      "Hamirpur",
      "Kangra",
      "Kinnaur",
      "Kullu",
      "Lahaul &amp; Spiti",
      "Mandi",
      "Shimla",
      "Sirmaur (Sirmour)",
      "Solan",
      "Una",
    ],
    "Jammu and Kashmir": [
      "Anantnag",
      "Bandipore",
      "Baramulla",
      "Budgam",
      "Doda",
      "Ganderbal",
      "Jammu",
      "Kathua",
      "Kishtwar",
      "Kulgam",
      "Kupwara",
      "Poonch",
      "Pulwama",
      "Rajauri",
      "Ramban",
      "Reasi",
      "Samba",
      "Shopian district",
      "Srinagar",
      "Udhampur",
    ],
    Jharkhand: [
      "Bokaro",
      "Chatra",
      "Deoghar",
      "Dhanbad",
      "Dumka",
      "East Singhbhum",
      "Garhwa",
      "Giridih",
      "Godda",
      "Gumla",
      "Hazaribag",
      "Jamtara",
      "Khunti",
      "Koderma",
      "Latehar",
      "Lohardaga",
      "Pakur",
      "Palamu",
      "Ramgarh",
      "Ranchi",
      "Sahibganj",
      "Seraikela-Kharsawan",
      "Simdega",
      "West Singhbhum",
    ],
    Karnataka: [
      "Bagalkot",
      "Ballari (Bellary)",
      "Belagavi (Belgaum)",
      "Bengaluru (Bangalore) Rural",
      "Bengaluru (Bangalore) Urban",
      "Bidar",
      "Chamarajanagar",
      "Chikballapur",
      "Chikkamagaluru (Chikmagalur)",
      "Chitradurga",
      "Dakshina Kannada",
      "Davanagere",
      "Dharwad",
      "Gadag",
      "Hassan",
      "Haveri",
      "Kalaburagi (Gulbarga)",
      "Kodagu",
      "Kolar",
      "Koppal",
      "Mandya",
      "Mysuru (Mysore)",
      "Raichur",
      "Ramanagara",
      "Shivamogga (Shimoga)",
      "Tumakuru (Tumkur)",
      "Udupi",
      "Uttara Kannada (Karwar)",
      "Vijayapura (Bijapur)",
      "Yadgir",
    ],
    Kerala: [
      "Alappuzha",
      "Ernakulam",
      "Idukki",
      "Kannur",
      "Kasaragod",
      "Kollam",
      "Kottayam",
      "Kozhikode",
      "Malappuram",
      "Palakkad",
      "Pathanamthitta",
      "Thiruvananthapuram",
      "Thrissur",
      "Wayanad",
    ],
    "Madhya Pradesh": [
      "Agar Malwa",
      "Alirajpur",
      "Anuppur",
      "Ashoknagar",
      "Balaghat",
      "Barwani",
      "Betul",
      "Bhind",
      "Bhopal",
      "Burhanpur",
      "Chhatarpur",
      "Chhindwara",
      "Damoh",
      "Datia",
      "Dewas",
      "Dhar",
      "Dindori",
      "Guna",
      "Gwalior",
      "Harda",
      "Hoshangabad",
      "Indore",
      "Jabalpur",
      "Jhabua",
      "Katni",
      "Khandwa",
      "Khargone",
      "Mandla",
      "Mandsaur",
      "Morena",
      "Narsinghpur",
      "Neemuch",
      "Panna",
      "Raisen",
      "Rajgarh",
      "Ratlam",
      "Rewa",
      "Sagar",
      "Satna",
      "Sehore",
      "Seoni",
      "Shahdol",
      "Shajapur",
      "Sheopur",
      "Shivpuri",
      "Sidhi",
      "Singrauli",
      "Tikamgarh",
      "Ujjain",
      "Umaria",
      "Vidisha",
    ],
    Maharashtra: [
      "Ahmednagar",
      "Akola",
      "Amravati",
      "Aurangabad",
      "Beed",
      "Bhandara",
      "Buldhana",
      "Chandrapur",
      "Dhule",
      "Gadchiroli",
      "Gondia",
      "Hingoli",
      "Jalgaon",
      "Jalna",
      "Kolhapur",
      "Latur",
      "Mumbai City",
      "Mumbai Suburban",
      "Nagpur",
      "Nanded",
      "Nandurbar",
      "Nashik",
      "Osmanabad",
      "Palghar",
      "Parbhani",
      "Pune",
      "Raigad",
      "Ratnagiri",
      "Sangli",
      "Satara",
      "Sindhudurg",
      "Solapur",
      "Thane",
      "Wardha",
      "Washim",
      "Yavatmal",
    ],
    Manipur: [
      "Bishnupur",
      "Chandel",
      "Churachandpur",
      "Imphal East",
      "Imphal West",
      "Jiribam",
      "Kakching",
      "Kamjong",
      "Kangpokpi",
      "Noney",
      "Pherzawl",
      "Senapati",
      "Tamenglong",
      "Tengnoupal",
      "Thoubal",
      "Ukhrul",
    ],
    Meghalaya: [
      "East Garo Hills",
      "East Jaintia Hills",
      "East Khasi Hills",
      "North Garo Hills",
      "Ri Bhoi",
      "South Garo Hills",
      "South West Garo Hills ",
      "South West Khasi Hills",
      "West Garo Hills",
      "West Jaintia Hills",
      "West Khasi Hills",
    ],
    Mizoram: [
      "Aizawl",
      "Champhai",
      "Kolasib",
      "Lawngtlai",
      "Lunglei",
      "Mamit",
      "Saiha",
      "Serchhip",
    ],
    Nagaland: [
      "Dimapur",
      "Kiphire",
      "Kohima",
      "Longleng",
      "Mokokchung",
      "Mon",
      "Peren",
      "Phek",
      "Tuensang",
      "Wokha",
      "Zunheboto",
    ],
    Odisha: [
      "Angul",
      "Balangir",
      "Balasore",
      "Bargarh",
      "Bhadrak",
      "Boudh",
      "Cuttack",
      "Deogarh",
      "Dhenkanal",
      "Gajapati",
      "Ganjam",
      "Jagatsinghapur",
      "Jajpur",
      "Jharsuguda",
      "Kalahandi",
      "Kandhamal",
      "Kendrapara",
      "Kendujhar (Keonjhar)",
      "Khordha",
      "Koraput",
      "Malkangiri",
      "Mayurbhanj",
      "Nabarangpur",
      "Nayagarh",
      "Nuapada",
      "Puri",
      "Rayagada",
      "Sambalpur",
      "Subarnapur (Sonepur)",
      "Sundargarh",
    ],
    // Puducherry: ["Karaikal", "Mahe", "Pondicherry", "Yanam"],
    Punjab: [
      "Amritsar",
      "Barnala",
      "Bathinda",
      "Faridkot",
      "Fatehgarh Sahib",
      "Fazilka",
      "Ferozepur",
      "Gurdaspur",
      "Hoshiarpur",
      "Jalandhar",
      "Kapurthala",
      "Ludhiana",
      "Mansa",
      "Moga",
      "Muktsar",
      "Nawanshahr (Shahid Bhagat Singh Nagar)",
      "Pathankot",
      "Patiala",
      "Rupnagar",
      "Sahibzada Ajit Singh Nagar (Mohali)",
      "Sangrur",
      "Tarn Taran",
    ],
    Rajasthan: [
      "Ajmer",
      "Alwar",
      "Anupgarh",
      "Balotra",
      "Banswara",
      "Baran",
      "Barmer",
      "Beawar",
      "Bharatpur",
      "Bhilwara",
      "Bikaner",
      "Bundi",
      "Chittorgarh",
      "Churu",
      "Dausa",
      "Dheeg",
      "Didwana-Kuchaman",
      "Dholpur",
      "Dudu",
      "Dungarpur",
      "Gangapur City",
      "Hanumangarh",
      "Jaipur",
      "Jaipur (Rural)",
      "Jaisalmer",
      "Jalore",
      "Jhalawar",
      "Jhunjhunu",
      "Jodhpur",
      "Jodhpur (Rural)",
      "Karauli",
      "Kekri",
      "Kota",
      "Kotputli-Bahrod",
      "Khairthal-Tijara",
      "Nagaur",
      "Neem Ka Thana",
      "Pali",
      "Phalodi",
      "Pratapgarh",
      "Rajsamand",
      "Salumbar",
      "Sanchore",
      "Sawai Madhopur",
      "Shahpura",
      "Sikar",
      "Sirohi",
      "Sri Ganganagar",
      "Tonk",
      "Udaipur",
    ],
    Sikkim: ["East Sikkim", "North Sikkim", "South Sikkim", "West Sikkim"],
    "Tamil Nadu": [
      "Ariyalur",
      "Chennai",
      "Coimbatore",
      "Cuddalore",
      "Dharmapuri",
      "Dindigul",
      "Erode",
      "Kanchipuram",
      "Kanyakumari",
      "Karur",
      "Krishnagiri",
      "Madurai",
      "Nagapattinam",
      "Namakkal",
      "Nilgiris",
      "Perambalur",
      "Pudukkottai",
      "Ramanathapuram",
      "Salem",
      "Sivaganga",
      "Thanjavur",
      "Theni",
      "Thoothukudi (Tuticorin)",
      "Tiruchirappalli",
      "Tirunelveli",
      "Tirupathur",
      "Tiruppur",
      "Tiruvallur",
      "Tiruvannamalai",
      "Tiruvarur",
      "Vellore",
      "Viluppuram",
      "Virudhunagar",
    ],
    Telangana: [
      "Adilabad",
      "Bhadradri Kothagudem",
      "Hyderabad",
      "Jagtial",
      "Jangaon",
      "Jayashankar Bhupalapally",
      "Jogulamba Gadwal",
      "Kamareddy",
      "Karimnagar",
      "Khammam",
      "Komaram Bheem Asifabad",
      "Mahabubabad",
      "Mahabubnagar",
      "Mancherial",
      "Medak",
      "Medchal",
      "Mulugu",
      "Nagarkurnool",
      "Nalgonda",
      "Nirmal",
      "Nizamabad",
      "Peddapalli",
      "Rajanna Sircilla",
      "Rangareddy",
      "Sangareddy",
      "Siddipet",
      "Suryapet",
      "Vikarabad",
      "Wanaparthy",
      "Warangal Rural",
      "Warangal Urban",
      "Yadadri Bhuvanagiri",
    ],
    Tripura: [
      "Dhalai",
      "Gomati",
      "Khowai",
      "North Tripura",
      "Sepahijala",
      "South Tripura",
      "Unakoti",
      "West Tripura",
    ],
    "Uttar Pradesh": [
      "Agra",
      "Aligarh",
      "Allahabad",
      "Ambedkar Nagar",
      "Amethi (Chatrapati Sahuji Mahraj Nagar)",
      "Amroha (J.P. Nagar)",
      "Auraiya",
      "Azamgarh",
      "Baghpat",
      "Bahraich",
      "Ballia",
      "Balrampur",
      "Banda",
      "Barabanki",
      "Bareilly",
      "Basti",
      "Bhadohi",
      "Bijnor",
      "Budaun",
      "Bulandshahr",
      "Chandauli",
      "Chitrakoot",
      "Deoria",
      "Etah",
      "Etawah",
      "Faizabad",
      "Farrukhabad",
      "Fatehpur",
      "Firozabad",
      "Gautam Buddha Nagar",
      "Ghaziabad",
      "Ghazipur",
      "Gonda",
      "Gorakhpur",
      "Hamirpur",
      "Hapur (Panchsheel Nagar)",
      "Hardoi",
      "Hathras",
      "Jalaun",
      "Jaunpur",
      "Jhansi",
      "Kannauj",
      "Kanpur Dehat",
      "Kanpur Nagar",
      "Kasganj",
      "Kaushambi",
      "Kushinagar (Padrauna)",
      "Lakhimpur - Kheri",
      "Lalitpur",
      "Lucknow",
      "Maharajganj",
      "Mahoba",
      "Mainpuri",
      "Mathura",
      "Mau",
      "Meerut",
      "Mirzapur",
      "Moradabad",
      "Muzaffarnagar",
      "Pilibhit",
      "Pratapgarh",
      "Prayagraj (Allahabad)",
      "Raebareli",
      "Rampur",
      "Saharanpur",
      "Sambhal (Bhim Nagar)",
      "Sant Kabir Nagar",
      "Shahjahanpur",
      "Shamali (Prabuddh Nagar)",
      "Shravasti",
      "Siddharth Nagar",
      "Sitapur",
      "Sonbhadra",
      "Sultanpur",
      "Unnao",
      "Varanasi",
    ],
    Uttarakhand: [
      "Almora",
      "Bageshwar",
      "Chamoli",
      "Champawat",
      "Dehradun",
      "Haridwar",
      "Nainital",
      "Pauri Garhwal",
      "Pithoragarh",
      "Rudraprayag",
      "Tehri Garhwal",
      "Udham Singh Nagar",
      "Uttarkashi",
    ],
    "West Bengal": [
      "Alipurduar",
      "Bankura",
      "Birbhum",
      "Cooch Behar",
      "Dakshin Dinajpur (South Dinajpur)",
      "Darjeeling",
      "Hooghly",
      "Howrah",
      "Jalpaiguri",
      "Jhargram",
      "Kalimpong",
      "Kolkata",
      "Malda",
      "Murshidabad",
      "Nadia",
      "North 24 Parganas",
      "Paschim Medinipur (West Medinipur)",
      "Purba Medinipur (East Medinipur)",
      "Purulia",
      "South 24 Parganas",
      "Uttar Dinajpur (North Dinajpur)",
    ],
    "Andaman and Nicobar Islands": [
      "Nicobar",
      "North and Middle Andaman",
      "South Andaman",
    ],
    Chandigarh: ["Chandigarh"],
    "Dadra and Nagar Haveli and Daman and Diu": [
      "Dadra and Nagar Haveli",
      "Daman",
      "Diu",
    ],
    Delhi: [
      "Central Delhi",
      "East Delhi",
      "New Delhi",
      "North Delhi",
      "North East Delhi",
      "North West Delhi",
      "Shahdara",
      "South Delhi",
      "South East Delhi",
      "South West Delhi",
      "West Delhi",
    ],
    Ladakh: ["Kargil", "Leh"],
    Lakshadweep: [
      "Agatti",
      "Amini",
      "Androth",
      "Bitra",
      "Chetlat",
      "Kadmat",
      "Kalpeni",
      "Kavaratti",
      "Kiltan",
      "Minicoy",
    ],
    Puducherry: ["Karaikal", "Mahe", "Puducherry", "Yanam"],
  };

  var tehsils = {
    Ajmer: [
      "Ajmer",
      "Arai",
      "Bhinai",
      "Bijainagar",
      "Kishangarh",
      "Masuda",
      "Nasirabad",
      "Pisangan",
      "Pushkar",
      "Roopangarh",
      "Sanver",
      "Sarwar",
      "Tantoti",
      "Tatgarh",
    ],
    Alwar: [
      "Alwar",
      "Bansur",
      "Govindgarh",
      "Kathumar",
      "Kishangarh Bas",
      "Kotkasim",
      "Laxmangarh",
      "Mala Khera",
      "Mundawar",
      "Narayanpur",
      "Neemrana",
      "Rajgarh",
      "Ramgarh",
      "Reni",
      "Tapukara",
      "Tehla",
      "Thanagazi",
    ],
    Anupgarh: [],
    Balotra: [],

    Banswara: ["Bagidora", "Banswara", "Garhi", "Ghatol"],
    Baran: ["Antah", "Atru", "Baran", "Chhabra", "Chhipabarod"],
    Barmer: [
      "Barmer",
      "Baytoo",
      "Chohtan",
      "Gudamalani",
      "Pachpadra",
      "Ramsar",
      "Sheo",
      "Siwana",
    ],
    Beawar: [],
    Bharatpur: [
      "Bayana",
      "Bharatpur",
      "Bhusawar",
      "Kaman",
      "Kumher",
      "Nadbai",
      "Nagar",
      "Pahari",
      "Rupbas",
      "Sikri",
      "Uchchain",
      "Weir",
    ],
    Bhilwara: [
      "Asind",
      "Badnor",
      "Banera",
      "Bhilwara",
      "Bijolia",
      "Hamirgarh",
      "Hurda",
      "Jahajpur",
      "Karera",
      "Kotri",
      "Mandal",
      "Mandalgarh",
      "Phooliya Kala",
      "Raipur",
      "Sahara",
    ],
    Bikaner: [
      "Bajju",
      "Bikaner",
      "Chhatargarh",
      "Dungargarh",
      "Khajuwala",
      "Kolayat",
      "Lunkaransar",
      "Nokha",
      "Poogal",
    ],
    Bundi: ["Bundi", "Hindaun", "Indragarh", "Kapren", "Nainwa"],
    Chittorgarh: [
      "Bari Sadri",
      "Begun",
      "Chittorgarh",
      "Dungla",
      "Kapasan",
      "Nimbahera",
      "Pratapgarh",
      "Rashmi",
      "Rawatbhata",
    ],
    Churu: [
      "Bidasar",
      "Churu",
      "Rajgarh",
      "Ratangarh",
      "Sardarshahar",
      "Siddhmukh",
      "Sujangarh",
      "Taranagar",
    ],
    Dausa: ["Bandikui", "Dausa", "Lalsot", "Mahwa"],
    Dheeg: [],
    Dholpur: ["Bari", "Dholpur", "Rajakhera", "Saramathura"],
    "Didwana-Kuchaman": [],
    Dudu: [],
    Dungarpur: [
      "Aspur",
      "Bichhiwara",
      "Chikhali",
      "Dowra",
      "Dungarpur",
      "Galiyakot",
      "Gamri Ahara",
      "Jhonthari",
      "Sabla",
      "Sagwara",
      "Simalwara",
    ],
    "Gangapur City": [],
    Hanumangarh: [
      "Bhadra",
      "Hanumangarh",
      "Nohar",
      "Pilibanga",
      "Rawatsar",
      "Sangria",
      "Tibbi",
    ],
    Jaipur: [
      "Amber",
      "Andhi",
      "Bassi",
      "Chaksu",
      "Chomu",
      "Jaipur",
      "Jamwa Ramgarh",
      "Jobner",
      "Kishangarh Renwal",
      "Kotkhawda",
      "Kotputli",
      "Madhorajpura",
      "Mauzmabad",
      "Pawta",
      "Phagi",
      "Phulera",
      "Sanganer",
      "Shahpura",
      "Toonga",
      "Viratnagar",
    ],
    "Jaipur (Rural)": [],

    Jaisalmer: ["Fatehgarh", "Jaisalmer", "Pokaran", "Sam"],
    Jalore: [
      "Ahore",
      "Bhinmal",
      "Jalore",
      "Jaswantpura",
      "Raniwara",
      "Sayla",
      "Siana",
    ],
    Jhalawar: [
      "Aklera",
      "Bakani",
      "Jhalarapatan",
      "Jhalawar",
      "Khanpur",
      "Manoharthana",
      "Pirawa",
    ],
    Jhunjhunu: [
      "Buhana",
      "Chirawa",
      "Jhunjhunu",
      "Khetri",
      "Malsisar",
      "Nawalgarh",
      "Pilani",
      "Surajgarh",
      "Udaipurwati",
    ],
    Jodhpur: ["Bhopalgarh", "Bilara", "Jodhpur", "Luni", "Osian", "Shergarh"],
    "Jodhpur (Rural)": [],
    Karauli: [
      "Karauli",
      "Hindaun",
      "Masalpur",
      "Mandrayal",
      "Nadoti",
      "Sapotra",
      "Suroth",
      "Shri Mahaveer Ji",
      "Todabhim",
    ],
    Kekri: [],
    "Khairthal-Tijara": [],
    Kota: [
      "Digod",
      "Itawa",
      "Kota",
      "Kaithoon",
      "Pipalda",
      "Ramganj Mandi",
      "Sangod",
    ],
    "Kotputli-Bahrod": [],
    Nagaur: [
      "Degana",
      "Jayal",
      "Khinvsar",
      "Ladnu",
      "Makrana",
      "Merta City",
      "Mundwa",
      "Nagaur",
      "Nawa",
      "Parbatsar",
      "Riyan Bari",
      "Sanjoo",
    ],
    "Neem Ka Thana": [],
    Pali: [
      "Bali",
      "Desuri",
      "Jaitaran",
      "Marwar Junction",
      "Pali",
      "Rohat",
      "Sojat",
      "Sumerpur",
    ],
    Phalodi: [],
    Pratapgarh: ["Arnod", "Chhoti Sadri", "Dhariawad", "Pratapgarh"],
    Rajsamand: [
      "Amet",
      "Bhim",
      "Deogarh",
      "Kumbhalgarh",
      "Nathdwara",
      "Rajsamand",
    ],
    Salumbar: [],
    Sanchore: [],
    "Sawai Madhopur": [
      "Bonli",
      "Bamanwas",
      "Chauth Ka Barawara",
      "Khandar",
      "Malarna Dungar",
      "Mitrpura",
      "Sawai Madhopur",
      "Wazirpur",
    ],
    Shahpura: [],
    Sikar: [
      "Danta Ramgarh",
      "Fatehpur",
      "Lachhmangarh",
      "Sikar",
      "Sri Madhopur",
    ],
    Sirohi: ["Abu Road", "Mount Abu", "Pindwara", "Sirohi"],
    "Sri Ganganagar": [
      "Gajsinghpur",
      "Karani",
      "Padampur",
      "Raisinghnagar",
      "Rawla",
      "Sadulshahar",
      "Shri Karanpur",
      "Sri Ganganagar",
      "Suratgarh",
      "Vijaynagar",
    ],
    Tonk: [
      "Deoli",
      "Duni",
      "Malpura",
      "Niwai",
      "Peeplu",
      "Todaraisingh",
      "Tonk",
      "Uniara",
    ],
    Udaipur: [
      "Girwa",
      "Gogunda",
      "Jhadol",
      "Kherwara",
      "Kotra",
      "Mavli",
      "Udaipur",
    ],
  };

  const handleKeyPress = (event, nextFieldRef) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (event.target.value && nextFieldRef.current) {
        nextFieldRef.current.focus();
      }
    }
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setLoading(false);
  };

  const handleInputChange = (event) => {
    setReference(event.target.value);
  };

  const userEmail = process.env.REACT_APP_MAP_API_EMAIL;
  const apiToken = process.env.REACT_APP_MAP_API_TOKEN;

  useEffect(() => {
    // Get the authorization token
    fetch("https://www.universal-tutorial.com/api/getaccesstoken", {
      headers: {
        Accept: "application/json",
        "api-token": apiToken,
        "user-email": userEmail,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // Store the authorization token in local storage
        localStorage.setItem("authToken", data.auth_token);
      })
      .catch((error) => {
        console.error("Error fetching authorization token:", error);
      });
  }, []);

  useEffect(() => {
    // Get all countries
    fetch("https://www.universal-tutorial.com/api/countries", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setCountry(data);
      })
      .catch((error) => {
        console.error("Error fetching countries:", error);
      });
  }, []);

  useEffect(() => {
    // Get states by country
    if (selectedCountry) {
      fetch(
        `https://www.universal-tutorial.com/api/states/${selectedCountry}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Accept: "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setState(data);
        })
        .catch((error) => {
          console.error("Error fetching states:", error);
        });
    }
  }, [selectedCountry]);

  useEffect(() => {
    // Get cities by state
    if (selectedState) {
      fetch(`https://www.universal-tutorial.com/api/cities/${selectedState}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Accept: "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setCity(data);
        })
        .catch((error) => {
          console.error("Error fetching cities:", error);
        });
    }
  }, [selectedState]);

  const handleDistrictChange = (e) => {
    setSelectedDistrict(e.target.value);
    setSelectedTehsil("");
  };

  const handleTehsilChange = (e) => {
    setSelectedTehsil(e.target.value);
  };

  const handleReset = () => {
    setFullName("");
    setRelation("");
    setEmail("");
    setMobileNumber("");
    setLocation("");
    setAddress("");
    setSelectedCountry("");
    setSelectedState("");
    setSelectedCity("");
    setVillage("");
    setOccupation("");
    setName("");

    // Reset RadioGroup
    if (location === "India") {
      setSelectedState("");
    } else if (location === "Abroad") {
      setSelectedCountry("");
      setSelectedState("");
      setSelectedCity("");
    }

    // Reset Select fields (if applicable)
    if (selectedDistrict) {
      setSelectedDistrict("");
      setSelectedTehsil("");
    }
  };

  const handleRadioClick = () => {
    setSelectedCountry("");
    setSelectedState("");
    setSelectedCity("");
  };

  const checkHandler = async (event) => {
    setLoading(true);
    setIsSubmitted(true);

    event.preventDefault();

    const errors = {};
    // if (!name) {
    //   errors.name = "Name is required";
    //   nameRef.current.focus();
    // }
    if (!occupation) {
      errors.occupation = "Occupation is required";
      occupationRef.current.focus();
    }

    if (!mobileNumber) {
      errors.mobileNumber = "Mobile Number is required";
      mobileNumberRef.current.focus();
    }
    if (!email) {
      errors.email = "Email is required";
      emailRef.current.focus();
    }
    if (!relation) {
      errors.relation = "Relation is required";
      relationRef.current.focus();
    }
    if (!fullName) {
      errors.fullName = "Full name is required";
      fullNameRef.current.focus();
    }
    if (!location) {
      errors.location = "Location is required";
    }
    if (!address) {
      errors.address = "Address is required";
    }
    if (!selectedState) {
      errors.selectedState = "State is required";
    }
    if (!selectedDistrict) {
      errors.selectedDistrict = "District is required";
    }

    if (Object.keys(errors).length > 0) {
      toast({
        title: "Please Fill all the Fields",
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
      setLoading(false);
      setFormErrors(errors);
      return;
    }
    setFormErrors({});

    const emailRegex = /^\S+@\S+\.\S+$/;
    if (!emailRegex.test(email)) {
      setEmailValid(false);
      toast({
        title: "Invalid email format",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setLoading(false);
      setEmail("");
      emailRef.current.focus();
      return;
    }

    const parsedPhoneNumber = parsePhoneNumberFromString(mobileNumber);
    if (!parsedPhoneNumber || !parsedPhoneNumber.isValid()) {
      setPhoneValid(false);
      toast({
        title: "Invalid phone number format",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setLoading(false);
      setMobileNumber("");
      mobileNumberRef.current.focus();
      return;
    }

    setEmailValid(true);
    setPhoneValid(true);

    try {
      setIsOpen(true);
    } catch (error) {
      toast({
        title: "Error Occured!",
        description: error.response.data.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
      setEmail("");
      setMobileNumber("");
      setLoading(false);
      emailRef.current.focus();
    }
  };

  const submitHandler = async (event) => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };
      var name = fullName;
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/register`,
        {
          fullName,
          relation,
          email,
          mobileNumber,
          location,
          selectedCountry,
          address,
          selectedState,
          selectedCity,
          selectedDistrict,
          selectedTehsil,
          village,
          occupation,
          name,
          reference,
        },
        config  
      );
      // console.log(data);
      toast({
        title: "Registration Successful",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
      setLoading(false);
      closeModal();

      sendEmail();
      navigate("/admin/dashboard", { replace: true });
    } catch (error) {
      toast({
        title: "Error Occured!",
        description: error.response.data.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
      setIsOpen(false);
      setEmail("");
      setMobileNumber("");
      emailRef.current.focus();
    }
    setLoading(false);
  };

  const sendEmail = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/email`,
        {
          fullName,
          email,
        }
      );
      // console.log(response.data);
      toast({
        title: `Email Sent to ${email}`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box
      display={{ base: "block", md: "flex" }}
      justifyContent="space-between"
      alignContent="center"
      alignItems="center"
      w={{ base: "auto", md: "90%" }}
      borderRadius="lg"
      p={{ base: 4, md: 6 }}
      bg="white"
      borderWidth="1px"
      borderColor="gray.300"
      ml={{ base: 4, md: "auto" }}
      mr={{ base: 4, md: "auto" }}
      mt={{ base: 4, md: 8 }}
      mb={{ base: 4, md: 8 }}
      max-width="1320px"
      h="fit-content"
    >
      <VStack w="100%" align="stretch">
        {useBreakpointValue({
          base: (
            <>
              <FormControl
                id="fullname"
                isRequired
                w={{ base: "100%", md: "50%" }}
                isInvalid={!fullName && isSubmitted}
              >
                <FormLabel fontWeight="extrabold" fontFamily="Montserrat">
                  Full Name
                </FormLabel>
                <Input
                  placeholder="Full Name"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  className={fullName ? "valid-input" : ""}
                  ref={fullNameRef}
                  onKeyDown={(event) => handleKeyPress(event, relationRef)}
                />
                {/* {!fullName && (
                  <FormErrorMessage>{formErrors.fullName}</FormErrorMessage>
                )} */}
              </FormControl>
              <FormControl
                id="relation"
                isRequired
                w={{ base: "100%", md: "50%" }}
                isInvalid={!relation && isSubmitted}
              >
                <FormLabel fontWeight="extrabold" fontFamily="Montserrat">
                  S/o, W/o, D/o
                </FormLabel>
                <Input
                  placeholder="S/o, W/o, D/o Name"
                  value={relation}
                  onChange={(e) => setRelation(e.target.value)}
                  className={relation ? "valid-input" : ""}
                  ref={relationRef}
                  onKeyDown={(event) => handleKeyPress(event, emailRef)}
                />
                {/* {!relation && (
                  <FormErrorMessage>{formErrors.relation}</FormErrorMessage>
                )} */}
              </FormControl>
            </>
          ),
          md: (
            <HStack flexWrap={{ base: "wrap", md: "nowrap" }} spacing={4}>
              <FormControl
                id="fullname"
                isRequired
                w={{ base: "100%", md: "50%" }}
                isInvalid={!fullName && isSubmitted}
              >
                <FormLabel fontWeight="extrabold" fontFamily="Montserrat">
                  Full Name
                </FormLabel>
                <Input
                  placeholder="Full Name"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  className={fullName ? "valid-input" : ""}
                  ref={fullNameRef}
                  onKeyDown={(event) => handleKeyPress(event, relationRef)}
                />
                {/* {!fullName && (
                  <FormErrorMessage>{formErrors.fullName}</FormErrorMessage>
                )} */}
              </FormControl>
              <FormControl
                id="relation"
                isRequired
                w={{ base: "100%", md: "50%" }}
                isInvalid={!relation && isSubmitted}
              >
                <FormLabel fontWeight="extrabold" fontFamily="Montserrat">
                  S/o, W/o, D/o
                </FormLabel>
                <Input
                  placeholder="S/o, W/o, D/o Name"
                  value={relation}
                  onChange={(e) => setRelation(e.target.value)}
                  className={relation ? "valid-input" : ""}
                  ref={relationRef}
                  onKeyDown={(event) => handleKeyPress(event, emailRef)}
                />
                {/* {!relation && (
                  <FormErrorMessage>{formErrors.relation}</FormErrorMessage>
                )} */}
              </FormControl>
            </HStack>
          ),
        })}

        {useBreakpointValue({
          base: (
            <>
              <FormControl
                id="email"
                isRequired
                w={{ base: "100%", md: "50%" }}
                isInvalid={(!email && isSubmitted) || !emailValid}
              >
                <FormLabel fontWeight="extrabold" fontFamily="Montserrat">
                  Email
                </FormLabel>
                <Input
                  type="email"
                  value={email}
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                  className={email ? "valid-input" : ""}
                  ref={emailRef}
                  onKeyDown={(event) => handleKeyPress(event, mobileNumberRef)}
                />
                {/* {!email && (
                  <FormErrorMessage>{formErrors.email}</FormErrorMessage>
                )} */}
              </FormControl>
              <FormControl
                id="mobilenumber"
                isRequired
                w={{ base: "100%", md: "50%" }}
                isInvalid={(!mobileNumber && isSubmitted) || !phoneValid}
              >
                <FormLabel fontWeight="extrabold" fontFamily="Montserrat">
                  Mobile Number
                </FormLabel>
                <PhoneInput
                  value={mobileNumber}
                  country={"in"}
                  countryCodeEditable={false}
                  placeholder="Mobile Number"
                  onChange={handlePhoneNumberChange}
                  onFocus={handleMobileNumberFocus}
                  className={mobileNumber ? "valid-input" : ""}
                  id="mobileNumberInput"
                  autoComplete="off"       
                  onKeyDown={(event) => handleKeyPress(event, occupationRef)}
                  style={{
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    padding: "8px",
                    fontSize: "14px",
                    outline: "none",
                    borderColor: "#E0E0E0",
                    boxShadow: isMobileNumberFocused
                      ? "0 0 0 2px #007bff"
                      : "none",
                  }}
                  inputProps={{
                    className: "phone-input",
                    ref:mobileNumberRef
                  }}
                />
              </FormControl>
            </>
          ),
          md: (
            <HStack flexWrap={{ base: "wrap", md: "nowrap" }} spacing={4}>
              <FormControl
                id="email"
                isRequired
                w={{ base: "100%", md: "50%" }}
                isInvalid={(!email && isSubmitted) || !emailValid}
              >
                <FormLabel fontWeight="extrabold" fontFamily="Montserrat">
                  Email
                </FormLabel>
                <Input
                  type="email"
                  value={email}
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                  className={email ? "valid-input" : ""}
                  ref={emailRef}
                  onKeyDown={(event) => handleKeyPress(event, mobileNumberRef)}
                />
                {/* {!email && (
                  <FormErrorMessage>{formErrors.email}</FormErrorMessage>
                )} */}
              </FormControl>
              <FormControl
                id="mobilenumber"
                isRequired
                w={{ base: "100%", md: "50%" }}
                isInvalid={(!mobileNumber && isSubmitted) || !phoneValid}
              >
                <FormLabel fontWeight="extrabold" fontFamily="Montserrat">
                  Mobile Number
                </FormLabel>
                <PhoneInput
                  value={mobileNumber}
                  defaultCountry="IN"
                  placeholder="Mobile Number"
                  countryCodeEditable={false}
                  onChange={handlePhoneNumberChange}
                  onFocus={handleMobileNumberFocus}
                  className={mobileNumber ? "valid-input" : ""}
                  id="mobileNumberInput"
                  autoComplete="off"
                  onKeyDown={(event) => handleKeyPress(event, occupationRef)}
                  style={{
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    padding: "8px",
                    fontSize: "14px",
                    outline: "none",
                    borderColor: "#E0E0E0",
                    boxShadow: isMobileNumberFocused
                      ? "0 0 0 2px #007bff"
                      : "none",
                  }}
                  inputProps={{
                    className: "phone-input",
                    ref:mobileNumberRef
                  }}
                />
              </FormControl>
            </HStack>
          ),
        })}

        <FormControl
          id="location"
          isRequired
          w="100%"
          isInvalid={!location && isSubmitted}
        >
          <FormLabel fontWeight="extrabold" fontFamily="Montserrat">
            Current Address
          </FormLabel>
          <RadioGroup
            value={location}
            onChange={(value) => setLocation(value)}
            onClick={handleRadioClick}
            className={location ? "valid-input" : ""}
          >
            <HStack spacing="24px">
              <Radio value="India" size="lg">
                India
              </Radio>
              <Radio value="Abroad" size="lg">
                Abroad
              </Radio>
            </HStack>
          </RadioGroup>
          {/* {!location && (
            <FormErrorMessage>{formErrors.location}</FormErrorMessage>
          )} */}
        </FormControl>

        {useBreakpointValue({
          base: (
            <>
              {location === "India" && (
                <>
                  <FormControl
                    id="address"
                    isRequired
                    w={{ base: "100%", md: "50%" }}
                    isInvalid={!address && isSubmitted}
                  >
                    <FormLabel fontWeight="extrabold" fontFamily="Montserrat">
                      Address
                    </FormLabel>
                    <Input
                      type="text"
                      placeholder="Current Address"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      className={address ? "valid-input" : ""}
                    />
                    {/* {!address && (
                      <FormErrorMessage>{formErrors.address}</FormErrorMessage>
                    )} */}
                  </FormControl>

                  <FormControl
                    id="state"
                    isRequired
                    w={{ base: "100%", md: "25%" }}
                    isInvalid={!selectedState && isSubmitted}
                  >
                    <FormLabel fontWeight="extrabold" fontFamily="Montserrat">
                      State
                    </FormLabel>
                    <Select
                      value={selectedState}
                      onChange={(e) => setSelectedState(e.target.value)}
                      className={selectedState ? "valid-input" : ""}
                    >
                      <option value="">-- Select State --</option>
                      {Object.keys(districtArray).map((state) => (
                        <option key={state} value={state}>
                          {state}
                        </option>
                      ))}
                    </Select>
                    {/* {!selectedState && (
                      <FormErrorMessage>
                        {formErrors.selectedState}
                      </FormErrorMessage>
                    )} */}
                  </FormControl>

                  {selectedState && (
                    <FormControl
                      id="city"
                      isRequired
                      w={{ base: "100%", md: "25%" }}
                      isInvalid={!selectedCity && isSubmitted}
                    >
                      <FormLabel fontWeight="extrabold" fontFamily="Montserrat">
                        District
                      </FormLabel>
                      <Select
                        value={selectedCity}
                        onChange={(e) => setSelectedCity(e.target.value)}
                        className={selectedCity ? "valid-input" : ""}
                      >
                        <option value="">-- Select District --</option>
                        {districtArray[selectedState].map((district) => (
                          <option key={district} value={district}>
                            {district}
                          </option>
                        ))}
                      </Select>
                      {/* {!selectedCity && (
                        <FormErrorMessage>
                          {formErrors.selectedCity}
                        </FormErrorMessage>
                      )} */}
                    </FormControl>
                  )}
                </>
              )}

              {location === "Abroad" && (
                <>
                  <FormControl
                    id="address"
                    isRequired
                    w={{ base: "100%", md: "50%" }}
                    isInvalid={!address && isSubmitted}
                  >
                    <FormLabel fontWeight="extrabold" fontFamily="Montserrat">
                      Address
                    </FormLabel>
                    <Input
                      type="text"
                      value={address}
                      placeholder="Current Address"
                      onChange={(e) => setAddress(e.target.value)}
                      className={address ? "valid-input" : ""}
                    />
                    {/* {!address && (
                      <FormErrorMessage>{formErrors.address}</FormErrorMessage>
                    )} */}
                  </FormControl>
                  <FormControl
                    id="country"
                    isRequired
                    w={{ base: "100%", md: "25%" }}
                    isInvalid={!selectedCountry && isSubmitted}
                  >
                    <FormLabel fontWeight="extrabold" fontFamily="Montserrat">
                      Country
                    </FormLabel>
                    <Select
                      value={selectedCountry}
                      onChange={(e) => setSelectedCountry(e.target.value)}
                      className={selectedCountry ? "valid-input" : ""}
                    >
                      <option value="">-- Select Country --</option>
                      {country.map((c) => (
                        <option
                          key={c.country_short_name}
                          value={c.country_name}
                        >
                          {c.country_name}
                        </option>
                      ))}
                    </Select>
                    {/* <FormErrorMessage>
                      {formErrors.selectedCountry}
                    </FormErrorMessage> */}
                  </FormControl>
                  {selectedCountry && (
                    <FormControl
                      id="state"
                      isRequired
                      w={{ base: "100%", md: "25%" }}
                      isInvalid={!selectedState && isSubmitted}
                    >
                      <FormLabel fontWeight="extrabold" fontFamily="Montserrat">
                        State
                      </FormLabel>
                      <Select
                        value={selectedState}
                        onChange={(e) => setSelectedState(e.target.value)}
                        className={selectedState ? "valid-input" : ""}
                      >
                        <option value="">-- Select State --</option>
                        {state.map((s) => (
                          <option key={s.state_name} value={s.state_name}>
                            {s.state_name}
                          </option>
                        ))}
                      </Select>
                      {/* {!selectedState && (
                        <FormErrorMessage>
                          {formErrors.selectedState}
                        </FormErrorMessage>
                      )} */}
                    </FormControl>
                  )}
                  {selectedState && (
                    <FormControl
                      id="city"
                      isRequired
                      w={{ base: "100%", md: "25%" }}
                      isInvalid={!selectedCity && isSubmitted}
                    >
                      <FormLabel fontWeight="extrabold" fontFamily="Montserrat">
                        City
                      </FormLabel>
                      <Select
                        value={selectedCity}
                        onChange={(e) => setSelectedCity(e.target.value)}
                        className={selectedCity ? "valid-input" : ""}
                      >
                        <option value="">-- Select City --</option>
                        {city.map((ci) => (
                          <option key={ci.city_name} value={ci.city_name}>
                            {ci.city_name}
                          </option>
                        ))}
                      </Select>
                      {/* {!selectedCity && (
                        <FormErrorMessage>
                          {formErrors.selectedCity}
                        </FormErrorMessage>
                      )} */}
                    </FormControl>
                  )}
                </>
              )}
            </>
          ),
          md: (
            <>
              {location === "India" && (
                <HStack flexWrap={{ base: "wrap", md: "nowrap" }} spacing={4}>
                  <FormControl
                    id="address"
                    isRequired
                    w={{ base: "100%", md: "50%" }}
                    isInvalid={!address && isSubmitted}
                  >
                    <FormLabel fontWeight="extrabold" fontFamily="Montserrat">
                      Address
                    </FormLabel>
                    <Input
                      type="text"
                      placeholder="Current Address"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      className={address ? "valid-input" : ""}
                    />
                    {/* {!address && (
                      <FormErrorMessage>{formErrors.address}</FormErrorMessage>
                    )} */}
                  </FormControl>

                  <FormControl
                    id="state"
                    isRequired
                    w={{ base: "100%", md: "25%" }}
                    isInvalid={!selectedState && isSubmitted}
                  >
                    <FormLabel fontWeight="extrabold" fontFamily="Montserrat">
                      State
                    </FormLabel>
                    <Select
                      value={selectedState}
                      onChange={(e) => setSelectedState(e.target.value)}
                      className={selectedState ? "valid-input" : ""}
                    >
                      <option value="">-- Select State --</option>
                      {Object.keys(districtArray).map((state) => (
                        <option key={state} value={state}>
                          {state}
                        </option>
                      ))}
                    </Select>
                    {/* {!selectedState && (
                      <FormErrorMessage>
                        {formErrors.selectedState}
                      </FormErrorMessage>
                    )} */}
                  </FormControl>

                  {selectedState && (
                    <FormControl
                      id="city"
                      isRequired
                      w={{ base: "100%", md: "25%" }}
                      isInvalid={!selectedCity && isSubmitted}
                    >
                      <FormLabel fontWeight="extrabold" fontFamily="Montserrat">
                        District
                      </FormLabel>
                      <Select
                        value={selectedCity}
                        onChange={(e) => setSelectedCity(e.target.value)}
                        className={selectedCity ? "valid-input" : ""}
                      >
                        <option value="">-- Select District --</option>
                        {districtArray[selectedState].map((district) => (
                          <option key={district} value={district}>
                            {district}
                          </option>
                        ))}
                      </Select>
                      {/* {!selectedCity && (
                        <FormErrorMessage>
                          {formErrors.selectedCity}
                        </FormErrorMessage>
                      )} */}
                    </FormControl>
                  )}
                </HStack>
              )}

              {location === "Abroad" && (
                <HStack flexWrap={{ base: "wrap", md: "nowrap" }} spacing={4}>
                  <FormControl
                    id="address"
                    isRequired
                    w={{ base: "100%", md: "50%" }}
                    isInvalid={!address && isSubmitted}
                  >
                    <FormLabel fontWeight="extrabold" fontFamily="Montserrat">
                      Address
                    </FormLabel>
                    <Input
                      type="text"
                      value={address}
                      placeholder="Current Address"
                      onChange={(e) => setAddress(e.target.value)}
                      className={address ? "valid-input" : ""}
                    />
                    {/* {!address && (
                      <FormErrorMessage>{formErrors.address}</FormErrorMessage>
                    )} */}
                  </FormControl>
                  <FormControl
                    id="country"
                    isRequired
                    w={{ base: "100%", md: "25%" }}
                    isInvalid={!selectedCountry && isSubmitted}
                  >
                    <FormLabel fontWeight="extrabold" fontFamily="Montserrat">
                      Country
                    </FormLabel>
                    <Select
                      value={selectedCountry}
                      onChange={(e) => setSelectedCountry(e.target.value)}
                      className={selectedCountry ? "valid-input" : ""}
                    >
                      <option value="">-- Select Country --</option>
                      {country.map((c) => (
                        <option
                          key={c.country_short_name}
                          value={c.country_name}
                        >
                          {c.country_name}
                        </option>
                      ))}
                    </Select>
                    {/* <FormErrorMessage>
                      {formErrors.selectedCountry}
                    </FormErrorMessage> */}
                  </FormControl>
                  {selectedCountry && (
                    <FormControl
                      id="state"
                      isRequired
                      w={{ base: "100%", md: "25%" }}
                      isInvalid={!selectedState && isSubmitted}
                    >
                      <FormLabel fontWeight="extrabold" fontFamily="Montserrat">
                        State
                      </FormLabel>
                      <Select
                        value={selectedState}
                        onChange={(e) => setSelectedState(e.target.value)}
                        className={selectedState ? "valid-input" : ""}
                      >
                        <option value="">-- Select State --</option>
                        {state.map((s) => (
                          <option key={s.state_name} value={s.state_name}>
                            {s.state_name}
                          </option>
                        ))}
                      </Select>
                      {/* {!selectedState && (
                        <FormErrorMessage>
                          {formErrors.selectedState}
                        </FormErrorMessage>
                      )} */}
                    </FormControl>
                  )}
                  {selectedState && (
                    <FormControl
                      id="city"
                      isRequired
                      w={{ base: "100%", md: "25%" }}
                      isInvalid={!selectedCity && isSubmitted}
                    >
                      <FormLabel fontWeight="extrabold" fontFamily="Montserrat">
                        City
                      </FormLabel>
                      <Select
                        value={selectedCity}
                        onChange={(e) => setSelectedCity(e.target.value)}
                        className={selectedCity ? "valid-input" : ""}
                      >
                        <option value="">-- Select City --</option>
                        {city.map((ci) => (
                          <option key={ci.city_name} value={ci.city_name}>
                            {ci.city_name}
                          </option>
                        ))}
                      </Select>
                      {/* {!selectedCity && (
                        <FormErrorMessage>
                          {formErrors.selectedCity}
                        </FormErrorMessage>
                      )} */}
                    </FormControl>
                  )}
                </HStack>
              )}
            </>
          ),
        })}

        <HStack flexWrap={{ base: "wrap", md: "nowrap" }} spacing={4}>
          <Text fontWeight="extrabold" mt="16px">
            NATIVE ADDRESS IN RAJASTHAN
          </Text>
        </HStack>

        {useBreakpointValue({
          base: (
            <>
              <FormControl
                w={{ base: "100%", md: "48%" }}
                isRequired
                isInvalid={!selectedDistrict && isSubmitted}
              >
                <FormLabel fontWeight="extrabold" fontFamily="Montserrat">
                  District
                </FormLabel>
                <Select
                  value={selectedDistrict}
                  onChange={handleDistrictChange}
                  className={selectedDistrict ? "valid-input" : ""}
                >
                  <option value="">-- Select District --</option>
                  {Object.keys(tehsils).map((district) => (
                    <option key={district} value={district}>
                      {district}
                    </option>
                  ))}
                </Select>
                {/* {!selectedDistrict && (
                  <FormErrorMessage>
                    {formErrors.selectedDistrict}
                  </FormErrorMessage>
                )} */}
              </FormControl>

              {selectedDistrict && (
                <FormControl w={{ base: "100%", md: "48%" }}>
                  <FormLabel fontWeight="extrabold" fontFamily="Montserrat">
                    Tehsil
                  </FormLabel>
                  <Select
                    value={selectedTehsil}
                    onChange={handleTehsilChange}
                    className={selectedTehsil ? "valid-input" : ""}
                  >
                    <option value="">-- Select Tehsil --</option>
                    {tehsils[selectedDistrict].map((tehsil) => (
                      <option key={tehsil} value={tehsil}>
                        {tehsil}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              )}
              {selectedDistrict && (
                <FormControl id="village" w={{ base: "100%", md: "25%" }}>
                  <FormLabel fontWeight="extrabold" fontFamily="Montserrat">
                    Village
                  </FormLabel>
                  <Input
                    placeholder="Village"
                    value={village}
                    onChange={(e) => setVillage(e.target.value)}
                    type="text"
                    className={village ? "valid-input" : ""}
                  />
                </FormControl>
              )}
            </>
          ),
          md: (
            <HStack flexWrap={{ base: "wrap", md: "nowrap" }} spacing={4}>
              <FormControl
                w={{ base: "100%", md: "48%" }}
                isRequired
                isInvalid={!selectedDistrict && isSubmitted}
              >
                <FormLabel fontWeight="extrabold" fontFamily="Montserrat">
                  District
                </FormLabel>
                <Select
                  value={selectedDistrict}
                  onChange={handleDistrictChange}
                  className={selectedDistrict ? "valid-input" : ""}
                >
                  <option value="">-- Select District --</option>
                  {Object.keys(tehsils).map((district) => (
                    <option key={district} value={district}>
                      {district}
                    </option>
                  ))}
                </Select>
                {/* {!selectedDistrict && (
                  <FormErrorMessage>
                    {formErrors.selectedDistrict}
                  </FormErrorMessage>
                )} */}
              </FormControl>

              {selectedDistrict && (
                <FormControl w={{ base: "100%", md: "48%" }}>
                  <FormLabel fontWeight="extrabold" fontFamily="Montserrat">
                    Tehsil
                  </FormLabel>
                  <Select
                    value={selectedTehsil}
                    onChange={handleTehsilChange}
                    className={selectedTehsil ? "valid-input" : ""}
                  >
                    <option value="">-- Select Tehsil --</option>
                    {tehsils[selectedDistrict].map((tehsil) => (
                      <option key={tehsil} value={tehsil}>
                        {tehsil}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              )}
              {selectedDistrict && (
                <FormControl id="village" w={{ base: "100%", md: "25%" }}>
                  <FormLabel fontWeight="extrabold" fontFamily="Montserrat">
                    Village
                  </FormLabel>
                  <Input
                    placeholder="Village"
                    value={village}
                    onChange={(e) => setVillage(e.target.value)}
                    type="text"
                    className={village ? "valid-input" : ""}
                  />
                </FormControl>
              )}
            </HStack>
          ),
        })}

        <FormControl
          id="occupation"
          isRequired
          w={{ base: "100%", md: "40%" }}
          isInvalid={!occupation && isSubmitted}
        >
          <FormLabel
            fontSize="lg"
            fontWeight="extrabold"
            fontFamily="Montserrat"
          >
            Occupation
          </FormLabel>
          <Input
            type="text"
            placeholder="Occupation"
            value={occupation}
            onChange={(e) => setOccupation(e.target.value)}
            className={occupation ? "invalid-input" : ""}
            bg="gray.100"
            size="lg"
            ref={occupationRef}
            onKeyDown={(event) => handleKeyPress(event, nameRef)}
            autoComplete="off"
          />
          {/* {!occupation && (
            <FormErrorMessage>{formErrors.occupation}</FormErrorMessage>
          )} */}
        </FormControl>

        <HStack>
          <Checkbox
            isRequired
            fontSize="lg"
            fontWeight="extrabold"
            fontFamily="Montserrat"
            size="lg"
            mt="12px"
            mb="8px"
            name="checkbox"
            onChange={handleCheckboxChange}
            isChecked={isChecked}
            borderColor="black"
            colorScheme="green"
          >
            By submitting this form, I consent to Founder General Secretary of
            <i> MARWADI INTERNATIONAL FEDERATION (MIF)</i> for using my name and
            details for records of MIF and its office bearers list. I have read
            and understood all the terms and conditions of MIF.
          </Checkbox>
        </HStack>

        <FormControl
          id="name"
          isRequired
          w={{ base: "100%", md: "50%" }}
          isInvalid={!fullName && isSubmitted}
        >
          <Flex align="center">
            <FormLabel
              fontSize="lg"
              fontWeight="extrabold"
              fontFamily="Montserrat"
            >
              Name
            </FormLabel>
            <Input
              type="text"
              placeholder="Your Name"
              value={fullName}
              onChange={(e) => setName(e.target.value)}
              className={fullName ? "invalid-input" : ""}
              size="lg"
              mb="16px"
              ref={nameRef}
              onKeyDown={(event) => handleKeyPress(event, fullNameRef)}
              autoComplete="off"
            />
          </Flex>
          {/* {!name && <FormErrorMessage>{formErrors.name}</FormErrorMessage>} */}
        </FormControl>

        <HStack>
          <Button
            type="submit"
            colorScheme="blue"
            size="lg"
            onClick={checkHandler}
            fontFamily="Montserrat"
            isLoading={loading}
            isDisabled={!isChecked}
          >
            Submit
          </Button>

          <Button
            type="button"
            colorScheme="red"
            size="lg"
            onClick={handleReset}
            ml={2}
            fontFamily="Montserrat"
          >
            Reset
          </Button>
        </HStack>
      </VStack>

      <Modal
        isOpen={isOpen}
        onClose={closeModal}
        isCentered
        blockScrollOnMount={false}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex>
              <Flex direction="column" flex="1" gap="18px" w="100%">
                <Text fontSize={20} mb={4} fontFamily="Poppins">
                  Where did you hear about us?
                </Text>
                <Input
                  placeholder="Leave a Comment"
                  value={reference}
                  onChange={handleInputChange}
                  variant="flushed"
                />
                <Button
                  type="submit"
                  mb={3}
                  mr={3}
                  onClick={submitHandler}
                  borderWidth="2px"
                  borderColor="black"
                  bgColor="white"
                >
                  Submit
                </Button>
              </Flex>
              <Flex justify="center" align="center" p={3}>
                <Image
                  src="https://bin-cdn-krn.s3.us-east-2.amazonaws.com/original/7b986f75-86ae-428d-940b-4a7f55a8a551.png"
                  boxSize={200}
                />
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default RegistrationForm;
